import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { configJson } from "../../Constent/config";

// Fetch patients by date
export const fetchPatientsByDate = createAsyncThunk(
  "patients/fetchByDate",
  async ({ doctorId, date ,timeZone}, thunkAPI) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/sidepatient/${doctorId}/${date}/${timeZone}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch patients");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Search patients by query
export const searchPatients = createAsyncThunk(
  "patients/search",
  async ({ organizationId, query }, thunkAPI) => {
    try {
      const response = await fetch(
        `${
          configJson.backend_URL
        }/patient/search-patient/${organizationId}/?query=${encodeURIComponent(
          query
        )}`
      );
      if (!response.ok) {
        throw new Error("Failed to search patients");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const patientsSlice = createSlice({
  name: "patients",
  initialState: {
    patientList: [],
    searchResults: [],
    loading: false,
    error: null,
  },
  reducers: {
    addPatientToList: (state, action) => {
      const existing = state.patientList.find(
        (patient) =>
          patient.Patient.patient_id === action.payload.Patient.patient_id
      );
      if (!existing) {
        state.patientList.unshift(action.payload);
      }
    },
    addPatientToTop: (state, action) => {
      state.patientList.unshift(action.payload); // Add the new patient to the top of the list
    },
    updatePatient: (state, action) => {
      console.log("Payload received in reducer:", action.payload);
      state.patientList = state.patientList.map((interaction) =>
        interaction.interaction_id === action.payload.interaction_id
          ? {
              ...interaction,
              interaction_status: action.payload.interaction_status,
            }
          : interaction
      );
    },
    updatePatientdetails: (state, action) => {
      console.log("Payload received in reducer:", action.payload);
    
      state.patientList = state.patientList.map((patient) =>
        patient.patient_id === action.payload.patient_id
          ? {
              ...patient,
              Patient: {
                ...patient.Patient,
                first_name: action.payload.Patient?.first_name || patient.Patient?.first_name,
                last_name: action.payload.Patient?.last_name || patient.Patient?.last_name,
                date_of_birth: action.payload.Patient?.date_of_birth || patient.Patient?.date_of_birth,
              },
            }
          : patient
      );
    },
    updatePatientStatus: (state, action) => {
      console.log("Payload received in reducer:", action.payload);
      state.patientList = state.patientList.map((interaction) =>
        interaction.patient_id === action.payload.patient_id
          ? {
              ...interaction,
              interaction_status: action.payload.interaction_status,
            }
          : interaction
      );
    },
    addPatientIfNotExists: (state, action) => {
      const existingPatient = state.patientList.find(
        (patient) =>
          patient.Patient?.patient_id === action.payload.Patient?.patient_id
      );
      if (!existingPatient) {
        state.patientList.unshift(action.payload); // Add the new patient to the top
      }
    },
    
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
    clearPatientList: (state) => {
      state.patientList = [];
    },
    resetState: (state) => {
      state.patientList = [];
      state.searchResults = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Patients by Date
      .addCase(fetchPatientsByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.patientList = [
          ...state.patientList,
          ...action.payload.filter(
            (newPatient) =>
              !state.patientList.some(
                (existingPatient) =>
                  existingPatient.Patient.patient_id ===
                  newPatient.Patient.patient_id
              )
          ),
        ];
      })
      .addCase(fetchPatientsByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //  Search Patients
      .addCase(searchPatients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchPatients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  addPatientToList,
  addPatientToTop,
  updatePatient,
  clearSearchResults,
  clearPatientList,
  resetState,
  updatePatientdetails,
  updatePatientStatus,
  addPatientIfNotExists
} = patientsSlice.actions;

export default patientsSlice.reducer;
