import React from "react";
import "./OutpatientDataSheet.css";
import { configJson } from "../../../../Constent/config";

const DataSheetHeader = ({ organization_id }) => {
  <style>
  {`
        @media print {
.cstmCidhead img{
margin:0 auto;
}
        }
  `}
</style>
  return (
    <div>
      {organization_id === 8 ? (
        <div
          className="headerPart"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
          }}
        >
          <div
            className="headerlft"
            style={{ flex: "1", marginTop: "-58px" }}
          >
            <img
              src={`/headlogotemplate.png`}
              alt="Gunam Hospital Template Logo"
            />
          </div>
          <div
            className="headercenter"
            style={{
              flex: "3",
              textAlign: "center",
              lineHeight: "1.5",
              marginLeft: "-152px",
            }}
          >
            <h2
              style={{
                margin: "0",
                fontSize: "20px",
                fontWeight: "700",
                color: "#000",
              }}
            >
              GUNAM SUPER SPECIALITY HOSPITAL
            </h2>
            <p
              className="addrCls"
              style={{
                margin: "0px 0",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000",
              }}
            >
              (A UNIT OF HOSUR SUPER SPECIALITY HOSPITALS PVT. LTD)
            </p>
            <p
              className="addrCls"
              style={{
                margin: "0px 0",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000",
              }}
            >
              #35, TANK STREET, (OLD SURVEY NO: 120/2B2), DENKANIKOTTA ROAD
              KRISHNAGIRI (DT), HOSUR - 635109
            </p>
            <p
              className="addrCls"
              style={{
                margin: "0px 0",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000",
              }}
            >
              P: 04344-220599; E: info@gunamhospital.com;
              www.gunamhospital.com
            </p>
            <p
              className="oupathead"
              style={{
                margin: "0",
                fontSize: "20px",
                fontWeight: "700",
                color: "#000",
              }}
            >
              OUTPATIENT DATA SHEET
            </p>
          </div>
        </div>
      ) : organization_id === configJson.ABC ? (
        <div
          className="headerPart"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
            marginTop: "-25px"
          }}
        >
          {/* Left Side: Logo */}
          <div
            className="headerLeft"
            style={{
              flex: "1",
            }}
          >
            <img
              src={`/ABClogoresized.png`}
              alt="ABC Medical Clinic Logo"
            
            />
          </div>

          {/* Center: Organization Name */}
          <div
            className="headerCenter"
            style={{
              flex: "2",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                margin: "0",
                fontSize: "30px",
                fontWeight: "700",
                color: "#fa0202",
                marginTop: "20px"
              }}
            >
              ABC MEDICAL CLINIC
            </h2>
          </div>

          {/* Right Side: Image */}
          <div
            className="headerRight"
            style={{
              flex: "1",
              textAlign: "right",
            }}
          >
            <img
              src={`/24HoursService.png`}
              alt="24-Hour Emergency Icon"
           
            />
          </div>
        </div>
      ): organization_id === configJson.CID ? (
        <div
          className="headerPart"
          style={{
            display: "flex !important",
            alignItems: "center !important",
            padding: "10px 10px !important",
            marginTop: "-25px !important",
            marginBottom: "20px !important",
          }}
        >
          {/* Left Side: Logo */}
          <div
            className="headerLeft cstmCidhead"
            // style={{
            //   flex: "1",
            // }}
          >
            <img
              src={`/CIDLogo.png`}
              alt="CID Logo"
              style={{
                margin: "0 auto"
              }}
            />
          </div>

          {/* Center: Organization Name */}
          <div
            className="headerCenter"
            style={{
           fontSize:"20px",
              textAlign: "center",
              fontWeight:"600",
              marginTop:"20px"
            }}
          >
             OUTPATIENT DATA SHEET
          </div>
      
        </div>
      ) : (
        <div
          className="loginPlaceholder"
          style={{
            textAlign: "center",
            padding: "20px 10px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#333",
          }}
        >
          <p
            className="oupathead"
            style={{
              margin: "0",
              fontSize: "20px",
              fontWeight: "700",
              color: "#000",
            }}
          >
            OUTPATIENT DATA SHEET
          </p>
        </div>
      )}
    </div>
  );
};

export default DataSheetHeader;
