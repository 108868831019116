import React from "react";
import { FormHeader, PatientData } from "./FormComponents";

const MedicineCard = () => {
  return (
    <>
      <FormHeader formTitle='Medicine card' />
      <PatientData />
    </>
  );
};

export default MedicineCard;
