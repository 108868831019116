import styled from "styled-components";

export const SignTopcontainer = styled.div`
  .topbar {
    height: 100px;
    width: 100vw;
    background-image: linear-gradient(to right, #c81d77, #6710c2);
    padding: 25px 49px;
    position: fixed;
    top: 0px;
  }
  .topbar p {
    text-align: center;
    color: #fff;
    font-size: 31px;
  }
  .myborder {
    border: 1px solid #00000059;
    border-radius: 25px;
  }
  .myheight {
    margin-top: 0px;
  }
`;

export const Signformside = styled.div`
  .sign-sign {
    margin-top: 74px;
  }
  .signup-text {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 35px;
  }
`;
export const SignTextContainer = styled.div`
  text-align: left;
  .denotes-logo {
    width: 200px;
    height: 70px;
  }
  .rating-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 25px;
  }
`;

export const SignForm = styled.div`
  .main-form-container {
    border: 1px solid black;
  }
  .form-control {
    // padding-left: 46px;
    // margin-bottom: -10px;
  }
  .form-check-input {
    background-color: #00000073;
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 36px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
  .input-img-container {
    position: relative;
    bottom: 24px;
    left: 8px;
    .sign-input-image {
      width: 15px;
      height: 15px;
    }
    .sign-input-or {
      padding: 5px;
    }
  }
  .er-message {
    color: crimson;
    font-size: 14px;
  }
  .Feedback {
    margin: 0px;
  }
  .btn-log {
    text-align: center;
    .reg-sub-btn {
       color: #fff;
        background-image:linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }
  }
`;

export const SignwithoutForm = styled.div`
  .input-img-container {
    position: relative;
    bottom: 24px;
    left: 8px;
    .sign-input-image {
      width: 15px;
      height: 15px;
    }
    .sign-input-or {
      padding: 5px;
    }
  }
`;

export const TermsText = styled.div`
  .text-main-part {
    color: black;
    font-weight: 500;
  }
`;