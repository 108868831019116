import React, { useEffect, useRef, useState } from "react";
// import personicon from "../../../../assets/user/user51.png";
import patiendicon from "../../../../assets/sidebar/patient.png";
import calendaricon from "../../../../assets/sidebar/calendar (1) 2.png";
import messengericon from "../../../../assets/sidebar/messenger.png";
// import searchicon from "../../../../assets/sidebar/searchicon.png";
import settingicon from "../../../../assets/sidebar/settings.png";
// import writingicon from "../../../../assets/sidebar/writing.png";
import arrowleft from "../../../../assets/sidebar/arrow_left.png";
import dashboardicon from "../../../../assets/sidebar/dashboard.png";
import billicon from "../../../../assets/sidebar/bill.png";
import Contactsicon from "../../../../assets/sidebar/contact-book.png";
import managericon from "../../../../assets/sidebar/manager.png";
import opportunityicon from "../../../../assets/sidebar/opportunity.png";
import backarrowicon from "../../../../assets/sidebar/arrow_back.png";
// import patientsidebar from "../../../../assets/medscribe/patient_sidebar.png";
// import gunamicon from "../../../../assets/sidebar/Gunam_Hospital.png";
// import templateIcon from "../../../../assets/sidebar/Templateicon.png";
import { GrOrganization } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { FaLaptopMedical } from "react-icons/fa";
import {
  Buttonstyle,
  ImageContainer,
  MainPage,
  ScrollButton,
  SettingTop,
  SidebarContainer,
} from "./SidebarStyle";
import { SideTopbar } from "../Topbar/TopBar";
import SidebarButton from "./SidebarButton";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { FaX } from "react-icons/fa6";
import peninsulaicon from "../../../../assets/medscribe/Peninsula.png";
import { useSelector } from "react-redux";
// import SidebarInteractionList from "./SidebarInteractionList";
import InteractionPatientList from "./InteractionPatientList";
import { DateTime } from "luxon";
import ScrollToBottom from "react-scroll-to-bottom";

function Sidebar({ children, setuser, setInteractions }) {
  const [showbar, setShowbar] = useState(true);
  const [role, setRole] = useState("");
  const Patient = true;
  const [showSetting, setShowSetting] = useState(false);
  // const [decode, setDecode] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isTodayScheduleActive = location.pathname === "/token";
  const isVisitHistoryActive = location.pathname === "/visithistory";
  // const isPatientHistoryActive = location.pathname === "/patientlist";
  const iscreateTokenActive = location.pathname === "/createtoken";
  const isPatientListActive = location.pathname === "/patientedit";
  const isAnaltyicsActive = location.pathname === "/analtyics";
  const isActivityActive = location.pathname === "/myactivity";
  // const isAssessmentActive = location.pathname === "/createassessment";
  const isFrontofficeActivity = location.pathname === "/front-activity";
  const isTechActivity = location.pathname === "/tech-activity";
  const isdoctor_consent = location.pathname === "/doctor_consent";
  const isalluser = location.pathname === "/adminpage";
  const isadminrole = location.pathname === "/adminrole";
  const isorganization_setting = location.pathname === "/organization-setting";
  const ismyprofile = location.pathname === "/my-profile";
  const issession = location.pathname === "/session";

  useEffect(() => {
    const handleResize = () => {
      setShowbar(window.innerWidth >= 800);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [patients, setPatients] = useState([]);
  // const [selectedPatientId, setSelectedPatientId] = useState(null);
  // const [logoorg, setLogoOrg] = useState(null);
  const { orglogo, timeZone } = useSelector((state) => state.settings);

  const decode = useSelector((state) => state.auth.decodedToken);
  // console.log(orglogo, timeZone,decode)

  const scrollRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowScrollToTop(scrollTop > 0);
      setShowScrollToBottom(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  // useEffect(() => {
  //   const token = sessionStorage.getItem("orgtoken");
  //   const set_logo = sessionStorage.getItem("orglogo");
  //   if (token) {
  //     setLogoOrg(set_logo);
  //     const decoded = jwtDecode(token);
  //     // setDecode(decoded);
  //     setRole(decoded.role);
  //     // if (decoded.organization_id === 10) {
  //     //   sessionStorage.setItem("timeZone", "US/Pacific");
  //     //   sessionStorage.setItem("timeFormat", "MM-dd-yyyy");
  //     // } else {
  //     //   sessionStorage.setItem("timeZone", "Asia/Kolkata");
  //     //   sessionStorage.setItem("timeFormat", "dd-MM-yyyy");
  //     // }
  //   } else {
  //     navigate("/login");
  //   }
  // }, [navigate]);

  useEffect(() => {
    const storedPatients = JSON.parse(localStorage.getItem("patients")) || [];
    setPatients(storedPatients);
  }, [location]);

  const removePatient = (patientId) => {
    const updatedPatients = patients.filter(
      (patient) => patient.id !== patientId
    );

    localStorage.setItem("patients", JSON.stringify(updatedPatients));
    setPatients(updatedPatients);
    navigate("/patientedit");

    // Find the index of the patient being removed
    // const removedPatientIndex = patients.findIndex(
    //   (patient) => patient.id === patientId
    // );

    // // Determine previous patient or default to '/activity'
    // if (removedPatientIndex > 0) {
    //   const previousPatient = updatedPatients[removedPatientIndex - 1];
    //   navigate(`/activity?id=${previousPatient.id}`);
    // } else if (updatedPatients.length > 0) {
    //   // If no previous patient, redirect to the first remaining patient
    //   navigate(`/activity?id=${updatedPatients[0].id}`);
    // } else {
    //   // If no patients are left, redirect to '/activity'
    //   navigate("/patientedit");
    // }
  };

  return (
    <div>
      <SideTopbar showUser={setuser} />
      <div className="container-fluid" style={{ marginTop: "50px" }}>
        <div
          className="row flex-nowrap overflow-hidden"
          style={{ height: "calc(100vh - 54px)" }}
        >
          {showbar ? (
            <div className="col-auto col-md-3 col-xl-2 px-0 bg-light sidebarresponsive">
              <SidebarContainer style={{ paddingTop: "10px" }}>
                <ImageContainer>
                  <div className="image-heading-container">
                    <div className="image-text">
                      {/* <img
                        src={gunamicon} // Replace this with the actual image URL
                        alt="Gunam Hospital"
                        className="image-file"
                      /> */}
                      {/* {decode && (
                        <img
                          src={
                            decode.organization_id === 10
                              ? peninsulaicon
                              : gunamicon
                          } // Conditional logo rendering
                          alt={
                            decode.organization_id === 10
                              ? "Peninsula Hospital"
                              : "Gunam Hospital"
                          }
                          className="image-file"
                        />
                      )} */}
                      {orglogo ? (
                        <img
                          src={orglogo}
                          alt={""}
                          title=""
                          className="image-file"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="image-text">
                      <p className="heading-text">
                        <b>C-DOT Hospital</b>
                      </p>
                      <p className="heading-text">Perumbakkam, Chennai</p>
                    </div>*/}
                  </div>
                  <hr />
                </ImageContainer>
                {/* <div className="search-items">
                  <input
                    className="input-text"
                    type="text"
                    name="search"
                    placeholder="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search-icon-container">
                    <img
                      src={searchicon}
                      alt=""
                      title=""
                      className="search-input-icon"
                    />{" "}
                  </span>
                </div> */}
                {!showSetting ? (
                  <div className="button-container">
                    {Patient ? (
                      <>
                        {decode?.role === "front office staff" && (
                          <Buttonstyle>
                            <button
                              type="button"
                              className={
                                iscreateTokenActive
                                  ? "side-btn active"
                                  : "side-btn"
                              }
                              onClick={() => {
                                navigate("/createtoken");
                              }}
                            >
                              <span>
                                <img
                                  src={calendaricon}
                                  title=""
                                  alt=""
                                  className="btn-image"
                                />
                              </span>{" "}
                              Create Appointments
                            </button>
                          </Buttonstyle>
                        )}
                        {(decode?.role === "front office staff" ||
                          decode?.role === "vital") && (
                          <>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isPatientListActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/patientedit");
                                }}
                              >
                                <span>
                                  <img
                                    src={messengericon}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Patient List
                              </button>
                            </Buttonstyle>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isFrontofficeActivity
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/front-activity");
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Activity
                              </button>
                              <div className="flex flex-col gap-2 lg:h-[140px] overflow-auto mt-2">
                                {patients?.map((data) => {
                                  // console.log(data, 'III')
                                  return (
                                    <div
                                      className={`flex items-center justify-between p-2 cursor-pointer shadow-md text-sm ${
                                        window.location.pathname + window.location.search ===
                                        `activity?id=${data.id}`
                                          ? "!bg-[#D9D9D9]"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        navigate(`/activity?id=${data.id}`);
                                        if (
                                          setInteractions &&
                                          !location.pathname ==
                                            `/activity?id=${data.id}`
                                        )
                                          setInteractions([]);
                                      }}
                                    >
                                      <div className="flex items-center gap-1">
                                        <div className="rounded-full bg-[#b3b0b0] w-fit p-1 size-[23px]">
                                          <img src={`/user.png`} width={14} />
                                        </div>
                                        <div>
                                          {data.name.length > 15
                                            ? `${data.name.substring(0, 15)}...`
                                            : data.name}
                                        </div>
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removePatient(data.id);
                                        }}
                                      >
                                        <img
                                          src={`/close.png`}
                                          width={20}
                                          className="hover:bg-[#b3b0b0] rounded-full"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              {/* <div className="settings">
                              {!showSetting ? (
                                <button
                                  type="button"
                                  className="side-btn"
                                  onClick={() => navigate("/settings")} // Navigate to settings page
                                >
                                  <span>
                                    <img
                                      src={settingicon}
                                      title=""
                                      alt=""
                                      className="btn-image"
                                    />
                                  </span>{" "}
                                  Settings
                                </button>
                              ) : (
                                ""
                              )}
                            </div> */}
                            </Buttonstyle>
                          </>
                        )}

                        {decode?.role === "doctor" && (
                          <>
                            {decode.country === "United States of America" ? (
                              <>
                                <Buttonstyle>
                                  <button
                                    type="button"
                                    className={
                                      isdoctor_consent
                                        ? "side-btn active"
                                        : "side-btn"
                                    }
                                    onClick={() => {
                                      navigate("/doctor_consent");
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={calendaricon}
                                        title=""
                                        alt=""
                                        className="btn-image"
                                      />
                                    </span>{" "}
                                    Today's Appointments
                                  </button>
                                </Buttonstyle>
                                <Buttonstyle>
                                  <button
                                    type="button"
                                    className={
                                      issession ? "side-btn active" : "side-btn"
                                    }
                                    onClick={() => {
                                      navigate("/session");
                                    }}
                                  >
                                    <span>
                                      <FaLaptopMedical className="btn-image" />
                                    </span>
                                    Create new session
                                  </button>
                                </Buttonstyle>
                              </>
                            ) : (
                              <>
                                <Buttonstyle>
                                  <button
                                    type="button"
                                    className={
                                      isTodayScheduleActive
                                        ? "side-btn active"
                                        : "side-btn"
                                    }
                                    onClick={() => {
                                      navigate("/token");
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={calendaricon}
                                        title=""
                                        alt=""
                                        className="btn-image"
                                      />
                                    </span>{" "}
                                    Today's Appointments
                                  </button>
                                </Buttonstyle>
                                {/* <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isAnaltyicsActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/analytics");
                                  if (isAnaltyicsActive) {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Analytics
                              </button>
                            </Buttonstyle> */}
                              </>
                            )}
                            {/* <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isVisitHistoryActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/visithistory");
                                }}
                              >
                                <span>
                                  <img
                                    src={messengericon}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Visit History
                              </button>
                            </Buttonstyle> */}
                            {/* <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isPatientHistoryActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/patientlist");
                                }}
                              >
                                <span>
                                  <img
                                    src={patientsidebar}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                My Patients
                              </button>
                            </Buttonstyle> */}
                            {/* <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isActivityActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/myactivity");
                                  if (isActivityActive) {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                My Activity
                              </button>
                            </Buttonstyle> */}
                            {/* <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isAssessmentActive
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/createassessment");
                                  if (isAssessmentActive) {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <span>
                                  <img
                                    src={templateIcon}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Assessment Setup
                              </button>
                            </Buttonstyle> */}
                            {/* <div className="flex flex-col gap-2 lg:h-[140px] overflow-auto mt-2"> */}
                            <InteractionPatientList />
                            {/* {patients?.map((data) => (
                                <div
                                  className={`flex items-center justify-between p-2 cursor-pointer shadow-md text-sm ${
                                    location.pathname ==
                                    `activity?id=${data.id}`
                                      ? "!bg-[#D9D9D9]"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    navigate(`/activity?id=${data.id}`);
                                    if (
                                      setInteractions &&
                                      !location.pathname ==
                                        `/activity?id=${data.id}`
                                    )
                                      setInteractions([]);
                                  }}
                                >
                                  <div className="flex items-center gap-1">
                                    <div className="rounded-full bg-[#b3b0b0] w-fit p-1 size-[23px]">
                                      <img src={`/user.png`} width={14} />
                                    </div>
                                    <div>
                                      {data.name.length > 15
                                        ? `${data.name.substring(0, 15)}...`
                                        : data.name}
                                    </div>
                                  </div>
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removePatient(data.id);
                                    }}
                                  >
                                    <img
                                      src={`/close.png`}
                                      width={20}
                                      className="hover:bg-[#b3b0b0] rounded-full"
                                    />
                                  </div>
                                </div>
                              ))} */}
                            {/* </div> */}
                            <div className="settings">
                              {!showSetting ? (
                                <button
                                  type="button"
                                  className="side-btn"
                                  onClick={() => navigate("/settings")} // Navigate to settings page
                                >
                                  <span>
                                    <img
                                      src={settingicon}
                                      title=""
                                      alt=""
                                      className="btn-image"
                                    />
                                  </span>{" "}
                                  Settings
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                        {decode?.role === "technician" && (
                          <>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isTechActivity
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/tech-activity");
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Tech-Activity
                              </button>
                            </Buttonstyle>
                          </>
                        )}
                        {decode?.role === "admin" && (
                          <>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isalluser ? "side-btn active" : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/adminpage");
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                All Users
                              </button>
                            </Buttonstyle>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isadminrole ? "side-btn active" : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/adminrole");
                                }}
                              >
                                <span>
                                  <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  />
                                </span>
                                Add Role
                              </button>
                            </Buttonstyle>
                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  ismyprofile ? "side-btn active" : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/my-profile");
                                }}
                              >
                                <span>
                                  <CgProfile className="btn-image" />
                                </span>
                                My Profile
                              </button>
                            </Buttonstyle>

                            <Buttonstyle>
                              <button
                                type="button"
                                className={
                                  isorganization_setting
                                    ? "side-btn active"
                                    : "side-btn"
                                }
                                onClick={() => {
                                  navigate("/organization-setting");
                                }}
                              >
                                <span>
                                  {/* <img
                                    src={`/activity.png`}
                                    title=""
                                    alt=""
                                    className="btn-image"
                                  /> */}
                                  <GrOrganization className="btn-image" />
                                </span>
                                Organization
                              </button>
                            </Buttonstyle>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <SidebarButton
                          image={dashboardicon}
                          names="Dashboard"
                          active={true}
                        />
                        <SidebarButton
                          image={messengericon}
                          names="Conversation"
                          active={false}
                        />
                        <SidebarButton
                          image={calendaricon}
                          names="Calendars"
                          active={false}
                        />
                        <SidebarButton
                          image={Contactsicon}
                          names="Contacts"
                          active={false}
                        />
                        <SidebarButton
                          image={billicon}
                          names="Payments"
                          active={false}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <SettingTop>
                      <button
                        className="go-back-btn"
                        onClick={() => setShowSetting(false)}
                      >
                        <span>
                          <img
                            className="back-btn-icon"
                            src={backarrowicon}
                            title=""
                            alt=""
                          />
                        </span>
                        Go Back
                      </button>
                      <p className="setting-text">Settings</p>
                      <div className="mybusiness">
                        <p className="mybusiness-text">
                          My Business
                          <span className="mybusiness-line"></span>
                        </p>
                      </div>
                    </SettingTop>
                    <div className="button-container">
                      <SidebarButton
                        image={patiendicon}
                        names="Business Profile"
                        active={true}
                      />
                      <SidebarButton
                        image={billicon}
                        names="Billing"
                        active={false}
                      />
                      <SidebarButton
                        image={managericon}
                        names="My Staff"
                        active={false}
                      />
                      <SidebarButton
                        image={opportunityicon}
                        names="Opportunities"
                        active={false}
                      />
                    </div>
                  </>
                )}

                {true ? (
                  <div className="side-arrow-container">
                    <button
                      type="button"
                      className="side-arrow-btn"
                      onClick={() => setShowbar(!showbar)}
                    >
                      <span>
                        <img
                          src={arrowleft}
                          title=""
                          alt=""
                          className="btn-arrow-image"
                        />
                      </span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </SidebarContainer>
            </div>
          ) : (
            ""
          )}
          <div className={`col py-3`}>
            {/* <div className={`col ${isActivityActive ? 'py-20' : 'py-3'}`}> */}
            <MainPage ref={scrollRef}>
              <main>{children}</main>
              {showScrollToTop && (
                <ScrollButton istop onClick={scrollToTop}>
                  ↑
                </ScrollButton>
              )}
              {showScrollToBottom && (
                <ScrollButton onClick={scrollToBottom}>↓</ScrollButton>
              )}
            </MainPage>
            {!showbar ? (
              <button
                style={{
                  position: "fixed",
                  left: "0px",
                  bottom: "10px",
                  color: "#fff",
                  backgroundImage:
                    "linear-gradient(to right, #5B5B5B, #1C1B1B)",
                }}
                type="button"
                onClick={() => setShowbar(!showbar)}
              >
                <span>
                  <img
                    src={arrowleft}
                    title=""
                    alt=""
                    style={{
                      width: "23px",
                      height: "23px",
                      marginRight: "5px",
                    }}
                  />
                </span>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

export function getDateTimeWithOffset(timezone) {
  return DateTime.now().setZone(timezone).toFormat("yyyy-MM-dd");
}
