import React, { useRef } from "react";

const DischargeSummary = ({ queryResponse }) => {
  const {
    advice_on_discharge,
    discharge_summary,
    findings,
    hospital_details,
    patient_details,
    procedure_details,
    procedure_notes,
    treatment_given,
  } = JSON.parse(queryResponse);

  console.log(JSON.parse(queryResponse));
  // console.log(hospital_details);

  const contentRef = useRef();

  const handlePrint = () => {
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";
    document.body.appendChild(printFrame);

    const content = contentRef.current.innerHTML;
    const printDocument = printFrame.contentWindow.document;

    printDocument.open();
    printDocument.write(`
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${content}
        </body>
      </html>
    `);
    printDocument.close();
  };
  return (
    <>
      <div className="max-w-4xl mx-auto bg-white p-4" ref={contentRef}>
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <img src={`/headlogotemplate.png`} alt="Gunam Logo" width={90} />

          <div className="text-xs text-gray-500 text-center ">
            <div className="font-bold text-base">
              GUNAM SUPER SPECIALITY HOSPITAL
            </div>
            (A Unit of Hosur Super Speciality Hospitals Pvt. Ltd), #35, Tank
            street, (Old survey No:
            <br />
            120/2B2), Denkani kotta Road Krishnagiri (Dt), HOSUR - 635109
            <br />
            P: 04344-220599,F: info@gunamhospital.com,www.gunamhospital.com
          </div>
          {/* <img
          src={`/headlogotemplate.png`}
          alt="Gunam Logo"
          width={90}
          className="hidden sm:block"
        /> */}
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex flex-col mb-4 justify-between">
            <div className="flex flex-col">
              <div className="text-base font-bold">{patient_details?.name}</div>
              <div className="text-sm text-gray-700">
                {patient_details?.age_gender}
              </div>

              <div className="text-sm text-gray-700">
                {patient_details?.address}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-700">
                Patient Type: {patient_details?.patient_type}
              </div>
              <div className="text-sm text-gray-700">
                Source Name :{" "}
                {patient_details?.source_name
                  ? patient_details?.source_name
                  : "-"}
              </div>
            </div>
          </div>

          {/* Hospital Details */}
          <div className="flex flex-col text-sm">
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">Register No.</div>
              <div>: {hospital_details?.register_number}</div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">IP Number</div>
              <div>: {hospital_details?.ip_number}</div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">Admitted On</div>
              <div>: {hospital_details?.admitted_on}</div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">Discharged On</div>
              <div>: {hospital_details?.discharged_on}</div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">Bed No</div>
              <div>: {hospital_details?.bed_no}</div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-semibold w-24">Ward Name</div>
              <div>: {hospital_details?.ward_name}</div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-2">
          <div className="flex flex-col">
            <div className="text-sm font-bold">CONSULTANT(S):</div>
            <div className="text-sm">Dr.SENTHIL M (TNMC Reg No:60070)</div>
          </div>

          <div className="text-sm">
            <div className="font-bold border-gray-300 text-center underline">
              DISCHARGE SUMMARY
            </div>
            <div className="flex flex-col">
              <div>
                <span className="font-semibold">DIAGNOSIS:</span>{" "}
                {discharge_summary?.diagnosis}
              </div>
              <div>
                <span className="font-semibold">PROCEDURE:</span>{" "}
                {discharge_summary?.procedure}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="text-sm font-bold ">REASON FOR ADMISSION:</div>
            <div className="text-sm">
              {discharge_summary?.reason_for_admission}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="text-sm font-bold">PAST HISTORY:</div>
            <div className="text-sm">
              {" "}
              {discharge_summary?.patient_past_history}{" "}
            </div>
          </div>

          <div>
            <div className="text-sm font-bold">GENERAL EXAMINATION:</div>
            <div className="text-sm">
              {discharge_summary?.general_examination}
            </div>
            <div className="text-sm mt-2">
              <span className="font-semibold">CARDIOVASCULAR SYSTEM:</span> S1
              S2 +
              <br />
              <span className="font-semibold">RESPIRATORY SYSTEM:</span> B/L AE
              +
              <br />
              <span className="font-semibold">ABDOMEN:</span> Soft, BS+
              <br />
              <span className="font-semibold">CNS:</span> NFND
            </div>
          </div>

          <div className="flex flex-col">
            <div className="text-sm font-bold">LOCAL EXAMINATION:</div>
            <div className="text-sm">
              Swelling in supraumbilical region, tenderness+.
            </div>
          </div>

          {treatment_given && <div className="flex flex-col">
            <div className="text-sm font-bold">Treatments Given:</div>
            <table>
              <thead>
                <tr>
                  <th>Drug</th>
                  <th>Dosage</th>
                  <th>Frequency</th>
                </tr>
              </thead>
              <tbody>
                {treatment_given?.map((treatment, index) => (
                  <tr key={index}>
                    <td>{treatment.drug}</td>
                    <td>{treatment.dosage}</td>
                    <td>{treatment.frequency}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>}

          <div className="flex flex-col">
            <div className="text-sm font-bold">COURSE IN HOSPITAL:</div>
            <div className="text-sm">
              Patient was admitted with above mentioned complaints. On initial
              examination patient's vitals were PR : 79/min; BP : 130/90 mm Hg;
              SPO2 : 98% under room air. Diagnosed to have Supra-Umbilical
              Hernia. After thorough evaluation, patient underwent Laparoscopic
              Intraperitoneal Onlay Mesh Plus Hernioplasty under GA on
              09.12.2024. There were no intraoperative complications. Patient
              was shifted to the ward. Patient improved symptomatically and
              hence being discharged in stable condition with following advice.
            </div>
          </div>
        </div>

        <div className="flex flex-col text-xs text-white text-center bg-black p-2 mt-4">
          <div className="">
            <span className="text-base font-bold">
              GUNAM SUPER SPECIALITY HOSPITAL{" "}
            </span>
            (A unit of Hosur Super Speciality Hospitals Pvt.Ltd)
          </div>
          No.35, Tank Street, Opp. Government Hospital, Hosur - 635109,
          Krishnagiri (Dr) TAMIL NADU
        </div>
      </div>
      <button
        className="rounded-3xl bg-gray-200 py-2 px-3 text-white float-right mt-4"
        style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
        onClick={handlePrint}
      >
        Save and Print
      </button>
    </>
  );
};

export default DischargeSummary;
