import React from "react";
import { configJson } from "../../../../Constent/config";

const DataSheetFooter = ({ organization_id }) => {
  return (
    organization_id === configJson.ABC && (
      <div
        className="data-sheet-footer"
        style={{
          backgroundColor: "#ffffff",
          fontFamily: "Arial, sans-serif",
          borderTop: "1px solid #ccc",
          lineHeight: 1.5,
          paddingTop: "20px",
        }}
      >
        <style>
          {`
            @media print {
              body {
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
              }
                .disc a{
                color:#000 !important;
                }
                .discountProd{
                color:#000 !important;
                }
.footer-details.container.mt-4 .col-md-3 {

  margin-top: -10px !important;
}
              .data-sheet-footer {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: 12px;
                page-break-after: always;
              }

              .footer-offer {
                font-size: 16px;
                background: #004d00 !important;
                font-weight: bold;
                margin-bottom: 15px;
                padding: 10px;
              }

              .footer-offer span {
                font-size: 16px;
                font-weight: bold;
                color: #fff !important;
                padding: 10px;
              }

              .footer-details .row {
                display: flex !important;
                margin-top: -20px !important;
              }

              .footer-title .container .row {
                display: flex !important;
                justify-content: center;
                align-items: center !important;
              }
                .dsc{

                }
            }
          `}
        </style>
        {/* Footer Title Section */}
        <div
          style={{
            fontWeight: "bold",
            fontSize: "10px",
            color: "#d11a1a",
            marginBottom: "10px",
            textAlign:"center"
          }}
          className="footer-title container"
        >
          <div className="row align-items-center">
            <div className="col-md-12">
              <span>*24 Hours LAB & PHARMACY Digital X-Ray / ECG</span>
            </div>
            {/* <div className="col-md-6 text-md-end cstmmailabc">
              <a
                href="mailto:admin@abcmedicalclinic.co.in"
                style={{
                  color: "#000",
                  textDecoration: "none",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                <i className="fas fa-envelope"></i> admin@abcmedicalclinic.co.in
              </a>
              <a
                href="http://www.abcmedicalclinic.co.in"
                style={{
                  color: "#000",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
              >
                <i className="fas fa-globe"></i> www.abcmedicalclinic.co.in
              </a>
            </div> */}
          </div>
          <div style={{ margin: "5px", textAlign: "left" }}>
          <div className="col-md-12" style={{textAlign: "center"}}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "10px",
                  }}
                >
                  OTTIYAMBAKKAM
                </p>
                <span className="contactphone">Contact: 9884653905 / 044-22770109</span>
                </div>
              </div>
        </div>
{/* 
        <div
          style={{
            fontSize: "16px",
            backgroundColor: "#004d00",
            fontWeight: "bold",
            marginBottom: "15px",
            color: "#ffffff",
            padding: "10px",
            textAlign: "center",
          }}
          className="disc"
        >
          <span className="discountProd">
            20% Discount on Medicine Offer Benefits To Customers
          </span>
          <a
            href="https://wa.me/9884672776"
            style={{
              color: "#ffffff",
              fontSize: "16px",
              marginLeft: "10px",
              textDecoration: "none",
            }}
          >
            98846 72776
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginBottom: "15px",
          }}
          className="footer-details container mt-4"
        >
          <div className="row">
            <div className="col-md-2">
              <p
                style={{
                  fontSize: "16px",
                  color: "#004d00",
                  fontWeight: "bold",
                }}
              >
                BRANCHES:
              </p>
            </div>

            <div className="col-md-3">
              <div style={{ margin: "10px", textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "14px",
                  }}
                >
                  SITHALAPAKKAM
                </p>
                <span>Contact: 9884653906 / 044-22770708</span>
              </div>
              <div style={{ margin: "10px", textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "14px",
                  }}
                >
                  WEST TAMBARAM
                </p>
                <span>Contact: 8778732137</span>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ margin: "10px", textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "14px",
                  }}
                >
                  OTTIYAMBAKKAM
                </p>
                <span>Contact: 9884653905 / 044-22770109</span>
              </div>
              <div style={{ margin: "10px", textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "14px",
                  }}
                >
                  PERUMBAKKAM, SEMMANCHERI
                </p>
                <span>Contact: 9442182874</span>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ margin: "10px", textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#d11a1a",
                    fontSize: "14px",
                  }}
                >
                  THALAMBUR
                </p>
                <span>Contact: 6381544117</span>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    )
  );
};

export default DataSheetFooter;
