import React, { useEffect, useRef, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { FaMicrophone } from "react-icons/fa";
import { BsFillMicMuteFill } from "react-icons/bs";
import {
  Feedrecorder,
  TimeFeedCounterContainer,
} from "../../../ThemeStyle/RegisterStyle";
import { useStopwatch } from "react-timer-hook";
import { configJson } from "../../../../Constent/config";
import MessageModel from "./MessageModel";
import {
  convertToKolkataTime,
  convertToKolkataTimes,
  convertToUTC,
} from "../../../../helpers";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  addPatientToList,
  updatePatientStatus,
} from "../../../../redux/slices/patientsSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FaStop } from "react-icons/fa6";
import "./chatbot.css";

// import CustomAudioPlayer from "./CustomAudioPlayer";

function Audiorecord({
  patientId,
  interactionIdFirst,
  handleExportImageUpload,
}) {
  // console.log(interactionIdFirst,"interactionIdFirst")
  const { timeZone } = useSelector((state) => state.settings);
  const [audioUrl, setAudioUrl] = useState(null); // To store the URL of the recorded audio
  const [isRecording, setIsRecording] = useState(false); // To manage recording state
  const recorderRef = useRef(null); // To store the recorder instance
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const [formData, setFormData] = useState({
    patient_id: "",
    doctor_id: "",
    timestamputc: "",
    organization_id: "",
    timestamp: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [decode, setDecode] = useState({});
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const animationIdRef = useRef(null);
  const microphoneRef = useRef(null);
  const [showModalBtn, setShowModalBtn] = useState(false);
  const handleRetry = async () => {
    setShowModalBtn(false);
    setIsLoading(true);
    await handleSubmitAudio(audioUrl);
  };

  const handleCloseModal = () => {
    setShowModalBtn(false);
  };
  // // Keep the ref in sync with the state
  // useEffect(() => {
  //   sessionStorage.setItem("isRecording", isRecording);
  // }, [isRecording]);
  useEffect(() => {
    // Retrieve the current value from sessionStorage
    const currentRecordingStatus = sessionStorage.getItem("isRecording");
    // Only update sessionStorage if the value is different from the current state
    if (currentRecordingStatus !== String(isRecording)) {
      sessionStorage.setItem("isRecording", isRecording);
    }
  }, [isRecording]);

  if (!recorderRef.current) {
    recorderRef.current = new MicRecorder({ bitRate: 128 });
  }
  useEffect(() => {
    const canvas = canvasRef.current;
    const resizeCanvas = () => {
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      stopVisualization();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startVisualization = () => {
    // Initialize Audio Context
    if (
      !audioContextRef.current ||
      audioContextRef.current.state === "closed"
    ) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 2048;
    analyserRef.current.smoothingTimeConstant = 0.8;
    const bufferLength = analyserRef.current.fftSize;
    dataArrayRef.current = new Uint8Array(bufferLength);

    // Access the microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        microphoneRef.current =
          audioContextRef.current.createMediaStreamSource(stream);
        microphoneRef.current.connect(analyserRef.current);
        drawWaveform();
      })
      .catch((err) => {
        console.error("Error accessing microphone for visualization:", err);
        toast.error("Microphone access denied for visualization.");
      });
  };

  const stopVisualization = () => {
    if (animationIdRef.current) {
      cancelAnimationFrame(animationIdRef.current);
    }
    if (microphoneRef.current) {
      microphoneRef.current.disconnect();
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current
        .close()
        .then(() => {
          audioContextRef.current = null; // Nullify after closing
        })
        .catch((err) => {
          console.error("Error closing AudioContext:", err);
        });
    }
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const draw = () => {
      animationIdRef.current = requestAnimationFrame(draw);

      analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

      // Clear the canvas
      canvasCtx.fillStyle = "#06AEB6"; // Dark background
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      // Create gradient
      const gradient = canvasCtx.createLinearGradient(0, 0, WIDTH, 0);
      gradient.addColorStop(0, "#ffffff");
      gradient.addColorStop(1, "#ffffff");

      canvasCtx.lineWidth = 3;
      canvasCtx.strokeStyle = gradient;

      canvasCtx.beginPath();

      const sliceWidth = (WIDTH * 1.0) / analyserRef.current.fftSize;
      let x = 0;

      for (let i = 0; i < analyserRef.current.fftSize; i++) {
        const v = dataArrayRef.current[i] / 128.0;
        const y = (v * HEIGHT) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };
  //create a new interaction
  async function updatedata(url) {
    const formattedDateUTC = convertToUTC();
    const formattedDateKolkata = convertToKolkataTimes(
      formattedDateUTC,
      timeZone
    );
    // handleExportImageUpload();
    console.log("formattedDateupdatedata", formattedDateKolkata);
    const mydata = {
      interaction_id: interactionIdFirst,
      patient_id: formData.patient_id,
      doctor_id: formData.doctor_id,
      interaction_date: formattedDateKolkata,
      organization_id: formData.organization_id,
      audioUrl: url,
      // types: decode.role,
    };
    // console.log(mydata);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/feedRecorder`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.data.status === true) {
        console.log("Audio upload success full");
        if (interactionIdFirst) {
          localStorage.removeItem("interactionIdFirst");
        }
        dispatch(addPatientToList(response?.data?.result));
        // dispatch(addPatientToTop(response?.data?.result));
        const changeStatus_color = {
          patient_id: response?.data?.result?.patient_id,
          interaction_status: "0",
        };
        dispatch(updatePatientStatus(changeStatus_color));
        checkInteractionStatus(response?.data?.result?.interaction_id);
        navigate(`/activity?id=${response?.data?.result?.patient_id}`);
        if (decode?.role === "doctor") {
          navigate(`/activity?id=${response?.data?.result?.patient_id}`);
        } else if (decode?.role === "front office staff") {
          navigate(
            `/activity?id=${response?.data?.result?.patient_id}&doctor=null`
          );
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  //Check Status
  const checkInteractionStatus = async (interaction_id) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/interactions/status/${interaction_id}`,
        requestOptions
      );
      if (!response.ok) {
        toast.error("refersh the page");
      }
      const data = await response.json();
      if (data?.status_ok === "audio") {
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "raw-text") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };
        if (decode?.role === "doctor") {
          dispatch(updatePatientStatus(changeStatus));
        }
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "ingest") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };

        if (decode?.role === "doctor") {
          // console.log(window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` && isRecording ,window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` ,isRecording)
          if (
            window.location.pathname + window.location.search ===
            `/activity?id=${data?.interaction?.patient_id}`
          ) {
            console.log("id inside true");
            const recordStatus = sessionStorage.getItem("isRecording");
            if (recordStatus === "true") {
              console.log("isRecordingRef.current");
              const changeStatus_color = {
                patient_id: data?.interaction?.patient_id,
                interaction_status: "6",
              };
              dispatch(updatePatientStatus(changeStatus_color));
            } else if (recordStatus === "false") {
              console.log("id inside isRecording else");
              dispatch(updatePatientStatus(changeStatus));
              navigate(`/activity?id=${data?.interaction?.patient_id}`);
            }
          } else {
            console.log("id inside else");
            const changeStatus_color = {
              patient_id: data?.interaction?.patient_id,
              interaction_status: "6",
            };
            dispatch(updatePatientStatus(changeStatus_color));
          }
        } else if (decode?.role === "front office staff") {
          const targetPath = `/activity?id=${data?.interaction?.patient_id}`;
          if (
            window.location.pathname + window.location.search === targetPath &&
            !isRecording
          ) {
            navigate(
              `/activity?id=${data?.interaction?.patient_id}&doctor=null`
            );
          }
          // navigate(`/activity?id=${data?.interaction?.patient_id}&doctor=null`);
        }
      }
    } catch (error) {
      console.error("Error checking button status:", error);
    }
  };
  //check 10s ones
  function startPolling({ interaction_id }) {
    checkInteractionStatus(interaction_id);
  }

  const handleSubmitAudio = async (blobUrl) => {
    if (!blobUrl) {
      setMessage("Please record audio or upload a file.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }
    const formattedDateUTC = convertToUTC();
    const formattedDateKolkata = convertToKolkataTimes(
      formattedDateUTC,
      timeZone
    );
    const audioBlob = await fetch(blobUrl).then((res) => res.blob());
    setIsLoading(true);
    console.log("formattedDatehandleSubmitAudio", formattedDateKolkata);
    const retryUploadWithFieldValidation = async () => {
      let result = formattedDateKolkata
        ?.replace(/[-:]/g, "_")
        .replace(", ", "_");
      const formData2 = new FormData();
      formData2.append(
        "file_name",
        `audio_files/${formData?.patient_id}_${result}.mp3`
      );
      formData2.append("audio_file", audioBlob, `${formData?.patient_id}.mp3`);

      const maxRetries = 2;
      let attempts = 0;

      while (attempts < maxRetries) {
        try {
          const response = await fetch(
            `${configJson.backend_URL}/soapPrompt/upload`,
            {
              method: "POST",
              body: formData2,
            }
          );

          if (response.ok) {
            const result = await response.json();
            console.log("Audio stored in bucket");
            const url = result.gcs_uri;

            // Here add specific validation for fields if necessary
            if (formData?.patient_id) {
              console.log("1q");
              await updatedata(url);
              setIsLoading(false);
              setShowModalBtn(false); // Ensure modal is closed on success
              return;
            } else {
              console.warn("Invalid fields found.");
              attempts++;
            }
          } else {
            console.warn("Attempt failed:", response.status);
            attempts++;
          }
        } catch (error) {
          console.error("Error on attempt", attempts, ":", error);
          attempts++;
        }
      }

      // If max retries are reached, show the modal
      setShowModalBtn(true);
      setIsLoading(false);
    };

    // Initial upload attempt with field-specific retries
    await retryUploadWithFieldValidation();
  };

  // Start recording
  const startRecording = () => {
    recorderRef.current
      .start()
      .then(() => {
        setIsRecording(true); // Change state to recording
        start();
        startVisualization();
      })
      .catch((e) => {
        console.error(e);
        stopVisualization();
      });
  };

  // Stop recording
  const stopRecording = () => {
    recorderRef.current
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const url = URL.createObjectURL(blob);
        setAudioUrl(url); // Set the URL for playback
        setIsRecording(false); // Change state to not recording
        handleSubmitAudio(url);
        reset(0, false);
        stopVisualization();
      })
      .catch((e) => {
        console.error(e);
        stopVisualization();
      });
  };
  //show or close model
  const setShowModals = (data) => {
    setShowModal(false);
  };
  //set Today Date
  useEffect(() => {
    const initializeForm = () => {
      // Convert date to UTC and Kolkata time
      const formattedDateUTC = convertToUTC();
      const formattedDateKolkata = convertToKolkataTime(formattedDateUTC);
      // console.log(formattedDateKolkata);

      // Get token from session storage
      const token = sessionStorage.getItem("orgtoken");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        // Decode token and update state
        const decoded = jwtDecode(token);
        setDecode(decoded);

        setFormData((prevFormData) => ({
          ...prevFormData,
          patient_id: patientId,
          timestamp: formattedDateKolkata,
          timestamputc: formattedDateUTC,
          organization_id: decoded?.organization_id,
          doctor_id: decoded?.role === "doctor" ? decoded?.doctor_id : null,
        }));
      } catch (error) {
        console.error("Error decoding token:", error);
        navigate("/login");
      }
    };

    initializeForm();
  }, [setFormData, navigate, patientId]);
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  // console.log(isRecording);
  const handleDownload = () => {
    // Create a temporary link element
    let result = formData?.timestamp?.replace(/[-:]/g, "_").replace(", ", "_");
    const a = document.createElement("a");
    a.href = audioUrl; // Set the href to the blob URL
    a.download = `${formData?.patient_id}_${result}.mp3`; // Specify a default file name for download
    a.click(); // Programmatically click the link to trigger the download
    a.remove(); // Clean up the element
  };

  // Modal logic
  const RetryModal = ({ show, onRetry, onClose, handleDownloads }) => (
    <div
      className={`modal ${show ? "d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload Failed</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Failed to upload the audio file due to invalid fields.</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleDownloads}
            >
              Download Audio
            </button>
            <button type="button" className="btn btn-primary" onClick={onRetry}>
              Retry
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    // <Feedrecorder style={styles.container}>
    //   <button
    //     className="btn-theme"
    //     onClick={isRecording ? stopRecording : startRecording}
    //   >
    //     {isRecording ? (
    //       <FaStop className="text-red-500 text-2xl" /> // Example: "text-4xl" makes the icon extra large
    //     ) : (
    //       <FaMicrophone className="text-white-500 text-2xl" />
    //     )}
    //   </button>
    //   {/*
    //   {audioUrl && !isRecording && (
    //     <div>
    //       <div className="mb-1 audio-wave-out">
    //         <audio src={audioUrl} controls className="w-100" />
    //         <CustomAudioPlayer audioSrc={audioUrl} />
    //       </div>
    //     </div>
    //   )} */}
    //   <TimeFeedCounterContainer>
    //     <div className="time-cards">
    //       <span className="time-font">
    //         {hours > 9 ? "" : <span>0</span>}
    //         {hours}:
    //       </span>
    //       <span className="time-font">
    //         {minutes > 9 ? "" : <span>0</span>}
    //         {minutes}:
    //       </span>
    //       <span className="time-font">
    //         {seconds > 9 ? "" : <span>0</span>}
    //         {seconds}
    //       </span>
    //     </div>
    //   </TimeFeedCounterContainer>
    //   <MessageModel
    //     showModal={showModal}
    //     messageData={message}
    //     setShowModal={setShowModals}
    //   />
    //   <canvas ref={canvasRef} style={styles.canvas}></canvas>
    //   {showModalBtn ? (
    //     <RetryModal
    //       show={showModalBtn}
    //       onRetry={handleRetry}
    //       onClose={handleCloseModal}
    //       handleDownloads={handleDownload}
    //     />
    //   ) : (
    //     ""
    //   )}
    // </Feedrecorder>
    <>
      <div className="flex items-center w-full relative">
        <div
          className={`bg-white rounded-full p-[5px] flex items-center justify-center z-20 border ${
            isRecording ? "border-[#FF0000]" : "border-[#06AEB6]"
          }`}
        >
          <button
            className={`text-white rounded-full p-3 ${
              isRecording ? "bg-[#FF0000] pulse-effect" : "bg-[#FF0000]"
            }`}
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? (
              <FaStop className="text-4xl" />
            ) : (
              <FaMicrophone className="text-4xl" />
            )}
          </button>
        </div>
        <div className="bg-[#06AEB6] text-white p-3 rounded-full absolute left-10 z-10 flex items-center h-[55px] overflow-hidden">
          <div className="flex items-center gap-1 text-sm ml-8">
            <span className="">
              {hours > 9 ? "" : <span>0</span>}
              {hours}:
            </span>
            <span className="">
              {minutes > 9 ? "" : <span>0</span>}
              {minutes}:
            </span>
            <span className="">
              {seconds > 9 ? "" : <span>0</span>}
              {seconds}
            </span>
          </div>
          <div className="min-w-[70px]">
            {/* {isRecording ? (
              <video
                src={/wave.mp4}
                autoPlay
                loop
                muted
                playsinline
                width={100}
                height={80}
                className="mix-blend-lighten"
              />
            ) : (
              <img src={/wave.png} width={100} height={80} />
            )} */}
            <canvas ref={canvasRef} style={styles.canvas}></canvas>
          </div>
        </div>
      </div>

      <MessageModel
        showModal={showModal}
        messageData={message}
        setShowModal={setShowModals}
      />
      {showModalBtn ? (
        <RetryModal
          show={showModalBtn}
          onRetry={handleRetry}
          onClose={handleCloseModal}
          handleDownloads={handleDownload}
        />
      ) : (
        ""
      )}
    </>
  );
}

const styles = {
  canvas: {
    width: "80%",
    maxWidth: "800px",
    height: "50px",
    // border: "1px solid #ccc",
    borderRadius: "4px",
    margin: "0 auto",
    display: "block",
  },
};
export default Audiorecord;
