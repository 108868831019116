import React, { useEffect, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import {
  SoapEditorModalContainer,
  SoapnotesModelcontainer,
} from "./DoctorFeedStyle";
import { configJson } from "../../../../Constent/config";
import { convertToKolkataTime, convertToUTC } from "../../../../helpers";

const MarkdownEditorModal = ({
  data,
  id,
  show,
  handleClose,
  fetchInteractions,
  patient_id,
  name,
}) => {
  const [markdown, setMarkdown] = useState("## Edit this Markdown text");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("success");
  const [loading, setLoading] = useState(false);
  const [date,setDate]=useState(null);
  console.log(patient_id, name);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  const handleChange = (event) => {
    setMarkdown(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = date.replace(/[-:]/g, "_").replace(", ", "_");
    const formdata = { notes: markdown, detail_id: id ,file_name:`${patient_id}_${result}.txt`};
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/update/soapnotes`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata), // Convert the data to a JSON string
        }
      );

      if (response.ok) {
        const result = await response.json();
        // Display success message
        setMessage(`Update successfully: ${result.message}`);
        setVariant("success");
        handleClose();
        fetchInteractions(patient_id, name);
      } else {
        // Bad request
        const result = await response.json();
        setMessage(`Error ${result.message}`);
        setVariant("danger");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(`Error ${error}`);
      setVariant("danger");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMarkdown(data);
    const formattedDate = convertToUTC();
    const d = convertToKolkataTime(formattedDate);
    setDate(d);
  }, [data, id]);

  return (
    <SoapnotesModelcontainer>
      {/* Modal containing the MarkdownEditor */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Markdown Editor</Modal.Title>
        </Modal.Header>
        {message && <Alert variant={variant}>{message}</Alert>}
        <Modal.Body>
          <SoapEditorModalContainer>
            {/* MarkdownEditor - Textarea for Markdown input */}
            <form onSubmit={handleSubmit}>
              <textarea
                value={markdown}
                onChange={handleChange}
                rows="15"
                placeholder="Enter Markdown here..."
                className="markdown-input"
                aria-label="Markdown editor"
              />
              <div class="d-flex justify-content-center">
                {loading ? (
                  <Button type="button" className="theme-button">Loading..</Button>
                ) : (
                  <Button type="submit" className="theme-button">
                  Confirm and Save
                </Button>
                )}
                
              </div>
            </form>
            {/* <div className="preview-container">
              <h3>Preview:</h3>
              <div className="markdown-preview">
                <ReactMarkdown>{markdown}</ReactMarkdown>
              </div>
            </div> */}
          </SoapEditorModalContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </SoapnotesModelcontainer>
  );
};

export default MarkdownEditorModal;
