import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Themebutton } from "../../Engine-Page/mainEngine/MainEngineStyle";
import { configJson } from "../../../Constent/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setToken } from "../../../redux/slices/authSlice";

function LoginButtonModel({ show, onClose }) {
  const [token, setTokens] = useState(null);
  const [tokenrole, setTokenRole] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRole = async (roledata) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/role`,
        {
          // Replace with your backend API endpoint
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, role: roledata }), // Sending token and role in the body
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.status) {
          sessionStorage.setItem("orgtoken", data.token);
          dispatch(setToken(data.token));  
          localStorage.removeItem('patients');
          localStorage.removeItem('interactionIdFirst');
          //Peninsula Login
          const orgId = jwtDecode(data.token);
          // console.log("datalogin",orgId)
          // if (orgId.organization_id === 10) {
          //   navigate("/doctor_consent");
          // }
          //  else {
            onClose();
            // Check the user's role and navigate accordingly
            if (data.role === "admin") {
              navigate("/adminpage");
            } else if (data.role === "doctor") {
              if (orgId.country === "United States of America"){
                navigate("/doctor_consent");
              }
              else{
                navigate("/token");
              }
            } else if (data.role === "front office staff") {
              navigate("/createtoken");
            } else if (data.role === "technician") {
              navigate("/tech-activity");
            }
          
        } else {
          toast(data.message);
          onClose();
        } // Store token in local storage or state
      } else {
        // If there is an error
        toast(data.message);
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast("An error occurred while logging in.");
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded) {
        const role = decoded.role.split(",");
        setTokenRole(role);
      }
      setTokens(token);
    } else {
      toast("Role not changed");
      onClose();
    }
  }, []);
  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Select Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tokenrole?.length > 0 ? (
          <>
            <Themebutton className="text-center">
              {tokenrole.map((role, index) => (
                <Button
                  variant="primary"
                  type="button"
                  className="new-style-btn"
                  key={index}
                  onClick={() => handleRole(role)}
                >
                  {role}
                </Button>
              ))}{" "}
            </Themebutton>
          </>
        ) : (
          ""
        )}
       
      </Modal.Body>
    </Modal>
  );
}

export default LoginButtonModel;
