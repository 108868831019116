import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import {jwtDecode} from "jwt-decode";
import { configJson } from "../../Constent/config";
import "./DoctorDetails.css"

function DoctorDetails() {
  const [doctorDetails, setDoctorDetails] = useState({});
  const [error, setError] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedSalutation, setSelectedSalutation] = useState(""); // New state for salutation
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch specialties
  const fetchSpecialties = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setSpecialties(data);
      }
    } catch (error) {
      console.error("Error fetching specialties:", error);
      setError("An error occurred while fetching specialties.");
    }
  };

  // Fetch doctor data
  const fetchDoctorDetails = async (doctorId, token) => {
    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/specific/${doctorId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const doctorInfo = await response.json();
        setDoctorDetails(doctorInfo.doctor);
        setSelectedSpecialty(doctorInfo.doctor.speciality_id || "");
        setSelectedSalutation(doctorInfo.doctor.salutation || ""); // Fetch salutation
      } else {
        setError("Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor details:", error);
      setError("An error occurred while fetching doctor details.");
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const doctorId = decoded.doctor_id;
        fetchSpecialties();
        fetchDoctorDetails(doctorId, token);
      } catch (error) {
        setError("Invalid token. Please login again.");
      }
    } else {
      setError("No token found. Please login again.");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("orgtoken");

    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/update_doctor/${doctorDetails.doctor_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          first_name: doctorDetails.first_name,
          last_name: doctorDetails.last_name,
          email: doctorDetails.email,
          phone_number: doctorDetails.phone_number,
          speciality_id: selectedSpecialty,
          license_no: doctorDetails.license_no,
          salutation: selectedSalutation, // Include salutation in the update
        }),
      });

      if (response.ok) {
        const updatedDoctor = await response.json();
        setDoctorDetails(updatedDoctor);
        setSuccessMessage("Details updated successfully!");
       // Immediately reload the app
      window.location.reload();
      } else {
        setError("Failed to update doctor details.");
      }
    } catch (error) {
      console.error("Error updating doctor details:", error);
      setError("An error occurred while updating doctor details.");
    }
  };

  return (
    <div className="p-3 profileinfotab">
      <h4>Manage your profile information</h4>
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorSalutation">
              <Form.Label>Salutation</Form.Label>
              <Form.Control
                as="select"
                value={selectedSalutation}
                onChange={(e) => setSelectedSalutation(e.target.value)}
              >
                {/* Default value */}
                <option value="">Select Salutation</option>
                <option value="Mr">Mr</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={doctorDetails.first_name || ""}
                onChange={(e) => setDoctorDetails({ ...doctorDetails, first_name: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={doctorDetails.last_name || ""}
                onChange={(e) => setDoctorDetails({ ...doctorDetails, last_name: e.target.value })}
              />
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorSpecialty">
              <Form.Label>Specialty</Form.Label>
              <Form.Control
                as="select"
                value={selectedSpecialty}
                onChange={(e) => setSelectedSpecialty(e.target.value)}
              >
                <option value="">Select Specialty</option>
                {specialties.map((specialty) => (
                  <option key={specialty.speciality_id} value={specialty.speciality_id}>
                    {specialty.specialty_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorContact">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                value={doctorDetails.phone_number || ""}
                onChange={(e) => setDoctorDetails({ ...doctorDetails, phone_number: e.target.value })}
              />
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={doctorDetails.email || ""}
                onChange={(e) => setDoctorDetails({ ...doctorDetails, email: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-2">
            <Form.Group controlId="formDoctorLicense">
              <Form.Label>License No</Form.Label>
              <Form.Control
                type="text"
                value={doctorDetails.license_no || ""}
                onChange={(e) => setDoctorDetails({ ...doctorDetails, license_no: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-3">
          <Button className="updatedetbtn" variant="primary" type="submit">
            Update Details
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DoctorDetails;

