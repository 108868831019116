import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConsentContainer } from "./ConsentRequestStyle";
import { configJson } from "../../../../Constent/config";
import Sidebar from "../../../Home/Components/Sidebar/Sidebar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneNumberContainer } from "../../Doctorstyle";
import { QRCodeSVG } from "qrcode.react";
import { convertToUTC } from "../../../../helpers";
import { toast } from "react-toastify";

const Consentrequestform = () => {
  const [decode, setDecode] = useState(null);
  const [formsubmit, setFormsubmit] = useState(false);
  const [showqr, setShowqr] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    doctorName: "",
  });
  const [date, setDate] = useState(null);
  const [phone, setPhone] = useState("+");
  const [pnone_error, setPhone_error] = useState(true);
  const [qrValue, setQrValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const send_link_url = window.location.origin;
  console.log(send_link_url);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //Check Status
  const checkRecordButtonStatus = async (id, formDataWithId) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/patient/check_status/${id}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.patientStatus === true) {
        //setTimeout(() => navigate("/engine", { state: formDataWithId }), 2000);
        navigate("/engine", { state: formDataWithId });
      } else if (data.patientStatus === false) {
        setTimeout(() => startPolling({ id, formDataWithId }), 3000);
      }
    } catch (error) {
      console.error("Error checking button status:", error);
    }
  };
  // console.log(isButtonEnabled);
  //check 2s ones
  function startPolling({ id, formDataWithId }) {
    checkRecordButtonStatus(id, formDataWithId);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhone_error(true);
    // Validate form fields
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.doctorName 
    ) {
      alert("Please fill out all fields.");
      return;
    }
    console.log(phone);
    console.log(phone.length < 11 || phone.length > 11, phone.length);
    console.log(phone.slice(0, 1));
    if (phone.length > 11 || phone.length < 11) {
      setPhone_error(false);
      return;
    } else if (phone.slice(0, 1) !== "1") {
      setPhone_error(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/create_patient`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: formData.first_name,
            last_name: formData.last_name,
            // date_of_birth: formData.date_of_birth,
            organization_id: decode.organization_id,
            doctor_id: decode.doctor_id,
            phone_number: "+" + phone,
            // language: "en",
            send_link: send_link_url,
            interaction_date: date,
          }),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.patientStatus === "verified") {
          navigate("/engine", {
            state: {
              patientFirstName: data.patient.first_name,
              patientLastName: data.patient.last_name,
              patient_id: data.patient.patient_id,
              doctorFirstName: decode.first_name,
              doctorLastName: decode.last_name,
              doctor_id: data.interactions.doctor_id,
              organization_id: data.patient.organization_id,
              // language: data.patient.language,
              interaction_id: data.interactions.interaction_id,
            },
          });
        } else if (data.patientStatus === "notverified") {
          if (data?.datatoken) {
            setQrValue(data?.datatoken);
            setFormsubmit(true);
          }
          if (data?.patient && data?.interactions) {
            const formDataWithId = {
              patientFirstName: data.patient.first_name,
              patientLastName: data.patient.last_name,
              patient_id: data.patient.patient_id,
              doctorFirstName: decode.first_name,
              doctorLastName: decode.last_name,
              doctor_id: data.interactions.doctor_id,
              organization_id: data.patient.organization_id,
              // language: data.patient.language,
              interaction_id: data.interactions.interaction_id,
            };
            startPolling({ id: data.patient.patient_id, formDataWithId });
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      alert("There was an error creating the patient.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Decode JWT token
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token); // Corrected function name
      if (decoded) {
        setDecode(decoded);
        // Set doctor name and email in formData once decode is available
        setFormData((formData)=>({
          ...formData,
          doctorName: decoded
            ? `${decoded.first_name} ${decoded.last_name}`
            : "",
        }));
        const formattedDate = convertToUTC();
        setDate(formattedDate);
      }
      // if (decode.organization_id !== 10) {
      //   navigate("/login");
      // }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    // <Sidebar setuser={true}>
      <div className="d-flex justify-content-center align-items-center">
        {/* <TopBar showUser={true} /> */}
        <Container fluid style={{ margin: "10px" }}>
          <Row className="d-flex align-items-center justify-content-center ">
            <Col
              md={8}
              className="rounded-5"
              style={{ border: "1px solid black" }}
            >
              <ConsentContainer>
                <div className="header-text-part">
                  <h2 className="header-text">Medscribe</h2>
                  <h5 className="text-center mb-4">Request Consent Form</h5>
                </div>
                {showqr && qrValue !== "" ? (
                  <>
                    <div className="qr-image-card">
                      <QRCodeSVG
                        value={qrValue} // The content of the QR code
                        size={300} // Size of the QR code
                        bgColor="#ffffff" // Background color
                        fgColor="#000000" // Foreground color
                        level="M" // Error correction level ('L', 'M', 'Q', 'H')
                      />
                    </div>
                  </>
                ) : (
                  <Form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="p-3"
                  >
                    <Form.Group className="mb-3" controlId="doctorName">
                      <Form.Label
                        style={{
                          display: "inline",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Doctor Name:&nbsp;
                      </Form.Label>
                      <span
                        style={{
                          display: "inline-block",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          color: "#6c757d",
                        }}
                      >
                        {formData.doctorName}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>Patient First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>Patient Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        name="date_of_birth"
                        value={formData.date_of_birth}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </Form.Group> */}
                    <PhoneNumberContainer>
                      <label className="form-label">Phone Number</label>
                      <div className="form-group">
                        <PhoneInput
                          international
                          country="us"
                          placeholder="phone"
                          name="phone"
                          value={phone}
                          onChange={setPhone}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                            prefix: "+",
                          }}
                        />
                        <p>{!pnone_error ? "Enter valid US number" : ""}</p>
                      </div>
                    </PhoneNumberContainer>
                    <div className="d-flex justify-content-center text-center">
                      {!formsubmit ? (
                        <div>
                          <div className="btn-log">
                            {isLoading ? (
                              <Button type="button" className="reg-sub-btn">
                                loading...
                              </Button>
                            ) : (
                              <Button type="submit" className="reg-sub-btn">
                                submit
                              </Button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="btn-log">
                          <Button
                            type="button"
                            onClick={() => setShowqr(true)}
                            className="reg-sub-btn"
                          >
                            Show Qr
                          </Button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </ConsentContainer>
            </Col>
          </Row>
        </Container>
      </div>
    // </Sidebar>
  );
};

export default Consentrequestform;
