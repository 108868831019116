import React, { useContext, useEffect, useState } from "react";
import {
  TopbarContainer,
  UserProfile,
  TopbarRight,
  SideTopbarContainer,
} from "./TopbarStyle";
import { Image } from "react-bootstrap";
import logo from "../../../../assets/medscribe/Medscribe-logo-White.png";
import usericons from "../../../../assets/user/AvatarIcon.png";
import { Dropdown } from "react-bootstrap";
// import { FaRegUser } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
// import { UserContext } from "../../../context/UserContext";
// import { jwtDecode } from "jwt-decode";
import "./topbar.css";
import { Tooltip } from "antd";
import LoginButtonModel from "../../../Login-page/Components/LoginButtonModel";
import { FaUserFriends } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../../redux/slices/patientsSlice";
import { clearToken } from "../../../../redux/slices/authSlice";
import { configJson } from "../../../../Constent/config";


function TopBar({ showUser }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [persondata, setpersondata] = useState({});
  const decode = useSelector((state) => state.auth.decodedToken);

  // const [, setToken] = useContext(UserContext);

  // useEffect(() => {
  //   let token = sessionStorage.getItem("orgtoken");
  //   // if (token === null) {
  //   //   token = sessionStorage.getItem("orgtoken");
  //   // }

  //   if (token) {
  //     console.log("null");
  //     setpersondata({
  //       name: "",
  //       email: "",
  //     });
  //   } else {
  //     if (token) {
  //       console.log("nullnot");
  //       let data = jwtDecode(token);
  //       if (data.last_name !== null) {
  //         setpersondata({
  //           name: data.first_name + " " + data.last_name,
  //           email: data.email,
  //         });
  //       } else {
  //         setpersondata({
  //           name: data.first_name,
  //           email: data.email,
  //         });
  //       }
  //     }
  //   }
  // }, []);


  const handlelogout = () => {
    console.log("logout");
    navigate("/login");
    sessionStorage.removeItem("orgtoken");
    dispatch(clearToken());
    dispatch(resetState());
    // setToken(null);
  };
  return (
    <>
      <TopbarContainer>
        <div className="logo-container">
          <Image
            src={logo}
            title="DeNotes"
            alt="DeNotes"
            className="denotes-logo"
            width={40}
          />
          {showUser ? (
            <TopbarRight className="!z-50">
              <div className="text-white mr-2">{decode?.email}</div>
              <UserProfile>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" size="sm">
                    <Image
                      className="hp-img-right"
                      src={usericons}
                      // roundedCircle
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h5>{decode?.first_name}</h5>
                      <p>{decode?.email}</p>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {/* <Dropdown.Item className="se-link" as={Link} to={``}>
                      <FaRegUser size={14} />
                      {"Manage Account"}
                    </Dropdown.Item> */}
                    <Dropdown.Item className="se-link" onClick={handlelogout}>
                      <MdLogout size={16} /> {"Logout"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </UserProfile>
            </TopbarRight>
          ) : (
            <div></div>
          )}
        </div>
      </TopbarContainer>
    </>
  );
}

export default TopBar;

export function SideTopbar({ showUser }) {
  const navigate = useNavigate();
  // const [persondata, setpersondata] = useState({});
  // const [, setToken] = useContext(UserContext);
  const [roleopen, setRoleOpen] = useState(false);
  const decode = useSelector((state) => state.auth.decodedToken);
  const doctorId = decode?.doctor_id; // Extract doctorId from decodedToken
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState(null);
  console.log(decode,"decodecheck")


  useEffect(() => {
    if (doctorId) {
      const token = sessionStorage.getItem("orgtoken");
      if (token) {
        fetchDoctorDetails(doctorId, token);
      }
    }
  }, [doctorId]);

  const fetchDoctorDetails = async (doctorId, token) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/doctor/specific/${doctorId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const doctorInfo = await response.json();
        console.log("Fetched doctor details:", doctorInfo);
        setDoctorDetails(doctorInfo.doctor);
      } else {
        setError("Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor details:", error);
      setError("An error occurred while fetching doctor details.");
    }
  };

   // Fetch doctor details only if the user is a doctor
   useEffect(() => {
    if (decode?.role === "doctor") {
      const fetchDoctorDetails = async (doctorId, token) => {
        try {
          const response = await fetch(
            `${configJson.backend_URL}/doctor/specific/${doctorId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.ok) {
            const doctorInfo = await response.json();
            setUserDetails({
              salutation: doctorInfo.doctor.salutation,
              first_name: doctorInfo.doctor.first_name,
              last_name: doctorInfo.doctor.last_name,
            });
          } else {
            setError("Failed to fetch doctor details.");
          }
        } catch (error) {
          console.error("Error fetching doctor details:", error);
          setError("An error occurred while fetching doctor details.");
        }
      };

      const token = sessionStorage.getItem("orgtoken");
      if (decode?.doctor_id && token) {
        fetchDoctorDetails(decode.doctor_id, token);
      }
    } else {
      // For non-doctor users, use decode directly
      setUserDetails({
        salutation: decode?.salutation || "",
        first_name: decode?.first_name || "",
        last_name: decode?.last_name || "",
      });
    }
  }, [decode]);

  // useEffect(() => {
  //   let token = sessionStorage.getItem("orgtoken");
  //   if (token === null) {
  //     token = sessionStorage.getItem("doctor_token");
  //   }

  //   if (token === null) {
  //     console.log("null");
  //     setpersondata({
  //       name: "",
  //       email: "",
  //     });
  //   } else {
  //     if (token) {
  //       // console.log("nullnot");
  //       let data = jwtDecode(token);
  //       if (data.last_name !== null) {
  //         setpersondata({
  //           name: data.first_name + " " + data.last_name,
  //           email: data.email,
  //         });
  //       } else {
  //         setpersondata({
  //           name: data.first_name,
  //           email: data.email,
  //         });
  //       }
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const token = sessionStorage.getItem("orgtoken");
  //   if (token) {
  //     const decoded = jwtDecode(token);
  //     console.log("DESoocede", decoded);
  //     setDecode(decoded);
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  // console.log('DOCCC', decode)
  const handleRoles = () => {
    setRoleOpen(true);
  };
  const handleRoleModel=()=>{
    setRoleOpen(false);
  }

  const handlelogout = () => {
    console.log("logout");
    navigate("/login");
    sessionStorage.removeItem("orgtoken");
    localStorage.removeItem("interactionIdFirst");
    // setToken(null);
  };
  return (
    <>
      <SideTopbarContainer>
        <div className="logo-container">
          <Image
            src={logo}
            title="Medscribe"
            alt="medscribe"
            className="denotes-logo"
          />
          {showUser  ? (
            <TopbarRight>
         <div className="text-white mr-2 hidden sm:block">
  {doctorDetails ? (
    <>
      {doctorDetails.salutation} {doctorDetails.first_name} {doctorDetails.last_name}
    </>
  ) : (
    <>
      {decode?.salutation || ""} {decode?.first_name || ""} {decode?.last_name || ""}
    </>
  )}
</div>

              <UserProfile className="cursor-pointer">
                {/* <Dropdown>
                  <Dropdown.Toggle  id="dropdown-basic" size="sm">
                    <Image
                      className="hp-img-right"
                      src={usericons}
                      // roundedCircle
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h5>{persondata.name}</h5>
                      <p>{persondata.email}</p>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="se-link" as={Link} to={``}>
                      <FaRegUser size={14} />
                      {"Manage Account"}
                    </Dropdown.Item>
                    <Dropdown.Item className="se-link" onClick={handlelogout}>
                      <MdLogout size={16} /> {"Logout"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Tooltip
                  title={
                    <TopMenu
                      persondata={decode}
                      handlelogout={handlelogout}
                      handleRole={handleRoles}
                      overlayStyle={{ backgroundColor: "#2db7f5" }}
                    />
                  }
                >
                  <Image
                    className="hp-img-right"
                    src={usericons}
                    width={40}
                    // roundedCircle
                  />
                </Tooltip>
                {roleopen ? (
                  <LoginButtonModel
                    show={roleopen}
                    onClose={handleRoleModel}
                  />
                ) : (
                  ""
                )}
              </UserProfile>
            </TopbarRight>
          ) : (
            <div></div>
          )}
        </div>
      </SideTopbarContainer>
    </>
  );
}

const TopMenu = ({ persondata, handlelogout, handleRole }) => {
  return (
    <div className="p-2">
      {/* <h5>{persondata.name}</h5> */}
      <p>{persondata?.email}</p>

      {/* <div className="border-t border-black"></div> */}
      <div
        className="flex items-center gap-2 mt-2 cursor-pointer"
        onClick={handleRole}
      >
        <FaUserFriends size={16} />
        <div>Role change</div>
      </div>
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={handlelogout}
      >
        <MdLogout size={16} />
        <div>Logout</div>
      </div>
    </div>
  );
};
