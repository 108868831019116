import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import { FaSearch } from "react-icons/fa";
import { Switch, Modal, message } from "antd";
import "./frontoffice.css";
import { configJson } from "../../Constent/config";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers";
import { useReactToPrint } from "react-to-print";
import { Alert, Button, Spinner } from "react-bootstrap";
import { FaX } from "react-icons/fa6";
import { DateTime } from "luxon";
import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import { RiEdit2Fill } from "react-icons/ri";
import SpecialityDropdownForm from "./Components/SpecialityDropdown";
import { IoClose } from "react-icons/io5";
import { Mybtn } from "../Engine-Page/mainEngine/MainEngineStyle";

const FrontActivity = () => {
  const [content, setContent] = useState("allPatients");
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isRes, setIsRes] = useState(false);
  const [users, setUsers] = useState([]);
  const [interactionInfo, setInteractionInfo] = useState({
    patientId: "",
    doctorId: "",
    interactionId: "",
  });

  const [patientInfo, setPatientInfo] = useState([]);
  const [apiDate, setApiDate] = useState();
  const [orgId, setOrgId] = useState();
  const [history, setHistory] = useState([]);
  const [decode, setDecode] = useState({});
  const [startDateget, setStartDateget] = useState("");
  const [orgspecialties, setOrgSpecialties] = useState([]);
  // const [endDateget, setEndDateget] = useState("");
const [timeZonedata,setTimeZonedata]=useState(null);
const [zone, setZone] = useState(null);
  const navigate = useNavigate();

  const fetchPatients = async (organization_id, startDate, endDate,timezone) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/byorg/${organization_id}/${startDate}/${endDate}/${timezone}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`, // Include the token for authorization
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch patients");

      const data = await response.json();
      console.log(data);
      setPatients((prevPatients) => [...prevPatients, ...data.interaction]);
      //   setPatients(data.interaction);
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchPatient(e.target.value);
  };

  const filteredByDate = (list) => {
    if (!startDate && !endDate) return list;

    const start = new Date(startDate);
    const end = new Date(endDate);

    return list.filter((data) => {
      const updatedAt = new Date(data.created_at);
      return (
        (!startDate || updatedAt >= start) && (!endDate || updatedAt <= end)
      );
    });
  };

  const filteredPatients = users?.filter(
    (patient) =>
      patient?.Patient?.first_name
        ?.toLowerCase()
        .includes(searchPatient.toLowerCase()) ||
      patient?.Patient?.last_name
        ?.toLowerCase()
        .includes(searchPatient.toLowerCase()) ||
      patient?.Patient?.hospital_patient_id
        ?.toLowerCase()
        .includes(searchPatient.toLowerCase())
  );

  const appointments = filteredByDate(filteredPatients).sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const patientList = patients?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // const appointments = users?.sort((a, b) => {
  //     return new Date(b.created_at) - new Date(a.created_at)})

  const fetchTokenUsers = async (formattedDate, organization_id,timezone) => {
    // console.log(organization_id)
    setLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/appointments/${formattedDate}/${organization_id}/${timezone}`
      ); // Replace with the correct API route
      if (!response.ok) {
        throw new Error("Failed to fetch token users");
      }
      const data = await response.json();
      setUsers(data.interaction);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSingleInteraction = async (doctorId, interactionId) => {
    // console.log(doctorId, interactionId)
    try {
      const res = await axios.get(
        `${configJson.backend_URL}/interaction/inter/${doctorId}/${interactionId}`
      );
      // console.log(res)
      setPatientInfo(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (interactionInfo.doctorId !== "") {
      getSingleInteraction(
        interactionInfo?.doctorId,
        interactionInfo?.interactionId
      );
    }
  }, [interactionInfo]);

  // useEffect(() => {
  //     const token1 = sessionStorage.getItem("orgtoken");
  //     if (token1) {
  //         const decoded = jwtDecode(token1);
  //         const formattedDates = DateTime.now().toUTC().toFormat('yyyy-MM-dd');
  //         setApiDate(formattedDates)
  //         if (decoded.organization_id) {
  //             setOrgId(decoded.organization_id)
  //             fetchTokenUsers(formattedDates, decoded.organization_id);
  //             fetchPatients(decoded.organization_id)
  //         }
  //     } else {
  //       navigate("/login"); // Navigate to login if token is not present
  //     }
  // }, [navigate]);

  const toggleStatus = async (
    interactionId,
    interactionStatus,
    doctorId,
    status
  ) => {
    try {
      const res = await axios.put(
        `${configJson.backend_URL}/interaction/status/${interactionId}/${interactionStatus}/${doctorId}/${status}`
      );
      if (res.statusText === "OK") {
        message.success(res?.data?.message);
      }
    } catch (err) {
      // console.log(err)
      message.error(err?.response?.data?.message);
    }
  };

  const getVisitHistory = async (id, startDate, endDate,timezone) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${configJson.backend_URL}/interactionDetail/details/notes/${id}/${startDate}/${endDate}/${timezone}`
      );
      setHistory(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //specialty setup Edit fetch
  const fetchOrgSpecialties = async (organization_id) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        //  `${configJson.backend_URL}/specialtysetup?organization_id=${organization_id}`,
        `${configJson.backend_URL}/specialtysetup/org/${organization_id}`,

        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (!response.ok) throw new Error(`Failed to fetch data`);
      const data = await response.json();
      setOrgSpecialties(data);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    const Tzone = sessionStorage.getItem("timeZone");
    
    if (Tzone) {
      setTimeZonedata(Tzone);
      const timezone = Tzone.replace("/", "_");
        setZone(timezone);
      const utcDate = DateTime.now().setZone(Tzone);

      // Format the current UTC date to just the date (YYYY-MM-DD)
      const formattedUTCDate = utcDate.toFormat("yyyy-MM-dd");

      // Create a variable for the date 5 days ago
      const last5Days = utcDate.minus({ days: 5 });
      const formattedLast5Days = last5Days.toFormat("yyyy-MM-dd");

      if (token) {
        const decoded = jwtDecode(token);
        setDecode(decoded);
        setStartDateget(formattedLast5Days);
        // setEndDateget(formattedUTCDate);
        if (decoded.organization_id) {
          setOrgId(decoded.organization_id);
          fetchTokenUsers(formattedUTCDate, decoded.organization_id,timezone);
          fetchPatients(
            decoded.organization_id,
            formattedLast5Days,
            formattedUTCDate,timezone
          );
          fetchOrgSpecialties(decoded.organization_id);
          PrintContent(decoded.organization_id);
        }
        //   console.log(decoded)
        console.log(
          "Fetching visit history for",
          formattedLast5Days,
          "to",
          formattedUTCDate
        );

        getVisitHistory(
          decoded.organization_id,
          formattedLast5Days,
          formattedUTCDate,
          timezone
        );
      } else {
        navigate("/login");
      }
    }
  }, [navigate]);

  //next 5 days data
  const handleNext = () => {
    // Parse the start date as a UTC DateTime
    const utcStartDate = DateTime.fromISO(startDateget, { zone:timeZonedata });

    // Format the start date as YYYY-MM-DD
    const formattedUTCDate = utcStartDate.toFormat("yyyy-MM-dd");

    // Subtract 5 days directly from the DateTime object
    const last5DaysDateTime = utcStartDate.minus({ days: 5 });

    // Format the last 5 days date as YYYY-MM-DD
    const formattedLast5Days = last5DaysDateTime.toFormat("yyyy-MM-dd");

    setStartDateget(formattedLast5Days);
    // Fetch new interactions
    // fetchInteractions(8, formattedLast5Days, startDate);
    fetchPatients(decode.organization_id, formattedLast5Days, formattedUTCDate,zone);
  };

  return (
    <>
      {/* <Sidebar setuser={true}> */}
      <div className="flex items-center justify-center w-full m-auto sticky top-0 z-50 bg-white">
        <div
          className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
            content === "allPatients" ? "!border-[#ACACAC]" : ""
          }`}
          style={{ borderColor: "transparent" }}
          onClick={() => setContent("allPatients")}
        >
          <img src={`/patient.png`} className="m-auto" width={32} />
        </div>
        <div
          className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
            content === "patient" ? "!border-[#ACACAC]" : ""
          }`}
          style={{ borderColor: "transparent" }}
          onClick={() => setContent("patient")}
        >
          <img src={`/follow-up.png`} className="m-auto" width={32} />
        </div>
      </div>
      {content === "allPatients" && (
        <div className="flex items-center justify-end sticky top-[34px] z-40 py-4 px-2 sm:px-10 bg-white">
          <div className="flex flex-col sm:flex-row items-end sm:items-center gap-2">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 text-xs p-2 border-[1px] border-[#C0C0C0] w-fit rounded-lg h-[32px]">
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="outline-none cursor-pointer bg-transparent"
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="outline-none cursor-pointer bg-transparent"
                />
                <img src={`/calendar.png`} alt="" width={12} />
              </div>
            </div>
            <div className="flex items-center p-2 h-[32px] border-[1px] border-[#C0C0C0] rounded-lg text-sm placeholder:text-sm">
              <input
                className="ml-2 bg-transparent outline-none"
                type="text"
                placeholder="Search by Name, Id.."
                onChange={handleSearch}
                value={searchPatient}
              />
              <FaSearch />
            </div>
          </div>
        </div>
      )}
      {content === "allPatients" && (
        <div className="text-textColor flex flex-col gap-4 px-2 sm:px-10 ">
          {startDate || endDate || searchPatient ? (
            <div className="flex flex-col gap-4 mb-5">
              {loading ? (
                <Spinner className="m-auto" />
              ) : (
                <>
                  {appointments?.map((data) => {
                    // console.log(data)
                    return (
                      <PatientCard
                        data={data}
                        setInteractionInfo={setInteractionInfo}
                        toggleStatus={toggleStatus}
                        fetchTokenUsers={fetchTokenUsers}
                        apiDate={apiDate}
                        orgId={orgId}
                        setUsers={setUsers}
                      />
                    );
                  })}
                </>
              )}
            </div>
          ) : (
            <div className="mb-5">
              <div className="border-b border-[#ACACAC] mb-4 font-semibold text-lg">
                Today's appointments
              </div>
              <div className="flex flex-col gap-4">
                {loading ? (
                  <Spinner className="m-auto" />
                ) : (
                  <>
                    {appointments.length > 0 ? (
                      <>
                        {appointments?.map((data, index) => {
                          // console.log(data)
                          return (
                            <PatientCard
                              data={data}
                              setInteractionInfo={setInteractionInfo}
                              toggleStatus={toggleStatus}
                              fetchTokenUsers={fetchTokenUsers}
                              apiDate={apiDate}
                              orgId={orgId}
                              setUsers={setUsers}
                              index={index}
                              orgspecialties={orgspecialties}
                              users={users}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <div className="text-center mt-2">
                        No appointments today..
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="border-b border-[#ACACAC] my-4 font-semibold text-lg">
                Past visit's
              </div>
              {console.log(patientList)}
              <div className="flex flex-col gap-4">
                {patientList?.map((data, index) => {
                  // console.log('DDD', data)
                  return (
                    <div
                      key={index}
                      className="bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <div className="rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]">
                            <img src={`/user.png`} width={15} />
                          </div>
                          <div>
                            <div className="text-base font-bold">
                              {data?.Patient.first_name}{" "}
                              {data?.Patient.last_name}
                            </div>
                            <div className="text-sm flex items-center gap-1">
                              <span className="font-bold">Follow-Up</span>
                              <span>{formatDate(data.created_at)}</span>
                            </div>
                          </div>
                        </div>
                        {/* <Switch 
                                checkedChildren="In" 
                                unCheckedChildren="Out" 
                                onClick={() => setInteractionInfo({
                                    patientId: data.patient_id,
                                    doctorId: data.doctor_id,
                                    interactionId: data.interaction_id,
                                })}
                                /> */}
                      </div>
                      <div className="border-[#9C9C9C] border-[1px] rounded-2xl px-6 py-2 bg-white">
                        <div className="font-semibold">
                          {new Date(data.created_at).toDateString() !==
                          new Date().toDateString()
                            ? "Existing patient:"
                            : "New patient:"}
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">Name:</span>
                          <span>
                            {data?.Patient.first_name} {data?.Patient.last_name}
                          </span>
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">DOB:</span>
                          <span>{data?.Patient.date_of_birth}</span>
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">ID:</span>
                          <span>{data?.Patient.hospital_patient_id}</span>
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">Phone No.:</span>
                          <span>{data?.Patient?.phone_number}</span>
                        </div>
                        {data?.Doctor ? (
                          <div className=" flex items-center gap-1">
                            <span className="font-semibold">Doctor:</span>
                            <span>
                              {data?.Doctor?.first_name}{" "}
                              {data?.Doctor?.last_name}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
                <Button
                  type="button"
                  className="theme-button"
                  onClick={handleNext}
                >
                  more
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {content === "patient" && (
        <div className="px-2 sm:px-10 py-4 flex flex-col gap-4">
          {isLoading ? (
            <Spinner animation="border" className="m-auto" />
          ) : (
            <>
              {history
                ?.filter((data) => {
                  const createdAtDate = new Date(data.created_at);
                  const today = new Date();
                  return (
                    createdAtDate.getDate() === today.getDate() &&
                    createdAtDate.getMonth() === today.getMonth() &&
                    createdAtDate.getFullYear() === today.getFullYear()
                  );
                })
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((data) => {
                  return (
                    <div className="bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg text-textColor w-full">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <div className="rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]">
                            <img src={`/user.png`} width={15} />
                          </div>
                          <div>
                            <div className="text-base font-bold">
                              {data?.Patient?.first_name}{" "}
                              {data?.Patient?.last_name}
                            </div>
                            <div className="text-sm flex items-center gap-1">
                              <span className="font-bold">Follow-Up</span>
                              <span>{formatDate(data?.created_at)}</span>
                            </div>
                          </div>
                        </div>
                        {/* <Switch checkedChildren="In" unCheckedChildren="Out" /> */}
                      </div>
                      <div className="border-[#9C9C9C] border-[1px] rounded-2xl px-6 py-2 bg-white">
                        {/* <div className='font-semibold'>Existing patient:</div> */}
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">Name:</span>
                          <span>
                            {data?.Patient?.first_name}{" "}
                            {data?.Patient?.last_name}
                          </span>
                        </div>
                        {data?.Patient?.date_of_birth ? (
                          <div className=" flex items-center gap-1">
                            <span className="font-semibold">DOB:</span>
                            <span>{data?.Patient?.date_of_birth}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">ID:</span>
                          <span>{data?.patient_id}</span>
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">Phone No.:</span>
                          <span>{data?.Patient?.phone_number}</span>
                        </div>
                        <div className=" flex items-center gap-1">
                          <span className="font-semibold">Doctor:</span>
                          <span>
                            {data?.Doctor?.first_name} {data?.Doctor?.last_name}
                          </span>
                        </div>
                        {data?.InteractionDetails?.map((detail) => {
                          if (detail?.interaction_detail_type == "soap-notes") {
                            return (
                              <>
                                <div className="font-bold my-2">
                                  {detail.processedFileContent.match(
                                    /\*\*(Treatment )?Plan:\*\* (.*?)(?=\n|$)/g
                                  )}
                                </div>
                                {/* <div className='font-bold my-2'>Doctor suggest to take Blood test, X-ray, and visit DR Raja for 2nd opinion</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div> */}
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                      <button
                        className="px-4 py-1 text-white w-fit rounded-[25px] m-auto opacity-60"
                        style={{
                          background:
                            "linear-gradient(to right, #1C1C1C, #4D4D4D)",
                        }}
                        onClick={() => setIsModal(true)}
                        disabled
                      >
                        Prescription
                      </button>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      )}
      <PrescModal isModal={isModal} setIsModal={setIsModal} />
      <div className="sticky w-full bottom-0 p-4 pb-0 bg-white flex flex-col gap-2">
        {/* <ChatbotQuery /> */}
      </div>
      {/* </Sidebar> */}
    </>
  );
};

export default FrontActivity;

const PatientCard = ({
  data,
  setInteractionInfo,
  toggleStatus,
  fetchTokenUsers,
  apiDate,
  orgId,
  setUsers,
  index,
  orgspecialties,
  users,
}) => {
  const [showSpecialityModel, setShowSpecialityModel] = useState(false);

  //Speciality Model Close
  const handleSpecialityClose = () => {
    setShowSpecialityModel(false);
  };
  const handleToggle = async () => {
    // console.log(data)
    const status = data.interaction_type == "true" ? "false" : "true";
    try {
      await toggleStatus(
        data.interaction_id,
        data.interaction_status,
        data.doctor_id,
        status
      );
      setUsers([]);
      fetchTokenUsers(apiDate, orgId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      // className="bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg"
      key={index}
      className={
        data?.interaction_status !== "99"
          ? "bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg opacity-80"
          : "bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg"
      }
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]">
            <img src={`/user.png`} width={15} />
          </div>
          <div>
            <div className="flex text-base font-bold ">
              <span className="pr-4">
                {" "}
                {data.Patient.first_name} {data.Patient.last_name}
              </span>
              {data?.token_number !== null ? (
                <div class="w-6 h-6 rounded-full p-2 bg-[#4388cf] flex justify-center items-center text-center">
                  <span>{data?.token_number}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-sm flex items-center gap-1">
              <span className="font-bold">Follow-Up</span>
              <span>{formatDate(data.created_at)}</span>
            </div>
          </div>
        </div>
        <Switch
          checked={data.interaction_type == "true" ? true : false}
          checkedChildren="In"
          unCheckedChildren="Out"
          // onChange={(checked) => handleToggle(checked, data.interaction_id)}
        />
      </div>
      <div className="border-[#9C9C9C] border-[1px] rounded-2xl px-6 py-2 bg-white">
        {/* <div className="font-semibold">
          {new Date(data.created_at).toDateString() !==
          new Date().toDateString()
            ? "Existing patient"
            : "New patient"}
        </div> */}
        <div className=" flex items-center gap-1">
          <span className="font-semibold">Name:</span>
          <span>
            {data.Patient.first_name} {data.Patient.last_name}
          </span>
        </div>
        {data?.Patient?.date_of_birth ? (
          <div className=" flex items-center gap-1">
            <span className="font-semibold">DOB:</span>
            <span>{data.Patient.date_of_birth}</span>
          </div>
        ) : (
          ""
        )}
        <div className=" flex items-center gap-1">
          <span className="font-semibold">ID:</span>
          <span>{data.Patient.hospital_patient_id}</span>
        </div>
        <div className=" flex items-center gap-1">
          <span className="font-semibold">Phone No.:</span>
          <span>{data.Patient.phone_number}</span>
        </div>
        {data?.speciality ? (
          <div className=" flex items-center gap-1">
            {showSpecialityModel ? (
              <>
                {console.log("orgspecialties", orgspecialties)}
                {orgspecialties?.length > 0 ? (
                  <SpecialityDropdownForm
                    specialities={orgspecialties}
                    selectedspcialite={data?.speciality?.speciality_id}
                    id={data.interaction_id}
                    users={users}
                    setUsers={setUsers}
                    handleSpecialityClose={handleSpecialityClose}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <span className="font-semibold">Speciality:</span>
                <span>{data?.speciality?.speciality_name}</span>
              </>
            )}
            {showSpecialityModel ? (
              <Mybtn>
                <Button
                  className="new-style-btn"
                  type="button"
                  onClick={() => setShowSpecialityModel(false)}
                >
                  <IoClose />
                </Button>
              </Mybtn>
            ) : (
              <>
                {data?.interaction_status === "99" ? (
                  <Mybtn>
                    <Button
                      className="new-style-btn"
                      type="button"
                      onClick={() => setShowSpecialityModel(true)}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </Mybtn>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        ) : (
          ""
        )}
        {data?.Doctor ? (
          <div className=" flex items-center gap-1">
            <span className="font-semibold">Doctor:</span>
            <span>
              {data.Doctor.first_name} {data.Doctor.last_name}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const PrescModal = ({ isModal, setIsModal }) => {
  const contentRef = useRef();

  const handlePrint = () => {
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";
    document.body.appendChild(printFrame);

    const content = contentRef.current.innerHTML;
    const printDocument = printFrame.contentWindow.document;

    printDocument.open();
    printDocument.write(`
            <html>
                <head>
                    <title>Print</title>
                    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
                    <style>
                        body { font-family: Arial, sans-serif; }
                    </style>
                </head>
                <body onload="window.print(); window.close();">
                    ${content}
                </body>
            </html>
        `);
    printDocument.close();
  };

  return (
    <Modal
      open={isModal}
      onClose={() => setIsModal(false)}
      onCancel={() => setIsModal(false)}
      title=" "
      footer={false}
    >
      <div ref={contentRef}>
        <PrintContent />
      </div>
      <div className="flex items-center justify-end mt-2">
        <button
          className="text-white px-4 py-1 rounded-2xl"
          style={{ background: "linear-gradient(to right, #1C1C1C, #4D4D4D)" }}
          onClick={handlePrint}
        >
          Print
        </button>
      </div>
    </Modal>
  );
};

const PrintContent = (organization_id) => {
  console.log("orgprintid", organization_id);
  return (
    <div className="bg-[#FAFAFA] p-2 rounded w-full">
      {organization_id === 8 && (
        <div className="flex items-center justify-between">
          <div>
            <img src={`/gunam.png`} alt="Gunam" width={200} />
          </div>
          <div className="text-right">
            <div className="font-bold">Gunam Super Speciality Hospital</div>
            <div className="text-sm">Health Care You Deserve</div>
            <div className="font-bold">Dr. John</div>
            <div className="text-sm">MDS, MBBS</div>
          </div>
        </div>
      )}
      <div className="border-b border-[#696969] my-4"></div>

      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-1">
          <div>Patient Name:</div>
          <div>Christy</div>
        </div>
        <div className="flex items-center gap-1">
          <div>Age:</div>
          <div>36</div>
        </div>
        <div className="flex items-center gap-1">
          <div>Age:</div>
          <div>36</div>
        </div>
        <div className="flex items-center gap-1">
          <div>Date:</div>
          <div>24-10-2024</div>
        </div>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th>Medication</th>
            <th>Dosage &#40;M-A-N&#41;</th>
            <th>Period</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Paracetomal 100</td>
            <td>1-0-1</td>
            <td>5 Days</td>
            <td>9am & 9pm AF</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-20 text-right">Seal & Signature</div>
    </div>
  );
};
