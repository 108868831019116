import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { configJson } from "../../../Constent/config";
import { jwtDecode } from "jwt-decode";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import { convertToKolkataTime, convertToUTC } from "../../../helpers";
import axios from "axios";
import { toast } from "react-toastify";

const OrganizationDetails = () => {
  const [organizationId, setOrganizationId] = useState("");
  const [organization, setOrganization] = useState(null);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [formFieldDrop, setFormFieldDrop] = useState([]);
  const [date, setDate] = useState(null);
  const [file, setFile] = useState("");
  const [imgurl, setImgurl] = useState(null);
  const [remove, setRemove] = useState("");
  const timeZones = [
    "Pacific/Samoa",
    "US/Samoa",
    "Pacific/Honolulu",
    "America/Anchorage",
    "America/Los_Angeles",
    "America/Denver",
    "America/Phoenix",
    "America/Chicago",
    "America/New_York",
    "America/Caracas",
    "America/Puerto_Rico",
    "Canada/Newfoundland",
    "America/Buenos_Aires",
    "Brazil/DeNoronha",
    "Atlantic/Azores",
    "Europe/Dublin",
    "Europe/London",
    "Europe/Warsaw",
    "Africa/Johannesburg",
    "Europe/Berlin",
    "Europe/Helsinki",
    "Africa/Nairobi",
    "Asia/Tehran",
    "Europe/Moscow",
    "Asia/Kabul",
    "Asia/Tashkent",
    "Asia/Calcutta",
    "Asia/Almaty",
    "Asia/Jakarta",
    "Asia/Hong_Kong",
    "Asia/Tokyo",
    "Asia/Kolkata",
    "US/Pacific",
    "Australia/Adelaide",
    "Australia/Sydney",
    "Asia/Vladivostok",
    "Pacific/Auckland",
    "Pacific/Fiji",
  ];

  useEffect(() => {
    setFormFieldDrop([
      {
        label: "time_zone",
        name: "time_zone",
        type: "select",
        required: true,
        options: timeZones,
      },
      // Other fields if needed
    ]);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // Fetch Organization Details
  const fetchOrganization = async (organizationId) => {
    try {
      setLoading(true);
      setMessage({ type: "", text: "" });
      const response = await fetch(
        `${configJson.backend_URL}/superadmin/oneorg/${organizationId}`
      );
      if (!response.ok) throw new Error("Organization not found.");
      const data = await response.json();
      console.log("data", data.data);
      setOrganization(data.data);

      // Set default values in the form
      Object.keys(data.data).forEach((key) => {
        setValue(key, data.data[key]);
        if (key === "url") {
          setLogo(data.data[key]);
          setRemove(data.data[key]);
        } else if (key === "time_zone") {
          setValue("time_zone", data.data[key]);
        } else if (key === "logo_url") {
          setImgurl(data.data[key]);
        }
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage({ type: "danger", text: error.message });
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      console.log(23, decoded);
      fetchOrganization(decoded.organization_id);
      setOrganizationId(decoded.organization_id);
      const formattedDate = convertToUTC();
      const d = convertToKolkataTime(formattedDate);
      console.log(d);
      setDate(d);
    }
  }, []);

  // Update Organization
  const updateOrganization = async (formData) => {
    // try {
    //   setLoading(true);
    //   setMessage({ type: "", text: "" });
    //   const response = await fetch(
    //     `${configJson.backend_URL}/superadmin/update/${organizationId}`,
    //     {
    //       method: "PUT",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify(formData),
    //     }
    //   );
    //   if (!response.ok) throw new Error("Failed to update organization.");
    //   const data = await response.json();
    //   setOrganization(data.organization);
    //   setMessage({
    //     type: "success",
    //     text: "Organization updated successfully.",
    //   });
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    //   setMessage({ type: "danger", text: error.message });
    // }
    if (!file) {
      updateOrganizations(formData, imgurl);
    } else {
      let result = date.replace(/[-:]/g, "_").replace(", ", "_");
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", `logs/${organizationId}_${result}.png`);
      try {
        // Make the request to the server
        const response = await axios.post(
          `${configJson.backend_URL}/superadmin/logo/img`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          const url = response.data.imgUrl;
          updateOrganizations(formData, url);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error uploading interaction:", error);
      }
    }
  };
  // Update Organization
  const updateOrganizations = async (formData, imgurl) => {
    try {
      setLoading(true);
      setMessage({ type: "", text: "" });
      const dataform = {
        ...formData,
        logo_url: imgurl,
      };
      console.log(dataform);
      const response = await fetch(
        `${configJson.backend_URL}/superadmin/update/${organizationId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataform),
        }
      );
      if (!response.ok) throw new Error("Failed to update organization.");
      const data = await response.json();
      setOrganization(data.organization);
      setMessage({
        type: "success",
        text: "Organization updated successfully.",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage({ type: "danger", text: error.message });
    }
  };

  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (fileExtension === "png" || fileExtension === "jpg") {
        setFile(file);
        console.log(fileExtension);
        setLogo(URL.createObjectURL(file)); // Create a preview URL for the uploaded file
      } else {
        toast.error("select only PNG or JPG ");
      }
    }
  };
  console.log("fddsf", logo, file);

  const handleImageRemove = () => {
    setFile(null);
    setLogo(remove);
  };
  const updateOrganizationAddress = (formData) => {
    console.log(formData);
  };
  return (
    <>
      {/* <Sidebar setuser={true}> */}
      <Container className="mt-5">
        <h3>Company Settings</h3>
        <p>Configure, customize & manage your company settings</p>
        <Form onSubmit={handleSubmit(updateOrganization)}>
          <Card className="p-4 shadow-sm">
            <Row>
              {/* Left Column: Company Data */}

              <Col md={6}>
                <h5>Company Data</h5>
                <hr />

                {/* Company Logo */}
                <div className="mb-3 text-center">
                  <img
                    src={logo}
                    alt="Company Logo"
                    className="border"
                    style={{
                      width: "350px",
                      height: "180px",
                      objectFit: "cover",
                    }}
                  />
                  <p className="text-muted mt-2">
                    The proposed size is 350px × 180px. No bigger than 2.5 MB
                  </p>
                  {/* <Button variant="primary" className="me-2" type="file" id="logo"  onClick={()=>handleImage()}>
                  Upload 
                </Button> */}
                  <Button type="button">
                    <input
                      type="file"
                      id="logo"
                      onChange={handleImage}
                      style={{ display: "none" }} // Hide the actual input
                    />
                    <label htmlFor="logo" className="btn btn-primary p-0">
                      Upload
                    </label>
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => handleImageRemove()}
                  >
                    Remove
                  </Button>
                </div>

                {/* Company Info */}
                {organization && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("name", {
                          required: "Name is required.",
                        })}
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {errors.name.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        {...register("type", {
                          required: "Type is required.",
                        })}
                      >
                        <option value="Hospital">Hospital</option>
                        <option value="General Practitioner">
                          General Practitioner
                        </option>
                      </Form.Select>
                      {errors.type && (
                        <small className="text-danger">
                          {errors.type.message}
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        {...register("email", {
                          required: "Email is required.",
                          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        })}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control type="text" {...register("phone_number")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Website</Form.Label>
                      <Form.Control type="text" {...register("website")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("description")}
                      />
                    </Form.Group>
                    {formFieldDrop.map((field, index) => (
                      <Form.Group className="mb-3" key={index}>
                        <Form.Label>{field.label}</Form.Label>

                        {field.type === "select" ? (
                          <Form.Control
                            as="select"
                            {...register(field.name, {
                              required: field.required
                                ? `${field.label} is required`
                                : false,
                            })}
                            isInvalid={!!errors[field.name]}
                          >
                            <option value="">Select an option</option>
                            {field.options.map((option, i) => (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            type={field.type}
                            {...register(field.name, {
                              required: field.required
                                ? `${field.label} is required`
                                : false,
                            })}
                            isInvalid={!!errors[field.name]}
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors[field.name]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ))}
                  </>
                )}
              </Col>

              {/* Right Column: Company Address */}
              <Col md={6}>
                <h5>Company Address</h5>
                <hr />
                {organization && (
                  <>
                    {/* Additional Fields */}
                    <Form.Group className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("state", {
                          required: "State is required.",
                        })}
                      />
                      {errors.state && (
                        <small className="text-danger">
                          {errors.state.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("city", {
                          required: "City is required.",
                        })}
                      />
                      {errors.city && (
                        <small className="text-danger">
                          {errors.city.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        {...register("country", {
                          required: "Country is required.",
                        })}
                        isInvalid={!!errors.country}
                      >
                        <option value="">Select Country</option>
                        <option value="India">India</option>
                        <option value="United States of America">
                          United States of America
                        </option>
                      </Form.Select>
                      {errors.country && (
                        <small className="text-danger">
                          {errors.country.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Pin Code</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("pin_code", {
                          required: "Pin Code is required.",
                        })}
                      />
                      {errors.pin_code && (
                        <small className="text-danger">
                          {errors.pin_code.message}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Fax Number</Form.Label>
                      <Form.Control type="text" {...register("fax_no")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Tax ID Number</Form.Label>
                      <Form.Control type="text" {...register("tax_id_no")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("address1", {
                          required: "Address Line 1 is required.",
                        })}
                      />
                      {errors.address1 && (
                        <small className="text-danger">
                          {errors.address1.message}
                        </small>
                      )}
                    </Form.Group>
                    <Button variant="success" type="submit" disabled={loading}>
                      {loading ? "Updating..." : "Update Organization"}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Form>
      </Container>
      {/* <div className="">
        <h1 className="my-4">Business Profile Settings</h1>
        <div>
          <h1
            style={{
              textTransform: "capitalize",
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            Business Logo
          </h1>
          <div>
            {logo ? (
              <img
                style={{ width: "100px", height: "100px" }}
                src={logo}
                className="img-fluid"
                alt="Business Logo"
              />
            ) : (
              <></>
            )}
            <br />
            <input onChange={handleImage} type="file" id="logo" />
          </div>
        </div>


        {message.text && <Alert variant={message.type}>{message.text}</Alert>}

        {organization && (
          <Form onSubmit={handleSubmit(updateOrganization)}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: "Name is required." })}
              />
              {errors.name && (
                <small className="text-danger">{errors.name.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                {...register("type", { required: "Type is required." })}
              >
                <option value="Hospital">Hospital</option>
                <option value="General Practitioner">
                  General Practitioner
                </option>
              </Form.Select>
              {errors.type && (
                <small className="text-danger">{errors.type.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                {...register("state", { required: "State is required." })}
              />
              {errors.state && (
                <small className="text-danger">{errors.state.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                {...register("city", { required: "City is required." })}
              />
              {errors.city && (
                <small className="text-danger">{errors.city.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                {...register("country", { required: "Country is required." })}
              />
              {errors.country && (
                <small className="text-danger">{errors.country.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pin Code</Form.Label>
              <Form.Control
                type="text"
                {...register("pin_code", { required: "Pin Code is required." })}
              />
              {errors.pin_code && (
                <small className="text-danger">{errors.pin_code.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
              />
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" {...register("phone_number")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Website</Form.Label>
              <Form.Control type="text" {...register("website")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Fax Number</Form.Label>
              <Form.Control type="text" {...register("fax_no")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tax ID Number</Form.Label>
              <Form.Control type="text" {...register("tax_id_no")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                {...register("address1", {
                  required: "Address Line 1 is required.",
                })}
              />
              {errors.address1 && (
                <small className="text-danger">{errors.address1.message}</small>
              )}
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register("description")}
              />
            </Form.Group>
            {formFieldDrop.map((field, index) => (
              <Form.Group className="mb-3" key={index}>
                <Form.Label>{field.label}</Form.Label>

                {field.type === "select" ? (
                  <Form.Control
                    as="select"
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                    })}
                    isInvalid={!!errors[field.name]}
                  >
                    <option value="">Select an option</option>
                    {field.options.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Form.Control
                    type={field.type}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                    })}
                    isInvalid={!!errors[field.name]}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            ))}

            <Button variant="success" type="submit" disabled={loading}>
              {loading ? "Updating..." : "Update Organization"}
            </Button>
          </Form>
        )}

      </div> */}{" "}
      {/* </Sidebar> */}
    </>
  );
};

export default OrganizationDetails;
