export const FormHeader = ({ formTitle }) => {
  return (
    <div className="flex items-center border border-black">
      <div className="flex items-center gap-2 w-1/2 border-r border-black p-2">
        <div>
          <img src={`/headlogotemplate.png`} alt="Gunam" width={90} />
        </div>
        <div className="text-left text-sm leading-4">
          <div className="font-bold">Gunam Super Speciality Hospital</div>
          <div className="">
            &#40;A unit of Hosur Super Speciality Hospitals Pvt.Ltd&#41;
          </div>
          <div className="">#35, Tank street,&#40;Opp.Govt.Hospital&#41;,</div>
          <div className="">Denkani kottai Road, Hosur-635109,</div>
          <div className="">Krishnagiri&#40;Dt&#41;, TAMIL NADU.</div>
          <div className="text-xs">
            Phone: 04344-220199, 220599, Mobile: 80980 55055
          </div>
        </div>
      </div>
      <div className="w-1/2 text-center font-semibold text-2xl uppercase">
        {formTitle}
      </div>
    </div>
  );
};

export const PatientData = () => {
  return (
    <div className="flex items-center border-black border-x border-b">
      <div className="w-[75%] border-r border-black text-sm py-3 pl-2">
        <div className="flex items-center gap-2 mb-2">
          <div className="flex gap-2 w-[60%]">
            <div>Patient Name:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2 w-[20%]">
            <div>Age:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2 w-[20%]">
            <div>Gender:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex gap-2 w-[50%]">
            <div>Patient MRN:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2 w-[20%]">
            <div>Bed No:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2 w-[30%]">
            <div>Department:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
        </div>
      </div>
      <div className="text-center w-[25%]">Affix Patient label here</div>
    </div>
  );
};
