import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
import { jwtDecode } from "jwt-decode"; // Fix the import here
import EditPatientModal from "./Components/EditPatientModal"; // Import the modal
import editicon from "../../assets/table/edit.png";
import Sidebar, {
  getDateTimeWithOffset,
} from "../Home/Components/Sidebar/Sidebar";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import {
  clearSearchResults,
  searchPatients,
} from "../../redux/slices/patientsSlice";
import { Select, Input, DatePicker } from "antd";

const PatientListedit = () => {
  const [patients, setPatients] = useState([]);
  // const [filteredPatients, setFilteredPatients] = useState([]); // State for filtered patients
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const navigate = useNavigate();
  const [decodedValue, setDecodedValue] = useState([]);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const { patientList, searchResults, loading } = useSelector(
    (state) => state.patients
  );
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [filterValue, setFilterValue] = useState("all");
  const [filteredPatients, setFilteredPatients] = useState(patients);
  // const [timeZonedata, setTimeZonedata] = useState(null);
  const [zone, setZone] = useState(null);

  const fetchPatientsByDate = async (date, organization_id, timezone) => {
    // console.log(organization_id)
    // setLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/byDate/${date}/${organization_id}/${timezone}`
      ); // Replace with the correct API route
      if (!response.ok) {
        throw new Error("Failed to fetch token users");
      }
      const data = await response.json();
      setPatients(data.interaction);
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect(() => {
  //   const token1 = sessionStorage.getItem("orgtoken");
  //   if (token1) {
  //     const decoded = jwtDecode(token1);
  //     setDecodedValue(decoded);
  //   } else {
  //     navigate("/login"); // Navigate to login if token is not present
  //   }
  // }, [navigate]);

  const handleEdit = (patient) => {
    setSelectedPatient(patient);
    setShowEditModal(true); // Open the modal for editing
  };

  const updatePatient = async (patientData) => {
    // console.log(patientData);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/updatepatientlist/${patientData.patient_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`,
          },
          body: JSON.stringify(patientData),
        }
      );

      if (!response.ok) throw new Error("Failed to update patient");

      // fetchTokenUsers(formattedUTCDate, decodedValue.organization_id);
      fetchPatientsByDate(selectedDate, decodedValue.organization_id, zone);
      if (query !== "") {
        dispatch(
          searchPatients({
            organizationId: decodedValue.organization_id,
            query: query,
          })
        );
      }

      // console.log(patients)

      // const updatedPatients = patients.map((p) =>
      //   p.patient_id === patientData.patient_id ? patientData : p.Patient
      // );
      // setPatients(updatedPatients);
      // setFilteredPatients(updatedPatients); // Update filtered patients as well
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };

  // Handle search input change
  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      dispatch(
        searchPatients({
          organizationId: decodedValue.organization_id,
          query: value,
        })
      );
    }
  };

  const handleSidePatients = (patientId, patientName) => {
    // Get the existing array of patients from localStorage, or an empty array if not present
    const existingPatients = JSON.parse(localStorage.getItem("patients")) || [];

    // Check if the patient already exists by ID
    const isPatientExists = existingPatients.some(
      (patient) => patient.id === patientId
    );

    if (!isPatientExists) {
      // Add the new patient object to the array
      existingPatients.push({ id: patientId, name: patientName });
      localStorage.setItem("patients", JSON.stringify(existingPatients));
    }
  };

  useEffect(() => {
    // console.log(selectedDate);
    const Tzone = sessionStorage.getItem("timeZone");
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      setDecodedValue(decoded);
      if (Tzone) {
        // setTimeZonedata(Tzone);
        const timezone = Tzone.replace("/", "_");
        setZone(timezone);
        const timesetDate = getDateTimeWithOffset(Tzone);
        const formattedDate = selectedDate.format("YYYY-MM-DD")
        if (decodedValue) {
          fetchPatientsByDate(formattedDate, decoded.organization_id, timezone);
        }
      }
    } else {
      navigate("/login"); // Navigate to login if token is not present
    }
  }, [selectedDate]);

  const rows = (patient, index) => (
    <tr
      key={index}
      className="hover:bg-gray-200"
      onClick={() => {
        // Prevent navigation if clicking the last <td>
        // if (e.target.closest(".no-navigate")) return;
        navigate(
          `/activity?id=${patient.Patient.patient_id}&doctor=${patient?.doctor_id}`,{
            state: {
              interaction_id: null,
            },
          }
         
        );
        handleSidePatients(
          patient?.Patient.patient_id,
          patient?.Patient.first_name
        );
      }}
      style={{ cursor: "pointer" }} // Add pointer cursor for better UX
    >
      <td>{index + 1}</td> {/* Display serial number */}
      <td>{patient.Patient?.hospital_patient_id}</td>
      <td>{patient.Patient?.first_name}</td>
      <td>{patient.Patient?.last_name}</td>
      <td>
        {patient.Patient?.phone_number ? patient.Patient?.phone_number : "-"}
      </td>
      <td>{patient?.Patient?.email ? patient?.Patient?.email : "-"}</td>
      <td className="no-navigate">
        <button
          className="edit-btn"
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event
            handleEdit(patient?.Patient);
          }}
        >
          <img
            style={{
              width: "18px",
              height: "18px",
              filter: "invert(0%) grayscale(100%) brightness(0%)", // Change color to black
            }}
            className="edit-btn-image"
            src={editicon}
            alt="Edit Patient Details"
            title="Edit Patient Details"
          />
        </button>
      </td>
    </tr>
  );

  useEffect(() => {
    if (!query) {
      dispatch(clearSearchResults());
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (filterValue === "99") {
      setFilteredPatients(
        patients.filter((patient) => patient.interaction_status === "99")
      );
    } else if (filterValue === "1") {
      setFilteredPatients(
        patients.filter((patient) => patient.interaction_status !== "99")
      );
    } else if (filterValue === "all") {
      setFilteredPatients(
        patients.filter(
          (patient) => patient.interaction_status === "1" || "0" || "99"
        )
      );
    }
  }, [filterValue, patients]);

  return (
    // <Sidebar setuser={true}>
    <div className="container mt-4">
      <h2>Patient List</h2>
      <p>Total Patients: {filteredPatients?.length}</p>

      {/* Display total patient count */}

      {/* Search Input */}
      <div className="flex items-center gap-2 mb-2">
        <Input
          type="text"
          className="w-full"
          placeholder="Search by Patient ID"
          value={query}
          onChange={handleSearch}
        />
        <DatePicker
          className="w-full"
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          disabledDate={(current) => current && current > today}
          format="DD-MM-YYYY"
          allowClear={false}
        />
        <Select
          placeholder="Select a filter"
          className="w-full"
          onChange={(e) => setFilterValue(e)}
          options={[
            {
              label: "All Tokens",
              value: "all",
            },
            {
              label: "Completed Interactions",
              value: "1",
            },
            {
              label: "Incompleted Interactions",
              value: "99",
            },
          ]}
          defaultValue="all"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Serial No</th> {/* Added Serial No header */}
            <th>Hospital Patient ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Edit</th>
          </tr>
        </thead>
        {query == "" ? (
          <tbody>
            {filteredPatients
              // .filter((patient) => patient.created_at.startsWith(today))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((patient, index) => {
                // console.log(patient, "LLL");
                return rows(patient, index);
              })}
          </tbody>
        ) : (
          <tbody>
            {searchResults.map((patient, index) => {
              // console.log(patient, "LLL");
              return rows(patient, index);
            })}
          </tbody>
        )}
      </table>
      {showEditModal && (
        <EditPatientModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          patientData={selectedPatient}
          updatePatient={updatePatient} // Pass the update function
        />
      )}
    </div>
    // </Sidebar>
  );
};

export default PatientListedit;
