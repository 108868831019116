import { FormHeader } from "./FormComponents";

const DischargeCheckList = () => {
  return (
    <div className="p-4 md:p-8">
      <FormHeader formTitle={"Check list - ip discharge file"} />
      <div className="text-sm">
        {/* <div className="grid grid-cols-3 gap-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Name:</span>
            <span className="border-b border-gray-400 w-32 md:w-60 text-sm">
              mr kantrin
            </span>
          </div>
          <div className="flex gap-2">
            <div>MRN No:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2">
            <div>IP/OP No:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex gap-2">
            <div>Age:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
          <div className="flex items-center gap-2">
            <div>sex:</div>
            <div className="flex">
              <input
                type="checkbox"
                id="male"
                name="male"
                className="mr-2 border-gray-300 rounded-sm border"
              />
              <label htmlFor="male" className="text-gray-700">
                Male
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-medium">Sex:</span>
              <div className="flex items-center space-x-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-gray-600"
                />
                <span className="text-sm">Male</span>
              </div>
              <div className="flex items-center space-x-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-gray-600"
                />
                <span className="text-sm">Female</span>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div>Ward:</div>
            <div className="border-b flex-1 border-black"></div>
          </div>
        </div> */}
      </div>

      <div className="mb-4 border-b border-gray-300 pb-2 flex flex-col md:flex-row justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Name:</span>
          <span className="border-b border-gray-400 w-32 md:w-60 text-sm">
            mr kantrin
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">IP/OP No:</span>
          <span className="border-b border-gray-400 w-32 md:w-40 text-sm">
            414732/661
          </span>
        </div>
      </div>

      <div className="mb-4 border-b border-gray-300 pb-2 flex flex-col md:flex-row justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Age:</span>
          <span className="border-b border-gray-400 w-16 md:w-20 text-sm">
            37
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">MRN No:</span>
          <span className="border-b border-gray-400 w-32 md:w-40 text-sm">
            414732
          </span>
        </div>
      </div>

      <div className="mb-4 flex flex-col md:flex-row justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Sex:</span>
          <div className="flex items-center space-x-1">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-gray-600"
            />
            <span className="text-sm">Male</span>
          </div>
          <div className="flex items-center space-x-1">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-gray-600"
            />
            <span className="text-sm">Female</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Ward:</span>
          <span className="border-b border-gray-400 w-24 md:w-32 text-sm">
            Gis
          </span>
        </div>
      </div>
      <div className="mb-4">
        <div className="font-bold text-center">
          Should be filled before sending the file to MRD
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b border-r border-gray-300 text-left">
                Sl.No.
              </th>
              <th className="py-2 px-4 border-b border-r border-gray-300 text-left">
                Admission Records Name
              </th>
              <th className="py-2 px-4 border-b border-gray-300 text-left">
                Total No. of Sheets
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">1</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Admission Records Name
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">2</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Doctors history Sheet
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">3</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Doctors orders, progress and consultation notes
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                04
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">4</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Drug chart
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                0
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">5</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                ICU flow chart
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                07
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">6</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Nurses Notes
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                07
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">7</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Lab Reports
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                0
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">8</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Vital signs graphic chart
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">9</td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                I/O Chart
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                02
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                10
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Diabetic Chart
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                0
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                11
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Pre operative check list
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                12
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Operative consent form
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                13
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Anaesthesia consent form
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                14
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Pre anesthetic consent form
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                15
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Operative notes
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                16
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                ECG
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                0
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                17
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                X-Ray / CT Scan
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                0
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                18
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Discharge summery (1 OP File, 1 IP File)
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                19
              </td>
              <td className="py-2 px-4 border-b border-r border-gray-300">
                Miscellaneous forms
              </td>
              <td className="py-2 px-4 border-b border-gray-300 text-center">
                01
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DischargeCheckList;
