import React, { useState, useEffect, useRef } from "react";
import { Form, Container, Row, Col, Button, Table } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./OutpatientDataSheet.css";
import { jwtDecode } from "jwt-decode";
import { configJson } from "../../../../Constent/config";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import html2canvas from "html2canvas";
import ConfirmationModal from "./ConfirmationModal";
import TableTemplateSelection from "./TableTemplateSelection";
import TemplateAfterUpdate from "./TemplateAfterUpdate";
import DataSheetHeader from "./DatasheetHeader";
import VitalsTable from "./VitalsTable";
import DataSheetFooter from "./DataSheetFooter";
import "./DataSheetFooter.css";
import { useSelector } from "react-redux";
const getFormattedDateTimeUTC = () => {
  const now = new Date();
  return now.toISOString().replace(/[-:.TZ]/g, "");
};

const OutpatientDataSheet = ({
  doctorId,
  registrationNumber,
  date,
  patientId,
  patientName,
  patientAddress,
  doctorName,
  appointmentNo,
  ageField,
  gender,
  marital,
  dob,
  maritalStatus,
  mobileNumber,
  fetchInteractions,
  answer,
  onAnswerChange,
  tokenNumber,
  audioUrlInteractionId,
  salutation,
}) => {
  const [editableAnswer, setEditableAnswer] = useState("");
  const [editableVitals, setEditableVitals] = useState([]);
  const [assessmentAnswer, setAssessmentAnswer] = useState("");
  const [saved, setSaved] = useState(false);
  const printRef = useRef();
  const [decode, setDecode] = useState({});
  const [medicineData, setMedicineData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [medicineList, setMedicineList] = useState([]); // State for medicines in selected template
  // const [editablePrescription, setEditablePrescription] = useState("");
  const navigate = useNavigate();
  const [comments, setComments] = useState("");
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [specialtyName, setSpecialtyName] = useState("");
  const [licenceNo, setLicenceNo] = useState("");
  const [showPopup, setShowPopup] = useState(false); // Popup visibility state
  const [showModal, setShowModal] = useState(false);
  const [showEditor, setShowEditor] = useState(true);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [showPrescription, setshowPrescription] = useState(true);
  const [isTableEdited, setIsTableEdited] = useState(false);
  const [isTableEditedTemplate, setIsTableEditedTemplate] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [prescriptionDataTemplate, setPrescriptionDataTemplate] = useState([
    // Initial template data
  ]);
  const [tempTemplateData, setTempTemplateData] = useState([]);
  const [prescriptionErrors, setPrescriptionErrors] = useState({});
  const [editableAssessment, setEditableAssessment] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [templateTests, setTemplateTests] = useState([]); // State for template tests
  const [assessmentTemplate, setAssessmentTemplate] = useState("");
  const [loading, setLoading] = useState(false);
  const decodes = useSelector((state) => state.auth.decodedToken);
  const handleInputChange = (index, field, value) => {
    // Update the specific field in the vitals data
    const updatedVitals = editableVitals.map((vital, idx) =>
      idx === index ? { ...vital, [field]: value } : vital
    );

    // Update the state with the changed vitals
    setEditableVitals(updatedVitals);

    // Log the updated values for debugging
    console.log("Updated Vitals:", updatedVitals);
  };

  const handleAssessmentSelect = (value) => {
    console.log("Selected value:", value); // Log the selected assessment ID
    if (value == "defaultVeraResponse") {
      // Handle Assessment Formatting
      const formattedAssessments = formatAssessments(answer, showEditor);
      setEditableAssessment(formattedAssessments);
    }
    setSelectedAssessment(value); // Set the selected assessment ID

    if (!value) {
      console.log("No assessment selected, resetting details");
      setEditableAssessment(""); // Reset editable content
      setAssessmentTemplate(""); // Reset the assessment template name
      setTemplateTests([]); // Reset the template tests
    } else {
      const selectedAssessmentData = assessmentList.find(
        (assessment) => String(assessment.assessment_id) === value
      );
      console.log("Selected assessment data:", selectedAssessmentData);

      if (selectedAssessmentData) {
        // Combine the assessment_template and template_tests for the editor with desired formatting
        const editableContent = `
            <strong>${selectedAssessmentData.assessment_template}</strong>
            <ul>
                ${
                  selectedAssessmentData.template_tests
                    ? selectedAssessmentData.template_tests
                        .split(",")
                        .map((test) => `<li>${test.trim()}</li>`)
                        .join("")
                    : ""
                }
            </ul>
        `;

        // Set the content to be editable
        setEditableAssessment(editableContent);

        // Set the assessment template
        setAssessmentTemplate(selectedAssessmentData.assessment_template);

        // Process and set template tests
        setTemplateTests(
          selectedAssessmentData.template_tests
            ? selectedAssessmentData.template_tests
                .split(",")
                .map((test) => test.trim())
            : []
        );
      }
    }
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = async () => {
    setShowModal(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string if dateString is invalid
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchDoctorAndSpecialty = async (doctorId) => {
    try {
      const token = sessionStorage.getItem("orgtoken");
      const doctorResponse = await fetch(
        `${configJson.backend_URL}/doctor/specific/${doctorId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (doctorResponse.ok) {
        const doctorInfo = await doctorResponse.json();
        setDoctorDetails(doctorInfo.doctor);
        console.log("doctinfo", doctorInfo.doctor);
        setLicenceNo(doctorInfo.doctor.license_no);

        const specialtyId = doctorInfo.doctor.speciality_id;

        // Fetch specialty details
        const specialtyResponse = await fetch(
          `${configJson.backend_URL}/specialties/${specialtyId}`
        );

        if (specialtyResponse.ok) {
          const specialtyData = await specialtyResponse.json();
          setSpecialtyName(
            specialtyData.specialty_name || "Specialty Not Found"
          );
        } else {
          setSpecialtyName("Specialty Not Found");
        }
      } else {
        console.error("Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor and specialty details:", error);
    }
  };

  useEffect(() => {
    // console.log(doctorId, "Docidget");
    if (doctorId) {
      fetchDoctorAndSpecialty(doctorId);
    }
  }, [doctorId]);

  const formattedDob = formatDate(dob);

  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      setDecode(decoded);
      fetchTemplates(decoded.organization_id); // Correct function name here
      fetchAssessments(decoded.organization_id);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const organization_id = decode.organization_id;
  const country = decode.country;

  const fetchTemplates = async (organization_id) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/medicationtemplates/organization/${organization_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch medication templates");
      }
      const data = await response.json();
      setMedicineData(data.data); // Assuming `data.data` contains the template array
    } catch (err) {
      console.error("Error fetching medication templates:", err.message);
    }
  };

  const fetchAssessments = async (organization_id) => {
    try {
      console.log("Fetching assessments for organization ID:", organization_id);

      const response = await fetch(
        `${configJson.backend_URL}/assessment/list/${organization_id}`
      );

      console.log("Fetch response status:", response.status);

      if (!response.ok) {
        throw new Error("Failed to fetch assessments");
      }

      const data = await response.json();
      console.log("Fetched assessments data:", data);

      setAssessmentList(data.assessments || []); // Ensure it's always an array
      console.log("Updated assessment list:", data.assessments || []);
    } catch (err) {
      console.error("Error fetching assessments:", err.message);
    }
  };

  useEffect(() => {
    if (answer) {
      // console.log("Updated ans", answer);
      console.log(editableVitals, "editableVitalsupdated");
      //setEditableAnswer(answer);

      setPrescriptionData(handleFormatPrescription(answer, showEditor));
      // console.log(prescriptionData, "prescripdata");

      // Validate and format answer for display
      const formattedAnswer = formatJsonAnswer(answer, showEditor);
      setEditableAnswer(formattedAnswer);
      const formattedVitals = handleVitals(answer, showEditor, editableVitals);
      setEditableVitals(formattedVitals);
    }
  }, [answer]);

  useEffect(() => {
    if (answer) {
      // Handle Assessment Formatting
      const formattedAssessments = formatAssessments(answer, showEditor);
      setEditableAssessment(formattedAssessments);
    }
  }, [assessmentAnswer]);

  useEffect(() => {}, [prescriptionData]); // Runs when 'prescriptionData' changes

  const handleCloseedit = async () => {
    setShowEditor(false);
  };

  const handleAddRow = () => {
    setPrescriptionData((prev) => [
      ...prev,
      {
        id: Date.now(),
        medication_name: "",
        dosage: "",
        frequency: { morning: "", afternoon: "", night: "" },
        duration: "",
        instructions: "",
      },
    ]);
  };
  const handleAddRowTemplate = () => {
    // Template selection add row
    setTemplateData((prev) => [
      ...prev,
      {
        id: Date.now(),
        medication_name: "",
        dosage: "",
        frequency: { morning: "", afternoon: "", night: "" },
        duration: "",
        instructions: "",
      },
    ]);
  };

  const handleTemplateDataResponse = (response) => {
    if (response?.data) {
      const formattedData = response.data.map((medication, index) => ({
        id: `template-${index + 1}`, // Generate unique IDs
        medication_name: medication.medication_name || "",
        dosage: medication.dosage || "",
        frequency: {
          morning: medication.frequency_morning || "",
          afternoon: medication.frequency_afternoon || "",
          night: medication.frequency_night || "",
        },
        duration: medication.duration || "",
        instructions: medication.instructions || "",
      }));
      setTemplateData(formattedData); // Set the formatted data, not the previous state
    } else {
      console.warn("Invalid template data response.");
    }
  };

  const handleTemplateSelect = async (templateId) => {
    setSelectedTemplate(templateId);

    if (!templateId) {
      setTemplateData(prescriptionData); // Use prescriptionData if no template is selected
      return;
    }

    try {
      const token = sessionStorage.getItem("orgtoken");
      const response = await fetch(
        `${configJson.backend_URL}/medicationList/medication/temp/${templateId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch template medication data");
      }

      const templateData = await response.json();
      handleTemplateDataResponse(templateData); // Process and set data for the table
    } catch (error) {
      console.error("Error fetching template medication data:", error.message);
    }
  };

  const handleDeleteRow = (id) => {
    setPrescriptionData((prev) => prev.filter((row) => row.id !== id));
  };

  const handleUpdateRow = (id, field, value) => {
    // console.log("onChange triggered for:", { id, field, value }); // Log the field being updated and its new value

    setPrescriptionData((prev) =>
      prev.map((row) =>
        row.id === id
          ? field === "frequency"
            ? { ...row, frequency: value }
            : { ...row, [field]: value }
          : row
      )
    );
    setIsTableEdited(true); // Mark table as edited
  };

  const handleTemplateUpdateRow = (id, field, value) => {
    setTemplateData((prev) =>
      prev.map((row) =>
        row.id === id
          ? field === "frequency"
            ? { ...row, frequency: value }
            : { ...row, [field]: value }
          : row
      )
    );
    setIsTableEditedTemplate(true); // Mark table as edited
  };

  const handleTemplateDeleteRow = (id) => {
    setTemplateData((prev) => prev.filter((row) => row.id !== id));
  };

  const handleSave = async (selectedTemplate) => {
    setSaved(true);

    const updatedData = { comments, prescriptionData, templateData };
    // console.log("editvitals",editableVitals)
    // Declare updatedAssessment at the top of the function
    let updatedAssessment = "";

    if (onAnswerChange) {
      const updatedDiagnosis =
        editableAnswer === answer
          ? formatJsonAnswer(answer, showEditor)
          : editableAnswer;

      // Assign value to updatedAssessment
      updatedAssessment =
        editableAssessment === assessmentAnswer
          ? formatAssessments(assessmentAnswer, showEditor)
          : editableAssessment;

      await onAnswerChange(
        updatedDiagnosis,
        updatedData.prescriptionData,
        updatedAssessment
      );
    }
    handlePrint();
    handleConvertPDF();

    // // Handle Prescription Data Upload
    if (!selectedTemplate && prescriptionData) {
      const fileContent = `
          ${JSON.stringify(prescriptionData, null, 2)}
        `;
      const fileName = `updatedprescription/${patientId}_${getFormattedDateTimeUTC()}.txt`;
      const success = await uploadFileToGCS(
        fileContent,
        fileName,
        patientId,
        doctorId,
        "Edited Prescription",
        audioUrlInteractionId
      );
      if (success) {
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);
      }
    }

    // Handle Template Data Upload
    if (selectedTemplate && templateData) {
      const fileContent = `
          ${JSON.stringify(templateData, null, 2)}
        `;
      const fileName = `updatedtemplate/${patientId}_${getFormattedDateTimeUTC()}.txt`;
      const success = await uploadFileToGCS(
        fileContent,
        fileName,
        patientId,
        doctorId,
        "Edited Prescription",
        audioUrlInteractionId
      );
      if (success) {
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 5000);
      }
    }

    // // Handle Updated Assessment Data Upload
    // if (updatedAssessment) {
    //   const fileContent = `
    //         ${JSON.stringify(updatedAssessment, null, 2)}
    //       `;
    //   const fileName = `updatedassessment/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Assessment",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }
  };

  const uploadFileToGCS = async (
    fileContent,
    fileName,
    patientId,
    doctorId,
    interactionDetailType,
    audioUrlInteractionId
  ) => {
    try {
      console.log(audioUrlInteractionId, "audiointupload");
      console.log(fileName, "filenamesave");
      // Create a .txt file blob
      const blob = new Blob([fileContent], { type: "text/plain" });

      // Prepare the form data for the GCS upload
      const formData = new FormData();
      formData.append("patient_id", patientId);
      formData.append("doctor_id", parseFloat(doctorId));
      formData.append(
        "interaction_date",
        new Date().toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append("interaction_id", audioUrlInteractionId);
      formData.append("interaction_type", "");
      formData.append("interaction_status", "");
      formData.append("interaction_notes", "");
      formData.append("interaction_detail_type", interactionDetailType);
      formData.append("file", blob, fileName);
      formData.append("file_name", fileName);

      // Upload the .txt file to GCS
      const result = await axios.post(
        `${configJson.backend_URL}/interaction/updateNewInteractionDetail/${audioUrlInteractionId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (result?.status) {
        console.log("File uploaded successfully:", result.data);
        return true; // Return success
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false; // Return failure
    }
  };

  const handleSaveTemplate = async () => {
    // Temporarily store the updated data for printout
    setTempTemplateData(prescriptionDataTemplate);
    console.log("Data temporarily saved for printout:", tempTemplateData);
    // You can now use this temporary data for printout or any other purpose
  };

  const handleConvertPDF = async () => {
    setSaved(true); // Indicate that saving is in progress
    setLoading(true); // Set loading to true

    const elementsToHide = document.querySelectorAll(
      ".printDisable, .react-quill-container, .no-print"
    );
    elementsToHide.forEach((el) => {
      el.classList.add("hide-during-print");
    });

    try {
      // Dynamically inject additional style for better printing
      const style = document.createElement("style");
      style.textContent = `
        p.oupathead {
          padding-bottom: 10px !important;
        }
        input.inputCommnts::placeholder {
          color: transparent !important;
        }
        .medicinetable th span {
          padding-bottom: 10px !important;
        }
        .medicinetable {
          border: 1px solid #4d4d4d00 !important;
        }
        .medicinetable td, .medicinetable th {
          border: 1px solid #4D4D4D !important;
          padding-bottom: 5px !important;
        }
        .medicinetable th {
          margin-bottom:10px !important;
          padding-bottom: 20px !important;
        }
        .medicinetable th:first-child {
          margin-bottom: 10px !important;
          padding-bottom: 10px !important;
        }
        .medinamesaved{}
      `;
      document.head.appendChild(style);

      const element = printRef.current; // Element to capture
      if (!element) return;

      // Capture the element as a canvas
      const canvas = await html2canvas(element, {
        scale: 2, // Use higher scale for better quality
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page size

      const imgWidth = canvas.width * (pdfWidth / canvas.width);
      const imgHeight = canvas.height * (pdfWidth / canvas.width);

      let remainingHeight = imgHeight;
      let position = 0;
      let pageCount = 1;

      // Slice the image and add it to multiple pages
      while (remainingHeight > 0) {
        const sliceHeight =
          remainingHeight > pdfHeight ? pdfHeight : remainingHeight;

        const canvasSlice = document.createElement("canvas");
        canvasSlice.width = canvas.width;
        canvasSlice.height = (sliceHeight * canvas.width) / pdfWidth;

        const ctx = canvasSlice.getContext("2d");
        ctx.drawImage(
          canvas,
          0,
          position,
          canvas.width,
          canvasSlice.height,
          0,
          0,
          canvasSlice.width,
          canvasSlice.height
        );

        const sliceData = canvasSlice.toDataURL("image/png");

        // Add the first page without top padding
        if (remainingHeight === imgHeight) {
          pdf.addImage(
            sliceData,
            "PNG",
            0,
            0,
            pdfWidth,
            (canvasSlice.height * pdfWidth) / canvas.width
          );
        } else {
          // For subsequent pages, add top padding (10px)
          pdf.addPage();
          pdf.addImage(
            sliceData,
            "PNG",
            0,
            10,
            pdfWidth,
            (canvasSlice.height * pdfWidth) / canvas.width
          );
        }

        // Add page number at the bottom of the page
        const pageNumberText = `Page ${pageCount}`;
        pdf.setFontSize(10);
        pdf.text(pageNumberText, pdfWidth - 30, pdfHeight - 10);

        remainingHeight -= pdfHeight;
        position += canvasSlice.height;
        pageCount++;
      }

      const pdfBlob = pdf.output("blob");
      const fileName = `${patientName}_${registrationNumber}_${getFormattedDateTimeUTC()}.pdf`;

      const formData = new FormData();
      formData.append("patient_id", patientId);
      formData.append("doctor_id", parseFloat(doctorId));
      formData.append(
        "interaction_date",
        new Date().toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append("interaction_type", "");
      formData.append("interaction_status", "");
      formData.append("interaction_notes", "");
      formData.append("interaction_detail_type", "Medicine Template");
      formData.append("file", pdfBlob, fileName);
      formData.append("file_name", `files/${fileName}`);

      const result = await axios.post(
        `${configJson.backend_URL}/interaction/createImgwithoutingest`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (result?.status) {
        // Show the popup message
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false); // Automatically hide after 5 seconds
        }, 5000);

        const recordStatus = sessionStorage.getItem("isRecording");
        if (fetchInteractions && recordStatus == "false")
          fetchInteractions(patientId);
      } else {
        console.error("No response data received from the server.");
        const recordStatus = sessionStorage.getItem("isRecording");
        if (fetchInteractions && recordStatus == "false")
          fetchInteractions(patientId);
      }
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
      const recordStatus = sessionStorage.getItem("isRecording");
      if (fetchInteractions && recordStatus == "false")
        fetchInteractions(patientId);
    } finally {
      elementsToHide.forEach((el) => {
        el.classList.remove("hide-during-print");
      });
      const injectedStyles = document.head.querySelectorAll("style");
      injectedStyles.forEach((style) => {
        if (style.textContent.includes("p.oupathead")) {
          document.head.removeChild(style);
        }
      });

      setSaved(false); // Reset saving state
      setLoading(false); // Set loading to false when done
    }
  };

  const calculateAge = (birthDate) => {
    console.log("brthdate", birthDate);
    if (!birthDate) return "Invalid Date"; // Handle missing or invalid date

    const [day, month, year] = birthDate.split("-"); // Assuming input is in DD-MM-YYYY format
    const parsedBirthDate = new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format

    if (isNaN(parsedBirthDate)) return "Invalid Date"; // Handle invalid date

    const today = new Date();
    let age = today.getFullYear() - parsedBirthDate.getFullYear();
    const monthDiff = today.getMonth() - parsedBirthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < parsedBirthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatJsonAnswer = (rawAnswer, showEditor) => {
    // console.log("showedit", showEditor);
    if (!rawAnswer) {
      return "<p style='color: red;'>No answer provided</p>";
    }
    if (!showEditor) {
      return `<p>${rawAnswer["updatedDiagnosis"]}</p>`;
    }

    try {
      // Ensure rawAnswer is valid JSON
      const answerObject = JSON.parse(rawAnswer.trim());
      console.log("answerObject", answerObject,organization_id,configJson.ABC);
      if(decodes.organization_id === configJson.ABC){
        const additionalDetails = [
          "C/O",
          "Present illness",
          "Clinical Diagnosis",
        ]
          .filter(
            (field) =>
              answerObject[field] &&
              answerObject[field] !== "not specified in the provided context." &&
              answerObject[field] !== "Not specified in the provided context."
          )
          // Include only fields that exist
          .map((field) => {
            let fieldValue = answerObject[field];
  
            // Handle "Clinical Diagnosis" separately to extract the Diagnosis from the object
            if (
              field === "Clinical Diagnosis" &&
              typeof fieldValue === "object" &&
              fieldValue.Diagnosis
            ) {
              fieldValue = fieldValue.Diagnosis; // Extract the Diagnosis field from the object
            }
  
            // Return the formatted string for each field
            return `<p><strong>${field}:</strong> ${fieldValue}</p>`;
          })
          .join("");
  
        return additionalDetails;
      }else{
        const additionalDetails = [
          "C/O",
          "Present illness",
          "Patient Past History",
          "Personal",
          "General Examination",
          "Clinical Diagnosis",
        ]
          .filter((field) => decodes.organization_id === configJson.ABC || field !== "Clinical Diagnosis")
          .filter(
            (field) =>
              answerObject[field] &&
              answerObject[field] !== "not specified in the provided context." &&
              answerObject[field] !== "Not specified in the provided context."
          )
          // Include only fields that exist
          .map((field) => {
            let fieldValue = answerObject[field];
  
            // Handle "Clinical Diagnosis" separately to extract the Diagnosis from the object
            if (
              field === "Clinical Diagnosis" &&
              typeof fieldValue === "object" &&
              fieldValue.Diagnosis
            ) {
              fieldValue = fieldValue.Diagnosis; // Extract the Diagnosis field from the object
            }
  
            // Return the formatted string for each field
            return `<p><strong>${field}:</strong> ${fieldValue}</p>`;
          })
          .join("");
  
        return additionalDetails;
      }
    
      
    } catch (error) {
      // If parsing fails, return rawAnswer wrapped in a paragraph
      return `<p>${rawAnswer}</p>`;
    }
  };
  const handleVitals = (answer, showEditor, editableVitals) => {
    if (!showEditor) {
      // Format the updated vitals from editableVitals
      const formattedUpdatedVitals = editableVitals.map((vital, index) => ({
        id: index,
        pulse: vital.pulse || "-",
        height: vital.height || "-",
        weight: vital.weight || "-",
        systolicBP: vital.systolicBP || "-",
        diastolicBP: vital.diastolicBP || "-",
        // position: vital.position || "-", // Include position
        oxygenSaturation: vital.oxygenSaturation || "-",
        bodyTemperature: vital.bodyTemperature || "-", // Use camelCase directly
        respiratoryRate: vital.respiratoryRate || "-", // Use camelCase directly
        bloodSugar: vital.bloodSugar || "-", // Use camelCase directly
      }));

      console.log("Formatted Updated Vitals:", formattedUpdatedVitals); // Log formatted updated vitals
      return formattedUpdatedVitals; // Return the formatted vitals for display
    }

    try {
      // Parse the original answer
      const parsedAnswer = JSON.parse(answer.trim());
      console.log("Parsed Answer:", parsedAnswer); // Log the parsed answer to verify the input data

      const vitals = parsedAnswer["Vitals"] || [];
      console.log("Vitals Raw Data:", vitals); // Log the raw vitals data

      const formattedVitals = vitals.map((vital, index) => ({
        id: index,
        pulse: vital.pulse || "-",
        height: vital.height || "-",
        weight: vital.weight || "-",
        systolicBP: vital.systolicBP || "-",
        diastolicBP: vital.diastolicBP || "-",
        // position: vital.position || "-", // Include position
        oxygenSaturation: vital.oxygenSaturation || "-",
        bodyTemperature: vital["Body Temperature"] || "-", // Include Body Temperature
        respiratoryRate: vital["Respiratory Rate"] || "-", // Include Respiratory Rate
        bloodSugar: vital["Blood Sugar"] || "-", // Include Blood Sugar
      }));

      console.log("Formatted Vitals:", formattedVitals); // Log the formatted vitals data

      return formattedVitals; // Return the formatted vitals for display
    } catch (error) {
      console.error("Error processing vitals:", error);
      return [];
    }
  };

  const handleFormatPrescription = (rawAnswer, showEditor) => {
    // console.log(rawAnswer, "rawans");
    // console.log(showEditor, "showedirprec");
    if (!rawAnswer) return [];

    try {
      // Declare prescriptions array once
      const prescriptions = [];
      // Check if showEditor is false, format the data as required
      if (!showEditor) {
        const answerObject = JSON.parse(rawAnswer.jsonData);
        // console.log(answerObject, "rawansform");
        // Map through the array to format the medication data
        answerObject.forEach((item, index) => {
          const medicationTemplate = item["medication_template"];

          if (
            medicationTemplate &&
            Object.keys(medicationTemplate).length > 0
          ) {
            prescriptions.push({
              id: index + 1, // Unique ID for each prescription
              medication_name: medicationTemplate.medication_name || "",
              dosage: medicationTemplate.dosage || "",
              frequency: {
                morning: medicationTemplate.frequency_morning || "",
                afternoon: medicationTemplate.frequency_afternoon || "",
                night: medicationTemplate.frequency_night || "",
              },
              duration: medicationTemplate.duration || "",
              instructions: medicationTemplate.instructions || "",
            });
          }
        });

        // console.log("Formatted prescriptions after update", prescriptions);
        return prescriptions; // Return formatted prescriptions
      }
      // console.log("eeee");
      const answerObject = JSON.parse(rawAnswer.trim());
      // console.log("ansobj", answerObject);
      // Original logic for "showEditor: true"
      const medicationTemplate = answerObject["medication_template"];
      const clinicalDiagnosis = answerObject["Clinical Diagnosis"];
      const { Prescription } = clinicalDiagnosis || {};

      // Check if medication_template exists and has content
      if (Array.isArray(medicationTemplate) && medicationTemplate.length > 0) {
        medicationTemplate.forEach((medication, index) => {
          prescriptions.push({
            id: index + 1,
            medication_name: medication.medication_name || "",
            dosage: medication.dosage || "",
            frequency: {
              morning: medication.frequency_morning || "",
              afternoon: medication.frequency_afternoon || "",
              night: medication.frequency_night || "",
            },
            duration: medication.duration || "",
            instructions: medication.instructions || "",
          });
        });
      }
      // If medication_template is empty, fall back to Clinical Diagnosis Prescription
      else if (Prescription && Object.keys(Prescription).length > 0) {
        prescriptions.push({
          id: 2,
          medication_name: Prescription.Medication || "",
          dosage: Prescription.Dosage || "",
          frequency: {
            morning: Prescription.Morning || "",
            afternoon: Prescription.Afternoon || "",
            night: Prescription.Night || "",
          },
          duration: "", // Clinical Diagnosis Prescription doesn't have duration
          instructions: Prescription.Instructions || "",
        });
      }

      return prescriptions; // Return the formatted data for the editor
    } catch (error) {
      console.error("Error parsing prescription data:", error);
      return [];
    }
  };

  const formatAssessments = (rawAnswer, showEditor) => {
    if (!rawAnswer) return "";

    if (!showEditor) {
      return `<p>${rawAnswer["updatedAssessment"]}</p>`;
    }

    try {
      const assessments = [];
      const answerObject = JSON.parse(rawAnswer.trim());
      const assessmentData = answerObject["assessment"];

      // Safeguard for empty or undefined assessment array
      if (Array.isArray(assessmentData) && assessmentData.length > 0) {
        assessmentData.forEach((assessment) => {
          const templateName = assessment.assessment_template || "";
          const tests = assessment.template_tests
            ? assessment.template_tests.split(",").map((test) => test.trim())
            : [""];

          assessments.push({
            templateName,
            tests,
          });
        });
      }

      return `
      <div class="assessment-container">
      ${assessments
        .map(
          (assessment) => `
            <strong>${
              assessment.templateName.charAt(0).toUpperCase() +
              assessment.templateName.slice(1)
            }</strong>
            ${
              assessment.tests && assessment.tests.length > 0
                ? `
                <ul class="assessment-tests">
                  ${assessment.tests
                    .map(
                      (test) =>
                        `<li class="test-item">${
                          test.charAt(0).toUpperCase() + test.slice(1)
                        }</li>`
                    )
                    .join("")}
                </ul>
                `
                : ""
            }
          `
        )
        .join("")}
      
      
</div>

      `;
    } catch (error) {
      console.error("Error parsing assessment data:", error);
      return "<p>Error displaying assessments.</p>";
    }
  };

  // Function to handle template selection
  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };
  const handleBlur = (rowId, medicationName) => {
    if (!medicationName || !medicationName.trim()) {
      setPrescriptionErrors((prevErrors) => ({
        ...prevErrors,
        [rowId]: "Medication Name is required", // Set error for this row
      }));
    } else {
      setPrescriptionErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[rowId]; // Remove error if validation passes
        return newErrors;
      });
    }
  };

  const handlePrint = () => {
    setShowEditor(false);

    const content = printRef.current.innerHTML;
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";
    document.body.appendChild(printFrame);

    const doc = printFrame.contentWindow.document;
    doc.open();
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
      <title>${document.title}</title>
          <style>
        @page {
  size: A4;
  margin: 0.6in; /* Reduced margin for more content space */
}

@page {
  @bottom-right {
    content: "Page " counter(page) " of " counter(pages);
  }
}
.outpatient-sheet p {
    margin-bottom: 0px;
    font-size: 14px;
}
.flexprint{
 display: flex !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    align-items: center !important;
}
body {
  margin: 0; /* Remove default body margin to align content properly */
}
.vitalspart th {
  border-bottom: 1px solid #ccc;
}

.vitalspart {
  border-spacing: 5px; /* Add spacing between cells */
  border-collapse: separate; /* Ensure border-spacing works */
   border: 1px solid #ccc;
}
.vitalspart.table td.align-middle{
  text-align: center !important;
}
.lftdoctside{
width:42% !important;
}
.ql-editor h3{
padding:0px !important;
margin:0px !important;
}
  .medinamesaved{
  width:100% !important;
  }
  p.addrCls{
font-size:12px !important;
  }
.doctorSize{

}
.PatnamePad{
padding:0px 7px !important;
}
.rghttnmcreg{
padding-left:0px !important;
padding-right:0px !important;
}
  .printlastnonecol{
  width:60% !important;
  }
  .daysprt{
  width:15% !important;
  }
  span.medinamesaved {
    width: 80% !important;
}
    .dosagewdt{
        width: 30% !important;
    }
    .info-row, .section-label, .section-address {
    font-family: 'Calibri', sans-serif !important;
    font-size: 16px !important;
  }
   .outpatient-sheet .DetailContainer .info-row  p.section-value{
   font-size: 24px !important;
   }

   .cstmapponttble{
    padding-bottom:10px !important;
   }
   
table.cstmapponttble td.frstrow{
    width: 100%;
    font-size:14px !important;
    padding:0px !important;
     margin:0px !important;
}
 .appointmentend info-col col{
   float: right;
    width: 50%;
 }
    .cstmapponttble td{
    font-size:15px !important;
    }
.info-row .info-col {
    float: left;
    width: 50%;
  }

  .info-row .appointmentend {
    float: right;
    width: 45%; /* Ensures right column has enough space */
    border-left: 2px solid rgba(0, 0, 0, 0.5);
    padding-left: 10px !important;
    margint-top:-5px !important;
  }
  .section-label, .section-colon, .section-value {
    padding: 5px;
  }
body {
  font-family: Calibri, sans-serif !important;
}
  .info-col.col p {
  font-size: 16px !important;
}
  .info-col{
  padding:0px !important;
  margin:0px !important;
  }
  .section-address{
  padding-left:5px !important;
  }
  .info-row.clearfixer.row {
    margin-left: -5px;
}

  .section-value{
  font-size:24px !important;
  }
  .cstmapponttble td{
  width:40% !important
}
  .headerPart{
  padding:0px !important;
    margin:0px !important;
  }
    .headercenter p.oupathead{
 
    font-size:20px !important;
    }
.headerlft img{
   width: "auto !important", // Set the exact width
height:auto !important;
}

.outpatient-sheet.container strong {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 10px !important;
}


.outpatient-sheet.container img {
  text-align: left !important;
  margin: 0px !important;
  padding: 0px !important;
  height: auto !important;
}

input.inputCommnts::placeholder {
  color: transparent !important; /* Hides the placeholder text */
}
table.medicinetable.table.table-bordered {
    border-left: 2px solid #e5e7eb !important;
        border-right:  2px solid #e5e7eb !important;
         border-top: 2px solid #e5e7eb !important;
}
    table.medicinetable.table.table-bordered th,table.medicinetable.table.table-bordered td{
          border-bottom: 2px solid #e5e7eb !important;
           border-right: 2px solid #e5e7eb !important;
    }
            table.medicinetable.table.table-bordered th.printlastnonecol,table.medicinetable.table.table-bordered td.printlastnonecol{
           border-right: 2px solid #5b5b5b00 !important;
    }
          
      table.medicinetable.table.table-bordered th.printDisable{
      width:0% !important;
      }
hr {
  border: 1px solid #000 !important; /* Light gray border */
  margin: 0;
  opacity: 0.5;
}

.appointmentend.info-col.col {
padding-left:50px;
}
.info-col.cstmpad.col{
padding-left:15px;
}
  .appointmentend.info-col.col::before {
    content: "";
    background: black;
    position: absolute;
    left: 0px;
    height: 140px;
    width: 2px;
    opacity: 0.5;
    top: 0px;
}
  
.DetailContainer .info-row{
padding-top:10px !important;
} 
.DetailContainer strong{
font-weight:600 !important;
}
.DetailContainer .info-row .bigfrontBoldtemp{
font-size: 24px !important;
 padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
    margin-bottom: 7px !important;
}
.info-col {
  width: 40%;
  float:left;
}
  .clearfixer::after {
    content: "";
    display: table;
    clear: both;
}
.outpatient-sheet p {
  margin-bottom: 8px;
}
.DetailContainer {
  padding-left: 0px;
  border-top: 2px solid;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.DetailContainer p {
  padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
      margin-bottom: 5px !important;
      font-family: Calibri, sans-serif !important;
      font-size:16px !important;

}
      
.DetailContainer .bigfrontBoldtemp{
font-size: 24px;
 padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
    margin-bottom: 7px !important;
}

hr {
  border: "1px solid black";
  margin: "0";
}

.page-break {
  page-break-before: always;
}

.printDisable {
  display: none;
}
.react-quill-container {
  display: none;
}
.no-print {
  display: none;
}

          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${content}
       
        </body>
      </html>
    `);
    doc.close();
  };

  return (
    <Container
      ref={printRef}
      className="outpatient-sheet"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #fff",
        fontFamily: "Calibri, sans-serif",
        fontSize: "10pt",
        boxSizing: "border-box",
        width: "100%",
        maxWidth: "794px",
        margin: "0 auto",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      {/* Add the popup message */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#4caf50",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          Saved Template Updated in Feed
        </div>
      )}

      <div>
        <DataSheetHeader organization_id={organization_id} />
      </div>

      <div className="DetailContainer">
        <Row
          className="info-row flexprint"
          style={{ paddingBottom: "6px", paddingTop: "6px" }}
        >
          <Col sm={8}>
            <p style={{ fontSize: "15px", paddingBottom: "0px" }}>
              Registration Number :
              <strong style={{ fontSize: "18px" }}>
                {" "}
                {registrationNumber || ""}
              </strong>{" "}
            </p>
            {/* <p
              className="bigfrontBoldtemp"
              style={{ fontSize: "24px", paddingBottom: "5px" }}
            >
              
            </p>
            <p
              style={{ fontSize: "15px", paddingBottom: "5px" }}
              className="pt-1"
            >
           
            </p> */}
          </Col>
          <Col sm={4}>
            <p style={{ fontSize: "15px", paddingBottom: "0px" }}>
              Date :{" "}
              <strong style={{ fontSize: "18px" }}>{date || "Date"}</strong>
            </p>
            {/* <p
              className="bigfrontBoldtemp"
              style={{ fontSize: "24px", paddingBottom: "5px" }}
            >
              
            </p>
            <p
              style={{ fontSize: "15px", paddingBottom: "5px" }}
              className="pt-1"
            >
           
            </p> */}
          </Col>
        </Row>
        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "0px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />

        <Row className="info-row clearfixer PatnamePad">
          <Col className="info-col cstmpad" style={{ paddingLeft: "11px" }}>
            <p style={{ fontSize: "16px" }}>
              Patient Name{organization_id !== 15 && " with Address"}
            </p>
            <p className="bigfrontBoldtemp" style={{ fontSize: "24px" }}>
              <strong>{patientName || "Patient Name"}</strong>
            </p>
            {organization_id !== 15 && (
              <p style={{ fontSize: "15px", paddingTop: "5px" }}>
                {patientAddress || ""}
              </p>
            )}
          </Col>

          <Col
            className="appointmentend info-col"
            style={{ position: "relative", paddingBottom: "10px" }}
          >
            <table
              className="cstmapponttble"
              style={{ width: "100%", border: "none" }}
            >
              <tbody>
                {/* <tr>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    Appointment No
                  </td>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "5%",
                      textAlign: "center",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "65%",
                      textAlign: "left",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    <strong>{tokenNumber || ""}</strong>
                  </td>
                </tr> */}
                <tr>
                  <td
                    className="agedet"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    Age / Gender
                  </td>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "5%",
                      textAlign: "center",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    :
                  </td>
                  <td
                    className="agedet"
                    style={{
                      fontSize: "15px",
                      width: "65%",
                      textAlign: "left",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    {country !== "United States of America" &&
                      organization_id != 15 && (
                        <strong>
                          {formattedDob
                            ? `${calculateAge(formattedDob)} Y`
                            : ""}
                          {dob && gender ? " / " : ""}
                          {gender}
                        </strong>
                      )}
                    {ageField && organization_id == 15 && (
                      <strong>
                        {ageField} Y{ageField && gender ? " / " : ""}
                        {gender}
                      </strong>
                    )}
                  </td>
                </tr>
                {organization_id !== 15 && (
                  <>
                    <tr>
                      <td
                        className="agedet"
                        style={{
                          fontSize: "15px",
                          width: "100%",
                          textAlign: "left",
                          paddingRight: "10px",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        Date of Birth
                      </td>
                      <td
                        style={{
                          fontSize: "15px",
                          width: "5%",
                          textAlign: "center",
                          paddingRight: "10px",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        :
                      </td>
                      <td
                        className="agedet"
                        style={{
                          fontSize: "15px",
                          width: "65%",
                          textAlign: "left",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        {country !== "United States of America" && (
                          <strong>{formattedDob || ""}</strong>
                        )}
                      </td>
                    </tr>

                    {/* <tr>
                      <td
                        className="agedet"
                        style={{
                          fontSize: "15px",
                          width: "100%",
                          textAlign: "left",
                          paddingRight: "10px",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        Marital Status
                      </td>
                      <td
                        style={{
                          fontSize: "15px",
                          width: "5%",
                          textAlign: "center",
                          paddingRight: "10px",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        :
                      </td>
                      <td
                        className="agedet"
                        style={{
                          fontSize: "15px",
                          width: "65%",
                          textAlign: "left",
                          paddingBottom: "0px",
                          border: "none",
                          paddingTop: "0px",
                        }}
                      >
                        <strong></strong>
                      </td>
                    </tr> */}
                  </>
                )}

                <tr>
                  <td
                    className="mobnmbr agedet"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    Mobile Number
                  </td>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "5%",
                      textAlign: "center",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    :
                  </td>
                  <td
                    className="agedet"
                    style={{
                      fontSize: "15px",
                      width: "65%",
                      textAlign: "left",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                  >
                    <strong>
                      {country === "United States of America"
                        ? mobileNumber || ""
                        : `+${mobileNumber || ""}`}
                    </strong>
                  </td>
                </tr>
                {/* {organization_id !== 15 && (
                  <tr>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        textAlign: "left",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      Corporate
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </td>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "65%",
                        textAlign: "left",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      <strong></strong>
                    </td>
                  </tr>
                )} */}
              </tbody>
            </table>
          </Col>
        </Row>
        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "5px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />
        <Row className="">
          <Col>
            <table
              className="docformat"
              style={{ width: "100%", border: "none" }}
            >
              <tbody>
                <tr>
                  {/* Left Column */}
                  <td
                    className="lftdoctside"
                    style={{
                      width: "58%",
                      verticalAlign: "top",
                      border: "none",
                      paddingRight: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <table style={{ width: "96%", border: "none" }}>
                      <tbody>
                        <tr>
                          <td
                            className="doctorSize"
                            style={{
                              fontSize: "15px",
                              width: "10%",
                              textAlign: "left",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            Primary Doctor
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "5%",
                              textAlign: "center",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "45%",
                              textAlign: "left",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            {salutation || ""} {doctorName || ""}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "30%",
                              textAlign: "left",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "5%",
                              textAlign: "center",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: "13px",
                              width: "65%",
                              textAlign: "left",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            ({specialtyName || ""})
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  {/* Right Column */}
                  <td
                    className="rghttnmcreg"
                    style={{
                      width: "35%",
                      verticalAlign: "top",
                      border: "none",
                    }}
                  >
                    <table style={{ width: "100%", border: "none" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: "0px",
                              fontSize: "15px",
                              width: "50%",
                              textAlign: "left",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              paddingLeft: "0px",
                              border: "none",
                            }}
                          >
                            TNMC Reg No
                          </td>
                          <td
                            style={{
                              paddingTop: "0px",
                              fontSize: "15px",
                              width: "5%",
                              textAlign: "center",
                              paddingRight: "10px",
                              paddingLeft: "0px",
                              paddingBottom: "0px",
                              border: "none",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              paddingTop: "0px",
                              fontSize: "15px",
                              width: "35%",
                              textAlign: "left",
                              paddingBottom: "0px",
                              border: "none",
                            }}
                          >
                            {licenceNo}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "30%",
                              textAlign: "left",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            Referral Doctor
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "5%",
                              textAlign: "center",
                              paddingRight: "10px",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontSize: "15px",
                              width: "65%",
                              textAlign: "left",
                              paddingBottom: "0px",
                              border: "none",
                              paddingTop: "0px",
                              paddingLeft: "0px",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "5px",
            marginTop: "10px",
            opacity: "0.5",
          }}
        />
        <Row className="">
          <Col>
            <table style={{ width: "100%", border: "none" }}>
              <tbody>
                {/* <tr>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    Comments :
                  </td>

                  <td
                    style={{
                      fontSize: "15px",
                      width: "75%",
                      textAlign: "left",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <input
                      className="inputCommnts"
                      type="text"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontSize: "15px",
                        backgroundColor: "transparent",
                      }}
                    />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <p
              style={{
                fontSize: "15px",
                width: "30%",
                textAlign: "left",
                paddingRight: "10px",
                paddingBottom: "0px",
                border: "none",
                paddingTop: "0px",
                paddingLeft: "0px",
              }}
            >
              <strong>Diagnosis:</strong>
            </p>

            {/* Conditionally Display Formatted Content */}
            {!showEditor && (
              <div
                style={{ fontSize: "15px", width: "100%", textAlign: "left" }}
                className="diagnosis-content mb-2"
                dangerouslySetInnerHTML={{
                  __html: formatJsonAnswer(answer, showEditor),
                }}
              ></div>
            )}

            {/* Conditionally Display Editable Quill Editor */}
            {showEditor && (
              <div className="react-quill-container">
                <ReactQuill
                  value={editableAnswer} // Use the editable answer directly
                  onChange={(value) => setEditableAnswer(value)} // Update the edited content
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, false] }],
                      ["link", "image"],
                    ],
                  }}
                  formats={[
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "header",
                    "link",
                    "image",
                  ]}
                />
              </div>
            )}
          </Col>
        </Row>

        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "5px",
            marginTop: "10px",
            opacity: "0.5",
          }}
        />

        {showEditor ? (
          <Row className="mt-3">
            <Col>
              <p
                style={{
                  fontSize: "15px",
                  textAlign: "left",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  border: "none",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                }}
              >
                <strong>Assessment:</strong>
              </p>
            </Col>
            <Col className="text-end">
              <div>
                <select
                  style={{
                    width: "250px",
                    display: "inline-block",
                    marginBottom: "5px",
                    height: "33px",
                    paddingTop: "5px",
                  }}
                  onChange={(e) => handleAssessmentSelect(e.target.value)}
                  value={selectedAssessment || ""}
                >
                  <option value="defaultVeraResponse">
                    Assessment Template
                  </option>
                  {assessmentList.map((assessment) => (
                    <option
                      key={assessment.assessment_id}
                      value={assessment.assessment_id}
                    >
                      {assessment.assessment_template}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="mt-3">
            <Col>
              <p
                className="assessmentrespo"
                style={{
                  fontSize: "15px",
                  textAlign: "left",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  border: "none",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                }}
              >
                <strong>Assessment:</strong>
              </p>
            </Col>
          </Row>
        )}
        <Row className="">
          <Col>
            {/* Conditionally Display Formatted Content */}
            {!showEditor && (
              <div
                style={{ fontSize: "15px", width: "100%", textAlign: "left" }}
                className="diagnosis-content mb-2"
                dangerouslySetInnerHTML={{
                  __html: `
        ${formatAssessments(answer, showEditor)}
      `,
                }}
              ></div>
            )}

            {/* Conditionally Display Editable Quill Editor */}
            {showEditor && (
              <div className="react-quill-container">
                <ReactQuill
                  value={editableAssessment} // Use the editable assessment directly
                  onChange={(value) => setEditableAssessment(value)} // Update the edited content
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, false] }],
                      ["link", "image"],
                    ],
                  }}
                  formats={[
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "header",
                    "link",
                    "image",
                  ]}
                />
              </div>
            )}
          </Col>
        </Row>

        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "5px",
            marginTop: "10px",
            opacity: "0.5",
          }}
        />

        <Row className="mt-3">
          <p
            className="vitalssec"
            style={{
              fontSize: "15px",
              textAlign: "left",
              paddingRight: "10px",
              paddingBottom: "10px",
              border: "none",
              paddingTop: "0px",
              paddingLeft: "0px",
            }}
          >
            <strong>Vitals:</strong>
          </p>
          <p
            className="vitalstable"
            style={{
              fontSize: "15px",
              textAlign: "left",
              paddingRight: "10px",
              paddingBottom: "10px",
              border: "none",
              paddingTop: "0px",
              paddingLeft: "0px",
            }}
          >
            <VitalsTable
              editableVitals={editableVitals}
              showEditor={showEditor}
              handleInputChange={handleInputChange}
            />
          </p>
        </Row>
        {organization_id !== 15 && (
          <hr
            style={{
              border: "1px solid black",
              margin: "0",
              marginBottom: "5px",
              marginTop: "10px",
              opacity: "0.5",
            }}
          />
        )}
        <Row>
          <Col>
            {showEditor ? (
              <Row className="mt-3">
                <Col>
                  {/* <p
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <strong>Prescription:</strong>
                  </p> */}
                </Col>
                <Col className="text-end">
                  <Form.Select
                    value={selectedTemplate || "tempdefault"}
                    onChange={(e) => handleTemplateSelect(e.target.value)}
                    style={{
                      width: "250px",
                      display: "inline-block",
                      marginBottom: "5px",
                      height: "33px",
                      paddingTop: "5px",
                    }}
                  >
                    <option value="">Medicine Template</option>
                    {medicineData.map((template) => (
                      <option
                        key={template.template_id}
                        value={template.template_id}
                      >
                        {template.template_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            ) : (
              <Row className="mt-3">
                <Col>
                  {/* <p
                    className="prescrespo"
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                       <strong>Prescription:</strong>
                  </p> */}
                </Col>
              </Row>
            )}

            {showEditor && (
              <>
                {!selectedTemplate || selectedTemplate === "tempdefault" ? (
                  <Table bordered responsive className="medicinetable">
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              Medicine Name
                            </span>
                            {/* <span
                          className="medinamewdth"
                          style={{ width: "29%", textAlign: "right" }}
                        >
                          Dosage
                        </span> */}
                          </div>
                        </th>

                        <th
                          style={{
                            width: "10%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          FN
                        </th>
                        <th
                          style={{
                            width: "10%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          AN
                        </th>
                        <th
                          style={{
                            width: "10%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          N
                        </th>
                        <th
                          style={{
                            width: "10%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          Days
                        </th>
                        <th
                          className="printlastnonecol"
                          style={{
                            width: "20%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          Instructions
                        </th>
                        <th
                          className="printDisable"
                          style={{
                            width: "10%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Calibri",
                            padding: "5px",
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptionData.map((row) => (
                        <tr key={row.id}>
                          <td style={{ width: "30%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Medication Name"
                                value={row.medication_name}
                                onChange={(e) =>
                                  handleUpdateRow(
                                    row.id,
                                    "medication_name",
                                    e.target.value
                                  )
                                }
                                onBlur={() =>
                                  handleBlur(row.id, row.medication_name)
                                } // Trigger validation on blur
                                style={{
                                  width: "65%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                  borderColor: prescriptionErrors[row.id]
                                    ? "red"
                                    : "", // Highlight border if error
                                }}
                                required // This makes the input field required in the HTML
                              />

                              {/* Display error */}
                              <input
                                type="text"
                                placeholder="Dosage"
                                value={row.dosage}
                                onChange={(e) =>
                                  handleUpdateRow(
                                    row.id,
                                    "dosage",
                                    e.target.value
                                  )
                                }
                                style={{
                                  width: "21%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                }}
                              />
                            </div>
                            {prescriptionErrors[row.id] && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {prescriptionErrors[row.id]}{" "}
                                {/* Display error message */}
                              </div>
                            )}
                          </td>

                          <td className="reswdthadjst" style={{ width: "10%" }}>
                            <input
                              type="number"
                              value={row.frequency.morning}
                              onChange={(e) =>
                                handleUpdateRow(row.id, "frequency", {
                                  ...row.frequency,
                                  morning: parseFloat(e.target.value) || 0, // Update morning field
                                })
                              }
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                              min="0" // Prevent negative values
                            />
                          </td>
                          <td className="reswdthadjst" style={{ width: "10%" }}>
                            <input
                              type="number"
                              value={row.frequency.afternoon}
                              onChange={(e) =>
                                handleUpdateRow(row.id, "frequency", {
                                  ...row.frequency,
                                  afternoon: parseFloat(e.target.value) || 0, // Update afternoon field
                                })
                              }
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                              min="0" // Prevent negative values
                            />
                          </td>
                          <td className="reswdthadjst" style={{ width: "10%" }}>
                            <input
                              type="number"
                              value={row.frequency.night}
                              onChange={(e) =>
                                handleUpdateRow(row.id, "frequency", {
                                  ...row.frequency,
                                  night: parseFloat(e.target.value) || 0, // Update night field
                                })
                              }
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                              min="0" // Prevent negative values
                            />
                          </td>

                          <td className="reswdthadjst" style={{ width: "10%" }}>
                            <input
                              type="text"
                              value={row.duration}
                              onChange={(e) =>
                                handleUpdateRow(
                                  row.id,
                                  "duration",
                                  e.target.value
                                )
                              }
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                            />
                          </td>
                          <td
                            className="printlastnonecol"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="text"
                              value={row.instructions}
                              onChange={(e) =>
                                handleUpdateRow(
                                  row.id,
                                  "instructions",
                                  e.target.value
                                )
                              }
                              style={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                            />
                          </td>
                          <td style={{ width: "10%" }}>
                            <Button
                              className="printDisable"
                              variant="danger"
                              onClick={() => handleDeleteRow(row.id)}
                              style={{
                                fontSize: "15px",
                                fontFamily: "Calibri",
                                padding: "5px",
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <Button
                            className="printDisable"
                            variant="success"
                            onClick={handleAddRow}
                            style={{
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px 20px",
                            }}
                          >
                            Add Row
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <TableTemplateSelection
                    prescriptionDataTemplate={templateData} // Pass the template data here
                    handleTemplateUpdateRow={handleTemplateUpdateRow}
                    handleTemplateDeleteRow={handleTemplateDeleteRow}
                    handleAddRowTemplate={handleAddRowTemplate}
                  />
                )}
              </>
            )}
            {!showEditor && // Check if the editor is not shown
              (!selectedTemplate || selectedTemplate === "tempdefault"
                ? prescriptionData.length > 0 && ( // Check if no template or tempdefault is selected
                    <Table bordered responsive className="medicinetable">
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <span
                                className="medinamesaved"
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                Medication Name
                              </span>
                            </div>
                          </th>
                          <th
                            style={{
                              width: "10%",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            FN
                          </th>
                          <th
                            style={{
                              width: "10%",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            AN
                          </th>
                          <th
                            style={{
                              width: "10%",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            N
                          </th>
                          <th
                            className="daysprt"
                            style={{
                              width: "10%",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            Days
                          </th>
                          <th
                            className="printlastnonecol"
                            style={{
                              width: "20%",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Calibri",
                              padding: "5px",
                            }}
                          >
                            Instructions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptionData.map((row) => (
                          <tr key={row.id}>
                            <td style={{ width: "30%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "65%",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    fontFamily: "Calibri",
                                    padding: "5px",
                                  }}
                                >
                                  {row.medication_name}
                                </div>
                                <div
                                  style={{
                                    width: "31%",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    fontFamily: "Calibri",
                                    padding: "5px",
                                  }}
                                >
                                  {row.dosage}
                                </div>
                              </div>
                            </td>
                            <td
                              className="reswdthadjst"
                              style={{ width: "10%" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                }}
                              >
                                {row.frequency.morning}
                              </div>
                            </td>
                            <td
                              className="reswdthadjst"
                              style={{ width: "10%" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                }}
                              >
                                {row.frequency.afternoon}
                              </div>
                            </td>
                            <td
                              className="reswdthadjst"
                              style={{ width: "10%" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                }}
                              >
                                {row.frequency.night}
                              </div>
                            </td>
                            <td
                              className="daysprt reswdthadjst"
                              style={{ width: "10%" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                }}
                              >
                                {row.duration}
                              </div>
                            </td>
                            <td
                              style={{ width: "20%" }}
                              className="printlastnonecol"
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Calibri",
                                  padding: "5px",
                                  borderRadius: "4px", // Optional: Adds slight rounding to the edges
                                }}
                              >
                                {row.instructions || ""}{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )
                : templateData.length > 0 && (
                    <TemplateAfterUpdate
                      prescriptionDataTemplate={templateData}
                      handleTemplateUpdateRow={handleTemplateUpdateRow}
                    />
                  ))}
          </Col>
        </Row>
        <DataSheetFooter organization_id={organization_id} />

        <div className="text-end mt-2 no-print">
          {/* <Button
            style={{ marginRight: "20px" }}
            variant="primary"
            className="btn-print"
            onClick={async () => {
              await handleCloseedit();
              await handleSave(selectedTemplate); // Save changes first
              // handleConvertPDF();
            }}
          >
            Save Changes
          </Button> */}
          <Button
            variant="primary"
            className="btn-print"
            onClick={async () => {
              handleOpenModal(); // Open confirmation modal
            }}
          >
            Save and Print
          </Button>
          {/* {saved && (
            <span style={{ color: "green", marginLeft: "10px" }}>
              Changes saved!
            </span>
          )} */}

          <ConfirmationModal
            loading={loading}
            show={showModal}
            onHide={handleCloseModal}
            onConfirm={() => {
              // Define an async function for the sequence
              const handleConfirmSequence = async () => {
                await handleCloseedit(); // Ensure this completes first
                handleCloseModal(); // Close the modal
                await handleSave(selectedTemplate); // Save changes first

                // handleConvertPDF();
                // handlePrint(); // Then trigger the print
              };

              // Call the async function
              handleConfirmSequence();
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default OutpatientDataSheet;
