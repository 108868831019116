import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { configJson } from '../../Constent/config';


// Async Thunk for fetching assessments
export const fetchAssessments = createAsyncThunk(
  'assessments/fetchAssessments',
  async (organizationId) => {
    const response = await fetch(
      `${configJson.backend_URL}/assessment/list/${organizationId}`
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Error fetching assessments');
    }
    return data.assessments || [];
  }
);

const assessmentSlice = createSlice({
  name: 'assessments',
  initialState: {
    loading: false,
    assessments: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssessments.fulfilled, (state, action) => {
        state.loading = false;
        state.assessments = action.payload;
      })
      .addCase(fetchAssessments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default assessmentSlice.reducer;
