import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToUTC } from "../../../helpers";
import { Button, Spinner } from "react-bootstrap";
import { configJson } from "../../../Constent/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addPatientIfNotExists } from "../../../redux/slices/patientsSlice";

function USdoctor() {
  const [decode, setDecode] = useState(null);
  const [formData, setFormData] = useState({
    last_name: "",
    doctorName: "",
  });
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const send_link_url = window.location.origin;
  console.log(send_link_url);

  const handleSubmit = async () => {
    if (!formData.last_name || !formData.doctorName) {
      alert("Please fill out all fields.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/sessioncreate_patient`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            last_name: formData.last_name,
            organization_id: decode.organization_id,
            doctor_id: decode.doctor_id,
            send_link: send_link_url,
            interaction_date: date,
          }),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        console.log(data,"dataverify");
        if (data.patientStatus === "verified") {
          const patient = {
                  Patient: {
                    patient_id: data?.patient?.patient_id || "",
                    first_name:  data?.patient?.first_name || "",
                    last_name: data?.patient?.last_name || "",
                  },
                  doctor_id: data?.interactions.doctor_id || "",
                  interaction_status: data?.interactions?.interaction_status || "",
                  patient_id:data?.interactions?.patient_id|| "",
                  interaction_id:data?.interactions?.interaction_id||""
                };
                dispatch(addPatientIfNotExists(patient));
          navigate(`/activity?id=${data.patient.patient_id}`, {
            state: {
              interaction_id: data.interactions.interaction_id,
            },
          });
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      alert("There was an error creating the patient.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmiteffect = async (
    last_name,
    organization_id,
    doctor_id,
    dates,
    decodefirst_name,
    decodelast_name
  ) => {
    if (
      !last_name ||
      !organization_id ||
      !doctor_id ||
      !dates ||
      !decodefirst_name ||
      !decodelast_name
    ) {
      alert("Please fill out all fields.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/sessioncreate_patient`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            last_name: last_name,
            organization_id: organization_id,
            doctor_id: doctor_id,
            interaction_date: dates,
          }),
        }
      );
      console.log(response);
      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.patientStatus === "verified") {
          const patient = {
            Patient: {
              patient_id: data?.patient?.patient_id || "",
              first_name:  data?.patient?.first_name || "",
              last_name: data?.patient?.last_name || "",
            },
            doctor_id: data?.interactions.doctor_id || "",
            interaction_status: data?.interactions?.interaction_status || "",
            patient_id:data?.interactions?.patient_id|| "",
            interaction_id:data?.interactions?.interaction_id||""
          };
          dispatch(addPatientIfNotExists(patient));
    navigate(`/activity?id=${data.patient.patient_id}`, {
      state: {
        interaction_id: data.interactions.interaction_id,
      },
    });
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      alert("There was an error creating the patient.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Decode JWT token
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token); // Corrected function name
      if (decoded) {
        setDecode(decoded);
        // Get the current session value from sessionStorage

        setFormData((formData) => ({
          ...formData,
          last_name: " ",
          doctorName: decoded
            ? `${decoded.first_name} ${decoded.last_name}`
            : "",
        }));
        const formattedDate = convertToUTC();
        setDate(formattedDate);
        handleSubmiteffect(
          " ",
          decoded.organization_id,
          decoded.doctor_id,
          formattedDate,
          decoded.first_name,
          decoded.last_name
        );
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);
  console.log(formData);
  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <Button type="button" onClick={handleSubmit} className="eng-sub">
            Create New session
          </Button>
        </div>
      )}
    </>
  );
}

export default USdoctor;
