import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import USpatientEdit from "../../../FrontOffice/Components/USpatientEdit";
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaCircleArrowLeft } from "react-icons/fa6";

import { DateTime } from "luxon";
import { SideInteractionPatientContainer, SideListInput } from "./SidebarStyle";
import {
  addPatientToList,
  clearPatientList,
  clearSearchResults,
  fetchPatientsByDate,
  searchPatients,
  updatePatientStatus,
} from "../../../../redux/slices/patientsSlice";
import { set } from "react-hook-form";
import { FaX } from "react-icons/fa6";
import { getDateTimeWithOffset } from "./Sidebar";

function InteractionPatientList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [zone, setZone] = useState(null);

  const decode = useSelector((state) => state.auth.decodedToken);
  const { patientList, searchResults, loading } = useSelector(
    (state) => state.patients
  );

  const [query, setQuery] = useState("");
  const [currentDate, setCurrentDate] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedid, setSelecteid] = useState(null);
  const [patientClicked, setPatientClicked] = useState(false);
  const [searchedPatient, setSearchedPatient] = useState(null);
  const [zoneData, setZonedata] = useState(null);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setCurrentDate(selectedDate);
    dispatch(clearPatientList());
    dispatch(
      fetchPatientsByDate({
        doctorId: decode.doctor_id,
        date: selectedDate,
        timeZone: zone,
      })
    );
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      dispatch(
        searchPatients({ organizationId: decode.organization_id, query: value })
      );
    }
  };

  const handleShowEdit = (id) => {
    setSelecteid(id);
    setShow(true);
  };
  const closeEditModal = () => {
    setShow(false);
    setSelecteid(null);
  };

  useEffect(() => {
    if (!query) {
      dispatch(clearSearchResults());
    }
  }, [query, dispatch]);

  const handlePatientClick = (patient) => {
    // dispatch(addPatientToList(patient));
    setPatientClicked(!patientClicked);
    localStorage.setItem("sidePatient", JSON.stringify(patient));
    navigate(`/activity?id=${patient.Patient.patient_id}`);
    dispatch(clearSearchResults());
  };

  const loadPreviousDayData = () => {
    const inputDate = DateTime.fromISO(currentDate, { zone: zoneData });
    const previousDates = inputDate.minus({ days: 1 });
    const previousDate = previousDates.toISODate();
    setCurrentDate(previousDate);
    dispatch(clearPatientList());
    dispatch(
      fetchPatientsByDate({
        doctorId: decode?.doctor_id,
        date: previousDate,
        timeZone: zone,
      })
    );
  };

  const loadNextDayData = () => {
    const inputDate = DateTime.fromISO(currentDate, { zone: zoneData });
    const nextDates = inputDate.plus({ days: 1 }); // Increment by 1 day
    const nextDate = nextDates.toISODate(); // Convert to ISO date string
    setCurrentDate(nextDate);
    dispatch(clearPatientList());
    dispatch(
      fetchPatientsByDate({
        doctorId: decode?.doctor_id,
        date: nextDate,
        timeZone: zone,
      })
    );
  };

  useEffect(() => {
    const Tzone = sessionStorage.getItem("timeZone");
    if (Tzone) {
      setZonedata(Tzone);
      const timesetDate = getDateTimeWithOffset(Tzone);
      setCurrentDate(timesetDate);
      if (timesetDate) {
        const timezone = Tzone.replace("/", "_");
        setZone(timezone);
        dispatch(
          fetchPatientsByDate({
            doctorId: decode?.doctor_id,
            date: timesetDate,
            timeZone: timezone,
          })
        );
      }
    }
  }, [dispatch, decode?.doctor_id]);
  const handleNavigate = (patientID, status, interactionId) => {
    if (status === "6") {
      const changeStatus = {
        patient_id: patientID,
        interaction_status: "3",
      };
      dispatch(updatePatientStatus(changeStatus));
      localStorage.removeItem("interactionIdFirst");
      navigate(`/activity?id=${patientID}`);
    } else if (status === "99" || status === "100") {
      localStorage.setItem("interactionIdFirst", interactionId);
      navigate(`/activity?id=${patientID}`);
    } else {
      localStorage.removeItem("interactionIdFirst");
      navigate(`/activity?id=${patientID}`);
    }
  };

  useEffect(() => {
    setSearchedPatient(JSON.parse(localStorage.getItem("sidePatient")));
  }, [patientClicked]);

  return (
    <>
      <div className="relative">
        <div className="flex items-center justify-between bg-white rounded-md shadow-md px-2 mb-2">
          <input
            type="text"
            placeholder="Enter patient name"
            className="outline-none bg-transparent"
            value={query}
            onChange={handleSearch}
            style={{
              width: "100%",
              padding: "  10px",
              // marginBottom: "10px",
              // backgroundColor: "rgb(187 187 187 / 30%)",
            }}
          />
          {query && (
            <FaX
              className="!text-xs overflow-hidden"
              onClick={() => setQuery("")}
            />
          )}
        </div>
        <SideListInput>
          <FaCircleArrowLeft
            size={25}
            onClick={loadPreviousDayData}
            disabled={loading}
          />
          <input
            type="date"
            value={currentDate}
            onChange={handleDateChange}
            className="side-input-date"
          />
          <FaCircleArrowRight
            size={25}
            onClick={loadNextDayData}
            disabled={loading}
          />
        </SideListInput>
        {loading && <p>Loading...</p>}
        {query && (
          <ul class="bg-white absolute w-full top-12 rounded-md shadow-md left-0 pl-0 max-h-48 overflow-y-auto">
            {searchResults.map((patient) => (
              <li
                key={patient.Patient.patient_id}
                type="button"
                class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700 flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 shadow-sm"
                onClick={() => handlePatientClick(patient)}
              >
                {patient.Patient.first_name} {patient.Patient.last_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {query && searchedPatient ? (
        <>
          <div
            className={`flex items-center justify-between py-2 px-2 rounded-md cursor-pointer shadow-md text-sm ${
              window.location.pathname + window.location.search ===
              `/activity?id=${searchedPatient.Patient.patient_id}`
                ? "bg-gray-300"
                : ""
            } ${
              searchedPatient.interaction_status === "1"
                ? "bg-red-400 text-white"
                : searchedPatient.interaction_status === "0"
                ? "bg-red-400 text-white"
                : searchedPatient.interaction_status === "3"
                ? ""
                : searchedPatient.interaction_status === "4"
                ? "bg-yellow-300 text-white"
                : searchedPatient.interaction_status === "5"
                ? "bg-yellow-500 text-white"
                : searchedPatient.interaction_status === "6"
                ? "bg-green-500 text-white"
                : "bg-red-500 text-white"
            }`}
            onClick={() => {
              handleNavigate(
                searchedPatient.Patient.patient_id,
                searchedPatient.interaction_status
              );
            }}
          >
            <div className="flex items-center gap-3">
              <div className="font-semibold">
                {searchedPatient?.Patient?.first_name}{" "}
                {searchedPatient?.Patient?.last_name}
              </div>
            </div>
            <div className="flex">
              {searchedPatient?.token_number && (
                <div className="rounded-full px-[6px] py-0 text-sm font-medium bg-white text-red-500">
                  <span>{searchedPatient?.token_number}</span>
                </div>
              )}
              <button
                style={{ padding: "3px" }}
                type="button"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation click
                  handleShowEdit(searchedPatient?.Patient?.patient_id);
                }}
              >
                <BsThreeDotsVertical />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          {patientList.length > 0 ? (
            <SideInteractionPatientContainer className="p-2">
              {patientList.map((patient) => (
                <div
                  key={patient.Patient.patient_id}
                  className={`flex items-center justify-between py-2 px-2 rounded-md cursor-pointer shadow-md text-sm ${
                    window.location.pathname + window.location.search ===
                    `/activity?id=${patient.Patient.patient_id}`
                      ? "bg-gray-500"
                      : ""
                  } ${
                    patient?.interaction_status === "1"
                      ? "!bg-red-400 !text-white"
                      : patient?.interaction_status === "0"
                      ? "!bg-red-400 !text-white"
                      : patient?.interaction_status === "3"
                      ? ""
                      : patient?.interaction_status === "2"
                      ? "!bg-yellow-300 !text-white"
                      : patient?.interaction_status === "4"
                      ? "!bg-yellow-300 !text-white"
                      : patient?.interaction_status === "5"
                      ? "!bg-yellow-500 !text-white"
                      : patient?.interaction_status === "6"
                      ? "!bg-green-500 !text-white"
                      : "!bg-orange-400 !text-white"
                  }`}
                  onClick={
                    () => {
                      handleNavigate(
                        patient.Patient.patient_id,
                        patient.interaction_status,
                        patient?.interaction_id
                      );
                    }
                    // navigate(`/activity?id=${patient.Patient.patient_id}`)
                  }
                >
                  <div className="flex items-center gap-3">
                    {/* <div className="rounded-full bg-gray-300 w-8 h-8 flex items-center justify-center">
                  <img src={`/user.png`} width={14} alt="Patient avatar" />
                </div> */}
                    <div className="font-semibold">
                      {patient?.Patient?.first_name}{" "}
                      {patient?.Patient?.last_name}
                    </div>
                  </div>
                  <div className="flex">
                    {patient?.token_number && (
                      <div className="rounded-full px-[6px] py-0 text-sm font-medium bg-white text-red-500">
                        <span>{patient?.token_number}</span>
                      </div>
                    )}
                    <button
                      style={{ padding: "3px" }}
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent navigation click
                        handleShowEdit(patient?.Patient?.patient_id);
                      }}
                    >
                      <BsThreeDotsVertical />
                    </button>
                  </div>
                </div>
              ))}
              {/* <button
                onClick={loadPreviousDayData}
                disabled={loading}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Previous Day
              </button> */}
              {show ? (
                <USpatientEdit
                  show={show}
                  handleClose={() => closeEditModal()}
                  patientID={selectedid}
                />
              ) : (
                ""
              )}
            </SideInteractionPatientContainer>
          ) : (
            <div className="text-center bg-gray-100 p-6 rounded-lg shadow-lg">
              <p className="text-gray-500 text-lg font-medium">No data</p>
              {/* <button
                onClick={loadPreviousDayData}
                disabled={loading}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md shadow-md disabled:opacity-50"
              >
                Previous Day
              </button> */}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default InteractionPatientList;
