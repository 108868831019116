import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import { SignInButton } from "../Login-page/Components/SignupBtton";
import { SocialMedia } from "../ThemeStyle/RegisterStyle";
import { loginRequest } from "../../authConfig";
import { configJson } from "../../Constent/config";
import { useForm } from "react-hook-form";
import personicon from "../../assets/signup/person.png";
import aticon from "../../assets/signup/at.png";
import keyicon from "../../assets/signup/key.png";
import orgicon from "../../assets/signup/org.png";
import phoneicon from "../../assets/signup/phone.png";
import eyeicon from "../../assets/signup/eye.png";
import ratingicon from "../../assets/signup/rating.png";
import logo from "../../assets/signup/logo.png";
import {
  SignForm,
  Signformside,
  SignTextContainer,
  SignTopcontainer,
  SignwithoutForm,
  TermsText,
} from "./SignupStyle";
import MobileTopBar from "../Home/Components/Topbar/MobileTopBar";
import eyeClose from "../../assets/signup/eyeclose.png";
import { toast } from "react-toastify";
// import { UserContext } from "../context/UserContext";

const RegistrationForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const [, setToken] = useContext(UserContext);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password_hash: "",
    name: "",
    country: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [microsoftData, setMicrosoftData] = useState(null);
  const [googleData, setGoogleData] = useState(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { instance } = useMsal();
  const signWidth =
    window.innerWidth < 767
      ? "290px"
      : window.innerWidth >= 768 && window.innerWidth <= 900
      ? "275px"
      : "400px";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //password show hide
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signupOrganization = async (organizationData) => {
    const url = `${configJson.backend_URL}/superadmin/OrganizationSignup`; // Replace with your actual API endpoint URL
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(organizationData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create organization");
      }

      const responseData = await response.json();
      return {
        success: true,
        datas: responseData,
      };
    } catch (error) {
      console.error("Error during organization signup:", error.message);
      return {
        success: false,
        message: error.message,
      };
    }
  };

  //data selection for backend
  const onSubmit = async (data) => {
    try {
      if (Boolean(!googleData) && Boolean(!microsoftData)) {
        data = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password_hash: data.password_hash,
          country: data.country,
          name: data.name,
          sign_type: "formdata",
        };
      }
      if (microsoftData) {
        data = {
          first_name: microsoftData["name"],
          email: microsoftData["username"],
          name: data.name,
          country: data.country,
          last_name: null,
          password_hash:null,
          sign_type: "microsoft",
        };
      }
      if (googleData) {
        data = {
          first_name: googleData["given_name"],
          last_name: null,
          password_hash: null,
          email: googleData["email"],
          name: data.name,
          country: data.country,
          sign_type: "google",
        };
      }
      console.log(data);
      const result = await signupOrganization(data);
      console.log(result);
      if (result.success) {
        if (result?.datas?.status_back) {
          navigate("/login");
        } else {
          toast.error(result?.datas?.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //show Terms and Conditions
  const openTermsModal = () => {
    setShowTermsModal(true);
  };

  //Terms and Conditions checkbox
  const handleCheckboxChange = () => {
    setAgreeChecked(!agreeChecked);
    setShowTermsModal(false);
  };

  //sign with Microsoft
  const handleMicrosoftLogin = async (loginType) => {
    if (loginType === "popup") {
      try {
        const response = await instance.loginPopup(loginRequest);
        console.log("microsoft working");
        setMicrosoftData(response.account);
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  //Sign with google
  function handleCallbackResponse(response) {
    let userObject = jwtDecode(response.credential);
    console.log("google working");
    setGoogleData(userObject);
    // toast.info("Please fill the company name");
  }
  function signInWithGoogle() {
    /*global google*/
    google.accounts.id.initialize({
      client_id:
        "691495889125-m6g1jm2greku6k93pu41gf5fiki54tom.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    // google.accounts.id.renderButton(document.getElementById("signInDiv"), {
    //   theme: "outline",
    //   size: "large",
    //   width: signWidth,
    //   logo_alignment: "center",
    //   shape: "rectangular",
    // });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
      width: signWidth,
      type: "icon", // Set to "icon" for the G icon
      logo_alignment: "center",
      shape: "rectangular", // Optionally set the shape to circle for better icon appearance
    });
    // Display the One Tap prompt
    google.accounts.id.prompt();
  }
  useEffect(() => {
    signInWithGoogle();
  }, []);

  //update Size
  const updateSize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };
  useEffect(() => {
    updateSize();

    // Add resize event listener
    window.addEventListener("resize", updateSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center  min-vh-100"
      ref={containerRef}
    >
      <SignTopcontainer className="bg-white  rounded shadow-sm w-100 ml-50">
        <Container
          fluid
          className="d-flex justify-content-center align-items-center "
        >
          <Row
            style={{
              maxWidth: "1200px",
              marginTop: "15px",
              marginBottom: "10px",
            }}
          >
            {containerWidth > 767 ? (
              <Col className="signup-content pb-3">
                <SignTextContainer>
                  <h3>Free Yourself For Better Things</h3>
                  <p>
                    Medscribe leverages cutting-edge AI to transform
                    dentist-patient interactions and key data into comprehensive
                    SOAP Notes, seamlessly integrated with your Practice
                    Management Software.
                  </p>
                  <p>
                    SOAP Notes are the industry standard for documenting patient
                    encounters in dentistry. They ensure clear, concise, and
                    organized communication, fostering better patient care and
                    improved practice efficiency. Medscribe automates this
                    process, freeing you to focus on what matters most – your
                    patients.
                  </p>
                  <div className="rating-icon-container">
                    <img
                      src={ratingicon}
                      title=""
                      alt=""
                      className="rating-icon"
                    />
                  </div>
                </SignTextContainer>
              </Col>
            ) : (
              ""
            )}
            <Col className={containerWidth > 767 ? "myborder" : "myheight"}>
              <Signformside>
                <div className="text-center mb-4 main-form-container">
                  <h4 className="font-weight-bold signup-text">Sign Up</h4>
                </div>

                <SignForm>
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="custom-form"
                    autocomplete="off"
                  >
                    {Boolean(!googleData) && Boolean(!microsoftData) && (
                      <>
                        <Form.Group controlId="formFirstName">
                          <Form.Label>First Name </Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            onChange={handleChange}
                            isInvalid={!!errors.first_name}
                            {...register("first_name", {
                              required: "First name is required",
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message:
                                  "First name should not any special character.",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.first_name && errors.first_name.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                          <Form.Label>Last Name </Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={handleChange}
                            isInvalid={!!errors.last_name}
                            {...register("last_name", {
                              required: "Last name is required",
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message:
                                  "First name should not any special character.",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.last_name && errors.last_name.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="medscribe@gmail.com"
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          controlId="formPassword_hash"
                          className="sh-pass"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password_hash"
                            placeholder="****************"
                            onChange={handleChange}
                            isInvalid={!!errors.password_hash}
                            {...register("password_hash", {
                              required: "Password is required",
                              minLength: {
                                value: 6,
                                message:
                                  "Password must be at least 6 characters long",
                              },
                            })}
                          />
                          <span className="show-pass">
                            {showPassword ? (
                              <img
                                onClick={togglePasswordVisibility}
                                src={eyeClose}
                                title=""
                                alt=""
                                className="sign-input-image-close"
                              />
                            ) : (
                              <img
                                onClick={togglePasswordVisibility}
                                src={eyeicon}
                                title=""
                                alt=""
                                className="sign-input-image"
                              />
                            )}
                          </span>

                          <Form.Control.Feedback type="invalid">
                            {errors.password_hash &&
                              errors.password_hash.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group controlId="formPhone">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="+10000000000"
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                            {...register("phone", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Invalid phone number",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone && errors.phone.message}
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group controlId="formname">
                          <Form.Label>Organization Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Organization Name"
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            {...register("name", {
                              required: "Organization name is required",
                              pattern: {
                                value: /^[a-zA-Z0-9\s]+$/,
                                message:
                                  "First name should not any special character.",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name && errors.name.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formcountry">
  <Form.Label>Country Name</Form.Label>
  <Form.Select
    name="country"
    onChange={handleChange}
    isInvalid={!!errors.country}
    {...register("country", {
      required: "Country name is required",
    })}
  >
    <option value="">Select Country</option>
    <option value="India">India</option>
    <option value="United States of America">United States of America</option>
  </Form.Select>
  <Form.Control.Feedback type="invalid">
    {errors.country && errors.country.message}
  </Form.Control.Feedback>
</Form.Group>


                        <Form.Group className="mb-1">
                          <Form.Check
                            type="checkbox"
                            label="I agree to the Terms and Conditions"
                            checked={agreeChecked}
                            isInvalid={!!errors.terms}
                            {...register("terms", {
                              required:
                                "You must agree to the Terms and Conditions",
                            })}
                            onClick={openTermsModal}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.terms && errors.terms.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                    {Boolean(googleData) || Boolean(microsoftData) ? (
                      <SignwithoutForm>
                        <p className="text-center">
                          Get started with Medscribe
                        </p>
                        <Form.Group controlId="formname">
                          <Form.Label>Organization Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Organization Name"
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            {...register("name", {
                              required: "Organization name is required",
                              pattern: {
                                value: /^[a-zA-Z0-9\s]+$/,
                                message:
                                  "First name should not any special character.",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name && errors.name.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formcountry">
                          <Form.Label>Country Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="country"
                            placeholder="Country Name"
                            onChange={handleChange}
                            isInvalid={!!errors.country}
                            {...register("country", {
                              required: "country name is required",
                              pattern: {
                                value: /^[a-zA-Z0-9\s]+$/,
                                message:
                                  "First name should not any special character.",
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.country && errors.country.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            label="I agree to the terms and Services"
                            checked={agreeChecked}
                            isInvalid={!!errors.terms}
                            {...register("terms", {
                              required:
                                "You must agree to the terms and Services",
                            })}
                            onClick={openTermsModal}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.terms && errors.terms.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </SignwithoutForm>
                    ) : (
                      ""
                    )}
                    {Boolean(!googleData) && Boolean(!microsoftData) && (
                     <SocialMedia>
                     <div className="google-button">
                       <div className="g-signin pb-2">
                         <div id="signInDiv" className="google-icon"></div>
                       </div>
                     </div>
                     {/* <div className="g-signin pb-2">
                       <div id="signInDiv" className="google-icon">
                         <img
                           src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                           alt="Google Icon"
                           className="google-logo"
                         />
                       </div>
                     </div> */}
                     <div className="text-center ms-signup">
                       <SignInButton handleLogin={handleMicrosoftLogin} />
                     </div>
                   </SocialMedia>
                    )}
                    <div className="btn-log">
                      <Button
                        type="submit"
                        className="reg-sub-btn"
                        disabled={!agreeChecked}
                      >
                        Register
                      </Button>
                    </div>
                  </Form>
                </SignForm>
                <div className="text-center mt-3">
                  Already have an account?
                  <Link to={"/login"} className="text-primary">
                    Login
                  </Link>
                </div>
              </Signformside>
            </Col>
          </Row>
        </Container>
      </SignTopcontainer>

      {/* Terms and Conditions Modal */}
      <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Privacy Policy</h3>
          <p>
            This medical application facilitates the transcription of
            interactions between doctors and patients. Patient data, audio
            recordings, and transcriptions are processed with the utmost
            confidentiality in compliance with applicable privacy regulations.
          </p>
          <p>
            We ensure that all audio-to-text conversions and patient information
            are securely stored and managed in a safe environment. The data is
            used solely for medical purposes and will not be shared without
            explicit consent.
          </p>

          <h3>Terms of Service</h3>
          <p>
            By using this platform, you agree to ensure that all medical data,
            including patient conversations, is recorded and managed
            responsibly. Any unauthorized sharing or distribution of patient
            information is strictly prohibited and may result in legal
            consequences.
          </p>
          <p>
            The service provided here is intended to aid doctors in managing
            their interactions and patient data effectively. Any misuse of the
            platform, especially in violation of medical ethics and privacy
            regulations, will result in termination of access to the service.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="I agree to the terms and conditions"
              checked={agreeChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RegistrationForm;
