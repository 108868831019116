import React, { useEffect, useState } from "react";
import { ConsentContainer } from "../Engine-Page/ConsentRequestPage/ConsentRequestStyle";
import {
  AddNewDoctor,
  Okbutton,
  PhoneNumberContainer,
} from "../Doctor-Page/Doctorstyle";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import { configJson } from "../../Constent/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { v4 as uuidv4 } from "uuid";
import { convertToUTC } from "../../helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function CreateNewPatient({ show, handleClose, tokendata, tokensStatus }) {
  const [load, setLoad] = useState(false);
  const [decoded, setDecoded] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("success");
  const [phone, setPhone] = useState("+");
  const [phoneError, setPhoneError] = useState(false);

  // Function to handle input blur (when field loses focus)
  const handleBlur = async (e) => {
    const { name, value } = e.target;
    if (value.trim().length > 0) {
      setLoad(true);
      try {
        const response = await fetch(
          `${configJson.backend_URL}/patient/get/${value}/${decoded.organization_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.patient) {
            setValue("hospital_patient_id", data.patient.hospital_patient_id);
            setValue("first_name", data.patient.first_name);
            setValue("last_name", data.patient.last_name);
            setValue("date_of_birth", data.patient.date_of_birth || "");
            setPhone(`+${data?.patient?.phone_number || ""}`);
          }
        } else {
          const errorData = await response.json();
          console.error("Error fetching patient:", errorData.message);
          setMessage(errorData.message || "Error fetching patient data.");
          setVariant("danger");
        }
      } catch (error) {
        console.error("Error during fetch operation:", error);
        setMessage("Error fetching patient data.");
        setVariant("danger");
      } finally {
        setLoad(false);
      }
    }
  };

  const onSubmit = async (data) => {
    setLoad(true);

    // Validate phone number
    if (phone.length < 10) {
      setPhoneError(true);
      setLoad(false);
      return;
    } else {
      setPhoneError(false);
    }

    // Process optional fields
    const processedData = {
      ...data,
      date_of_birth: data.date_of_birth ? data.date_of_birth : null,
      phone_number: phone ? phone : null,
      age: data.age ? parseFloat(data.age) : null, // Convert age to float
      gender: data.gender || null, // Include gender if provided
    };

    const patientData = {
      ...processedData,
      organization_id: decoded.organization_id,
      doctor_id: tokendata.doctor_id,
      speciality_id: tokendata.speciality_id,
      interaction_status: "99",
      interaction_date: convertToUTC(),
    };

    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/patientIntraction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(patientData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMessage(result.message);
        setVariant("success");
        toast.success("Token Created");
        // Navigate or perform any additional actions here
        handleClose();
      } else {
        setMessage(result.message || "Failed to add patient.");
        setVariant("danger");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error creating patient: " + error.message);
      setVariant("danger");
    } finally {
      setLoad(false);
    }
  };

  const removeHospital = () => {
    setValue("hospital_patient_id", "");
  };

  // Initialize form fields on component mount
  useEffect(() => {
    setValue("hospital_patient_id", uuidv4().split("-")[0]);
    setValue("interaction_date", convertToUTC());
  }, [setValue]);

  // Decode token and set decoded data
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      try {
        const decodedData = jwtDecode(token);
        if (decodedData) {
          setDecoded(decodedData);
        }
      } catch (error) {
        console.error("Invalid token:", error);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <AddNewDoctor>
        <Modal.Body>
          <ConsentContainer>
            <div className="header-text-part">
              <h2 className="header-text">Patient Details</h2>
              <h3>SPECIALITY: {tokendata.speciality_name}</h3>
            </div>
            {message && <Alert variant={variant}>{message}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Hospital Patient ID */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Hospital Patient ID</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="hospital_patient_id"
                    {...register("hospital_patient_id", {
                      required: "Hospital Patient ID is required",
                    })}
                    onBlur={handleBlur}
                    onClick={removeHospital}
                    isInvalid={!!errors.hospital_patient_id}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.hospital_patient_id?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* First Name */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="first_name"
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                    isInvalid={!!errors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Last Name */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="last_name"
                    {...register("last_name", {
                      required: "Last Name is required",
                    })}
                    isInvalid={!!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {decoded.organization_id != configJson.ABC && (
                <Form.Group as={Row} className="mb-3">
                  <Col sm={4}>
                    <Form.Label>Date of Birth</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      name="date_of_birth"
                      {...register("date_of_birth")}
                      isInvalid={!!errors.date_of_birth}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date_of_birth?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}

              {decoded.organization_id == configJson.ABC && (
                <>
                  {/* Age Field */}
                  <Form.Group as={Row} className="mb-3">
                    <Col sm={4}>
                      <Form.Label>Age</Form.Label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        type="number"
                        step="0.1" // Supports decimal values like 0.3
                        name="age"
                        {...register("age", {
                          min: {
                            value: 0,
                            message: "Age must be a positive number",
                          },
                          max: {
                            value: 150,
                            message: "Age must be less than 150",
                          },
                        })}
                        min="0" // Prevents negative values
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault(); // Prevent increment/decrement using arrow keys
                          }
                        }}
                        onInput={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0; // Reset to 0 if negative
                          }
                        }}
                        isInvalid={!!errors.age}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.age?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* Gender Field */}
                  <Form.Group as={Row} className="mb-3 align-items-center">
                    <Col sm={4}>
                      <Form.Label>Gender</Form.Label>
                    </Col>
                    <Col sm={8}>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="radio"
                          label="Male"
                          value="Male"
                          {...register("gender", {
                            required: "Please select a gender", // Validation message
                          })}
                          name="gender"
                          className="me-3"
                        />
                        <Form.Check
                          type="radio"
                          label="Female"
                          value="Female"
                          {...register("gender", {
                            required: "Please select a gender", // Validation message
                          })}
                          name="gender"
                          className="me-3"
                        />
                        <Form.Check
                          type="radio"
                          label="Others"
                          value="Others"
                          {...register("gender", {
                            required: "Please select a gender", // Validation message
                          })}
                          name="gender"
                        />
                      </div>
                      {errors.gender && (
                        <p style={{ color: "red", marginTop: "5px" }}>
                          {errors.gender.message}
                        </p>
                      )}
                    </Col>
                  </Form.Group>
                </>
              )}

              {/* Phone Number */}
              <PhoneNumberContainer>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={4}>
                    <Form.Label>Phone Number</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <PhoneInput
                      international
                      country="in"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: false,
                      }}
                      isValid={(value, country) => {
                        if (value.length === 14) {
                          return "Invalid value: " + value;
                        } else {
                          return true;
                        }
                      }}
                    />
                    {phoneError && (
                      <p style={{ color: "red" }}>
                        Enter a valid phone number.
                      </p>
                    )}
                  </Col>
                </Form.Group>
              </PhoneNumberContainer>

              {/* Token Number */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Token Number</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="token_number"
                    {...register("token_number", {
                      required: "Token Number is required",
                    })}
                    isInvalid={!!errors.token_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_number?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Okbutton>
                <Button
                  type="button"
                  className="reg-sub-btn"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {load ? (
                  <Button className="reg-sub-btn" disabled>
                    Loading...
                  </Button>
                ) : (
                  <Button type="submit" className="reg-sub-btn">
                    OK
                  </Button>
                )}
              </Okbutton>
            </Form>
          </ConsentContainer>
        </Modal.Body>
      </AddNewDoctor>
    </Modal>
  );
}

export default CreateNewPatient;
