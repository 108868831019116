import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import { configJson } from "../../Constent/config";
import { Alert, Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import CreateNewPatient from "./CreateNewPatient";
import "../../App.css";
import { FaCamera } from "react-icons/fa6";
import { Modal } from "antd";
import Webcam from "react-webcam";
import axios from 'axios'
import { convertToKolkataTime, convertToUTC } from "../../helpers";

function CreateNewToken() {
  const navigate = useNavigate();
  // const [users, setUsers] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 9;
  const [show, setShow] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [scanModal, setScanModal] = useState(false);
  const [decodedValue, setDecodedValue] = useState([]);

  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  // Capitalize helper function
  const capitalize = (word) => {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };
  const fetchSpecialtiesData = async (organization_id, doctorId) => {
    setLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${configJson.backend_URL}/specialtysetup/org/${organization_id}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch doctors");
      }
      const data = await response.json();
      console.log(45, data);
      setSpecialties(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from backend using organization_id
  // const fetchData = async (organization_id, doctorId) => {
  //   setLoading(true);
  //   try {
  //     const requestOptions = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const response = await fetch(
  //       `${configJson.backend_URL}/doctor/${organization_id}`,
  //       requestOptions
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch doctors");
  //     }
  //     const data = await response.json();
  //     // console.log(45,data)
  //     if (doctorId !== null) {
  //       const userdata = data.filter((doctor) => {
  //         return doctor.doctor_id === doctorId;
  //       });
  //       if (userdata?.length > 0) {
  //         setSelectedUser(userdata[0]);
  //         setShow(true);
  //         setUsers(userdata);
  //       } else {
  //         setUsers(data);
  //       }
  //     } else {
  //       setUsers(data);
  //     }
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Effect to handle token and organization_id fetching
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      // fetchData(decoded.organization_id);
      setDecodedValue(decoded);
      if (decoded.role === "doctor") {
        fetchSpecialtiesData(decoded.organization_id, decoded.doctor_id);
        // fetchData(decoded.organization_id, decoded.doctor_id);
      } else {
        fetchSpecialtiesData(decoded.organization_id, null);
        // fetchData(decoded.organization_id, null);
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // Pagination logic
  const totalUsers = specialties.length;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const indexOfFirstUser = (currentPage - 1) * usersPerPage;
  const currentUsers = specialties.slice(
    indexOfFirstUser,
    indexOfFirstUser + usersPerPage
  );

  // Handle user click to open the modal
  // const handleUserClick = (user) => {
  //   if (user) {
  //     setSelectedUser(user);
  //     setShow(true);
  //   }
  // };

  function getFormattedDateTimeUTC() {
    const dateTime = new Date();
    const formattedDate = convertToUTC();
    const d = convertToKolkataTime(formattedDate);
    const timee = d.replace(/[-:]/g, "_").replace(", ", "_");

    const formattedDateTime = [
        String(dateTime.getUTCMonth() + 1).padStart(2, '0'),  // Month
        String(dateTime.getUTCDate()).padStart(2, '0'),       // Day
        String(dateTime.getUTCFullYear()).slice(-2),          // Year
        String(dateTime.getUTCHours()).padStart(2, '0'),      // Hours
        String(dateTime.getUTCMinutes()).padStart(2, '0'),    // Minutes
        String(dateTime.getUTCSeconds()).padStart(2, '0')     // Seconds
    ].join('_');

    return timee;
}

  // Handle Speciality click to open the modal
  const handleSpecialityClick = (speciality) => {
    if (speciality) {
      console.log(speciality);
      setSelectedSpecialty(speciality);
      setShow(true);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Display error message if there was an error during fetch
  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  const handleSubmitDocs = async () => {
    if (!file) {
      console.error("File is empty or invalid!");
      return;
    }
  
    setLoading(true);
    const formData = new FormData();
    const dateTime = getFormattedDateTimeUTC();
  
    try {
      const fileExtension = file.name.split(".").pop();
      const newFileName = `patientData/${dateTime}.${fileExtension}`;
      formData.append("orgId", decodedValue.organization_id);
      formData.append("file", file);
      formData.append("file_name", newFileName);
      const result = await axios.post(
        `${configJson.backend_URL}/interaction/createAppointment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log("API Response:", result.data);
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
      setScanModal(false);
      setFile(null)
    }
  };
  
  
  return (
    // <Sidebar setuser={true}>
      <TokenPageContainer>
        {show && (
          <CreateNewPatient
            show={show}
            handleClose={handleClose}
            tokendata={selectedSpecialty}
            tokensStatus={() => {}}
          />
        )}
        <ScanModal
          scanModal={scanModal}
          setScanModal={setScanModal}
          file={file}
          setFile={setFile}
          handleSubmitDocs={handleSubmitDocs}
        />
        {/* <div className="heading-text">
          <h4 className="gradient-text">Create Appointments</h4>
          <span className="heading-text-line"></span>
        </div> */}
        <div className="flex items-center justify-between px-4">
          <h4 className="gradient-text">Create Appointments</h4>
          <button
            className="rounded-3xl text-white py-2 px-3 flex items-center gap-2"
            style={{
              background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
            }}
            onClick={() => setScanModal(true)}
          >
            <FaCamera />
            <div>Scan</div>
          </button>
        </div>
        <div className="border-t-2 mt-2 border-black"></div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {specialties.length === 0 ? (
              <Alert variant="info" className="show-hide">
                No Specialties found.
              </Alert>
            ) : (
              <div className="token-buttons-pagination-container">
                <div className="token-buttons-container">
                  {currentUsers.map((user, index) => (
                    <button
                      key={index} // Use a unique identifier like user.id
                      onClick={() => handleSpecialityClick(user)}
                      className="token-button"
                    >
                      {capitalize(user.speciality_name)}
                    </button>
                  ))}
                </div>
                <div className="pagination">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                  >
                    Previous
                  </button>

                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => paginate(i + 1)}
                      className={`pagination-button ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}

                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </TokenPageContainer>
    // </Sidebar>
  );
}

export default CreateNewToken;

const ScanModal = ({ scanModal, setScanModal, file, setFile, handleSubmitDocs }) => {
  const [openCam, setOpenCam] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const cameraRef = useRef(null);

  const handleCapture = () => {
    if (file !== null) {
      setFile(null);
    }

    const screenshot = cameraRef?.current?.getScreenshot();

    if (screenshot) {
      // Convert base64 data URL to a Blob
      const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(base64.split(",")[1]);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      };

      // Convert base64 to Blob
      const blob = base64ToBlob(screenshot, "image/jpeg");

      // Create a File object with the new name
      const fileName = `${new Date().toISOString()}.jpg`;
      const renamedFile = new File([blob], fileName, { type: "image/jpeg" });

      // Set the renamed file
      setFile(renamedFile);
    }
  };

  const handleSwitchCamera = () => {
    setIsFrontCamera((prev) => !prev);
  };

  useEffect(() => {
    if (!scanModal) {
      setOpenCam(false);
      setFile(null); // Clear file on modal close if needed
    }
  }, [scanModal]);

  useEffect(() => {
    if (!openCam && cameraRef.current) {
      const stream = cameraRef.current.video.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        cameraRef.current.video.srcObject = null; // Clear video source
      }
    }
  }, [openCam, scanModal]);

  return (
    <Modal
      open={scanModal}
      onCancel={() => setScanModal(false)}
      onClose={() => setScanModal(false)}
      className="rounded-lg shadow-lg w-11/12 max-w-lg mx-auto"
      onOk={handleSubmitDocs}
    >
      <div className="text-center">
        {openCam ? (
          <div className="flex flex-col items-center">
            {file == null ? (
              <>
                <Webcam
                  audio={false}
                  ref={cameraRef}
                  screenshotFormat="image/jpeg"
                  className="rounded-lg border border-gray-300 shadow-sm mb-4"
                  videoConstraints={{
                    facingMode: isFrontCamera
                      ? "user"
                      : { exact: "environment" },
                  }}
                />
                <button
                  onClick={handleSwitchCamera}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600"
                >
                  Switch Camera
                </button>
              </>
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt="Captured"
                className="rounded-lg shadow-md mb-4"
              />
            )}
            <button
              onClick={handleCapture}
              className="bg-green-500 text-white py-2 px-6 rounded-lg shadow hover:bg-green-600 mt-4"
            >
              {file == null ? "Capture" : "Retake"}
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Upload File:
              <input
                type="file"
                className="mt-2 block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                onChange={(e) => setFile(e.target.files[0])}
                multiple={false}
              />
            </label>
          </div>
        )}
        <button
          onClick={() => {
            setOpenCam(!openCam);
            setFile(null);
          }}
          className="text-blue-500 underline mt-4"
        >
          {openCam ? "Or Upload File" : "Or Take Photo"}
        </button>
      </div>
    </Modal>
  );
};
