import "bootstrap/dist/css/bootstrap.min.css";
import LoginUser from "./pages/Login-page/LoginUser";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Route, Routes,Outlet  } from "react-router-dom";
import Doctorlist from "./pages/Doctor-Page/Doctorlistpage";
import MainEngine from "./pages/Engine-Page/mainEngine/MainEngine";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
// import PatientList from "./pages/Doctor-Page/Components/PatientList";
// import SoapNotes from "./pages/Engine-Page/mainEngine/Components/SoapNotes";
// import Visithistory from "./pages/Doctor-Page/Components/ConversationHistory";
import Token from "./pages/Doctor-Page/Components/Token";
import SuperAdminlogin from "./pages/Super-admin/SuperadminLogin";
import SuperAdminDashboard from "./pages/Super-admin/SuperAdminDashboard";
import SuperadminRegister from "./pages/Super-admin/SuperAdminRegistration";
import Organisationtesting from "./pages/Super-admin/Organisationtesting";
import OrganizationsTable from "./pages/Super-admin/OrganizationList";
import SpecialtiesTable from "./pages/Super-admin/Components/SpecialtiesTable";
import AddSpecialtyForm from "./pages/Super-admin/Components/AddSpecialtyForm";
import AddPatient from "./pages/Patient-page/AddPatient";
import InteractionTable from "./pages/Doctor-Page/Components/InteractionList";
// import InteractionDetail from "./pages/Interaction-Detail-Page/InteractionDetail";
import SoapnotesDisplay from "./pages/Soap-notes/SoapnotesDisplay";
import InteractionPatient from "./pages/Patient-page/InteractionPatient";
import CreateNewToken from "./pages/FrontOffice/CreateNewToken";
import PatientListedit from "./pages/FrontOffice/PatientListedit";
// import MyActivity from "./pages/Interaction-Detail-Page/MyActivity";
import CreateAssessment from "./pages/AssessmentTemplate/AssessmentPage";
import TechActivity from "./pages/FrontOffice/TechActivity";
import SidePatients from "./pages/Interaction-Detail-Page/SidePatients";
// import DoctorFeed from "./pages/Doctor-Page/Components/DoctorFeed/DoctorFeed";
import FrontActivity from "./pages/FrontOffice/FrontActivity";
import AdminPage from "./pages/AdminPage/AdminPage";
import Settings from "./pages/SettingsPage/SettingsPage";
import Consent from "./pages/Patient-page/Patientpage/consernform";
import Consentrequestform from "./pages/Doctor-Page/Components/ConsentRequestPage/consentrequestform";
import BillingDefaultTemplate from "./pages/Billing/BillingDefaultTemplate";
import KollaConnect from "./pages/kolla/kollaToken";
import AddRole from "./pages/AdminPage/Components/AddRole";
import AdminActivity from "./pages/AdminPage/Components/AdminActivity";
import Analytics from "./pages/Interaction-Detail-Page/Analytics";
import Registration from "./pages/registration/Registration";
import FetchInvoiceNinjaClient from "./pages/Billing/Components/FetchInvoiceClient";
import OrganizationDetails from "./pages/AdminPage/Components/OrganizationDetails";
// import UserProfile from "./pages/AdminPage/Components/UserProfile";
// import UserInfo from "./pages/AdminPage/Components/UserInfo";
import ProfileUpdate from "./pages/AdminPage/Components/ProfileUpdate";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "./redux/slices/authSlice";
import { setOrgLogo, setTimeZone } from "./redux/slices/settingsSlice";
import Sidebar from "./pages/Home/Components/Sidebar/Sidebar";
import RegistrationForm from "./pages/registration/RegistrationForm";
import USdoctor from "./pages/Doctor-Page/Components/USdoctor";
// import AppT from "./pages/AdminPage/Components/T";
import MedicineCard from "./pages/Templates/MedicineCard";
import DischargeSummary from "./pages/Templates/DischargeSummary";
import DischargeCheckList from "./pages/Templates/DischargeCheckList";









const msalInstance = new PublicClientApplication(msalConfig);

// Routers
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken"); // Assuming the token is stored in localStorage
    if (token) {
      dispatch(setToken(token)); // Decode and store the token
    }
    const orgLogo = sessionStorage.getItem("orglogo");
    const timeZone = sessionStorage.getItem("timeZone");

    if (orgLogo) {
      dispatch(setOrgLogo(orgLogo));
    }

    dispatch(setTimeZone(timeZone));
  }, [dispatch]);
  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/kolla" element={<KollaConnect />} />
            <Route path="/login" element={<LoginUser />} />
            <Route path="/signup" element={<RegistrationForm/>}/>
    
            {/* <Route path="/memberlist" element={<Doctorlist />} /> */}

            {/* <Route path="/patientlist" element={<PatientList />} /> */}
            {/* <Route path="/visithistory" element={<Visithistory />} /> */}
            {/* <Route path="/soapnotes" element={<SoapNotes />} /> */}

            <Route path="/superadminlogin" element={<SuperAdminlogin />} />
            <Route
              path="/superadmindashboard"
              element={<SuperAdminDashboard />}
            />
            <Route
              path="/superadminregister"
              element={<SuperadminRegister />}
            />
            <Route
              path="/organizationtesting"
              element={<Organisationtesting />}
            />
            <Route path="/organizationlist" element={<OrganizationsTable />} />
            <Route
              path="/interactionPatient"
              element={<InteractionPatient />}
            />
            <Route path="/specialties" element={<AddSpecialtyForm />} />
            <Route path="/specialtieslist" element={<SpecialtiesTable />} />
            <Route path="/addpatient" element={<AddPatient />} />
            <Route path="/interactionlist" element={<InteractionTable />} />
            {/* <Route path="/interactiondetail" element={<InteractionDetail />} /> */}
            <Route path="/soapnotesdetail" element={<SoapnotesDisplay />} />

            <Route path="/analytics" element={<Analytics />} />
            <Route path="/createassessment" element={<CreateAssessment />} />

            <Route path="/tech-activity" element={<TechActivity />} />

            {/* <Route path="/doctorfeed" element={<DoctorFeed/>}/> */}

            {/* <Route path="/doctor_consent" element={<Consentrequestform />} /> */}
            <Route path="/consent" element={<Consent />} />
            <Route
              path="/billingdefault"
              element={<BillingDefaultTemplate />}
            />

            <Route path="/registration" element={<Registration />} />
            <Route path="/fecthinvoice" element={<FetchInvoiceNinjaClient />} />
            <Route path="/Registration" element={<Registration/>}/>
          
          {/* <Sidebar setuser={true}>
            <Routes> */}
          <Route element={<MainLayout />}>
            <Route path="/doctor_consent" element={<Consentrequestform />} />
            <Route path="/token" element={<Token />} />
            <Route path="/engine" element={<MainEngine />} />
            <Route path="/activity" element={<SidePatients />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/adminrole" element={<AddRole />} />
            <Route path="/admin-activity" element={<AdminActivity />} />
            <Route path="/adminpage" element={<AdminPage />} />
            <Route path="/my-profile" element={<ProfileUpdate />} />
            <Route path="/createtoken" element={<CreateNewToken />} />
            <Route path="/patientedit" element={<PatientListedit />} />
            <Route path="/front-activity" element={<FrontActivity />} />
            <Route
              path="/organization-setting"
              element={<OrganizationDetails />}
            />
            <Route path="/session" element={<USdoctor/>}/>
            <Route path="/form" element={<MedicineCard />}/>
            <Route path="/form1" element={<DischargeSummary />}/>
            <Route path="/form2" element={<DischargeCheckList />}/>
            {/* <Route path="/aaa" element={<Appre/>}/> */}
            {/* </Routes> */}
            {/* </Sidebar> */}
          </Route>
          </Routes>
        </Router>
      </div>
    </MsalProvider>
  );
  function MainLayout() {
    return (
      <>
        <Sidebar setuser={true}>
          <Outlet />
        </Sidebar>
      </>
    );
  }
}

export default App;
