import React from "react";
import { Table, Container, Form } from "react-bootstrap"; // Import Bootstrap components
import "./OutpatientDataSheet.css";

const VitalsTable = ({ editableVitals, showEditor, handleInputChange }) => {
  const defaultFields = {
    systolicBP: "",
    diastolicBP: "",
    pulse: "",
    // position: "",
    height: "",
    weight: "",
    oxygenSaturation: "",
    bodyTemperature: "", // Added Body Temperature
    respiratoryRate: "", // Added Respiratory Rate
    bloodSugar: "", // Added Blood Sugar
  };

  console.log(editableVitals, "editedvitals");

  return (
    <Container style={{ maxWidth: "794px" }}>
      {editableVitals && editableVitals.length > 0 ? (
        <Table bordered hover responsive className="table-striped vitalspart">
          <thead className="bg-primary text-white">
            <tr>
              {Object.keys(defaultFields).map((key) => (
                <th
                  key={key}
                  className="text-center" // Removed text-capitalize class
                >
                  {key
                    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add spaces between words
                    .replace(/\b(B) (P)\b/g, "$1$2") // Join "B P" into "BP"
                    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {editableVitals.map((vital, index) => (
              <tr key={index}>
                {Object.keys(defaultFields).map((key) => (
                  <td key={key} className="align-middle">
                    {showEditor ? (
                      <Form.Control
                        type="text"
                        value={vital[key] || ""}
                        onChange={(e) =>
                          handleInputChange(index, key, e.target.value)
                        }
                        className="form-control-sm"
                      />
                    ) : (
                      <span className="text-muted">
                        {vital[key] || "-"} {/* Display updated value */}
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div></div> // Placeholder for empty editableVitals
      )}
    </Container>
  );
};

export default VitalsTable;







// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const VitalsForm = ({ editableVitals, showEditor }) => {
//   const defaultVitals = {
//     height: "",
//     weight: "",
//     systolicBP: "",
//     diastolicBP: "",
//     position: "Sitting",
//     pulse: "",
//     oxygenSaturation: "",
//   };

//   const [data, setData] = useState({ ...defaultVitals });

//   useEffect(() => {
//     const standardizedVitals = editableVitals.length
//       ? { ...defaultVitals, ...editableVitals[0] }
//       : { ...defaultVitals };
//     setData(standardizedVitals);
//   }, [editableVitals]);

//   const handleInputChange = (field, value) => {
//     setData((prevData) => ({
//       ...prevData,
//       [field]: value,
//     }));
//   };

//   return (
//     <Container className="p-4" style={{ maxWidth: "794px" }}>
//       <Card className="mb-4 shadow-sm">
//         <Card.Body>
//           <h5 className="mb-3">Basic Details</h5>
//           <Row>
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>Height (cm)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.height}
//                   onChange={(e) => handleInputChange("height", e.target.value)}
//                   disabled={!showEditor}
//                 />
//               </Form.Group>
//             </Col>
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>Weight (kg)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.weight}
//                   onChange={(e) => handleInputChange("weight", e.target.value)}
//                   disabled={!showEditor}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>

//       <Card className="shadow-sm">
//         <Card.Body>
//           <h5 className="mb-3">Vitals</h5>
//           <h6 className="text-secondary mb-3">Blood Pressure</h6>
//           <Row>
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>Systolic blood pressure (mmHg)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.systolicBP}
//                   onChange={(e) =>
//                     handleInputChange("systolicBP", e.target.value)
//                   }
//                   disabled={!showEditor}
//                 />
//                 <Form.Text className="text-muted">(100 - 140)</Form.Text>
//               </Form.Group>
//             </Col>
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>Diastolic blood pressure (mmHg)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.diastolicBP}
//                   onChange={(e) =>
//                     handleInputChange("diastolicBP", e.target.value)
//                   }
//                   disabled={!showEditor}
//                 />
//                 <Form.Text className="text-muted">(60 - 90)</Form.Text>
//               </Form.Group>
//             </Col>
//           </Row>

//           <h6 className="text-secondary mt-4">Body Position</h6>
//           <Form.Group className="mb-3">
//             <div className="d-flex gap-2">
//               {["Sitting", "Recumbent", "Unknown", "Other", "Standing", "Fowler"].map(
//                 (position) => (
//                   <Button
//                     key={position}
//                     variant={
//                       data.position === position ? "primary" : "outline-primary"
//                     }
//                     onClick={() =>
//                       showEditor && handleInputChange("position", position)
//                     }
//                   >
//                     {position}
//                   </Button>
//                 )
//               )}
//             </div>
//           </Form.Group>

//           <Row className="mt-3">
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>Pulse (beats/min)</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.pulse}
//                   onChange={(e) => handleInputChange("pulse", e.target.value)}
//                   disabled={!showEditor}
//                 />
//                 <Form.Text className="text-muted">(60 - 100)</Form.Text>
//               </Form.Group>
//             </Col>
//             <Col xs={6}>
//               <Form.Group>
//                 <Form.Label>
//                   Arterial blood oxygen saturation (%)
//                 </Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={data.oxygenSaturation}
//                   onChange={(e) =>
//                     handleInputChange("oxygenSaturation", e.target.value)
//                   }
//                   disabled={!showEditor}
//                 />
//                 {/* <Form.Text className="text-muted">()</Form.Text> */}
//               </Form.Group>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default VitalsForm;
