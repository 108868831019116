import React, { useState } from "react";
import { Form, Button, Table, Alert } from "react-bootstrap";
import "../../App.css"; 
import { configJson } from "../../Constent/config";

const AssessmentTemplateForm = ({ doctorId, setAssessmentList,organizationId }) => {
  const defaultTemplateName = "General Health Assessment"; // Default template name
  const defaultTestValue = "Blood Test, X-Ray"; // Default value in the test input field

  const [templateName, setTemplateName] = useState(defaultTemplateName); // Template name state with default value
  const [test, setTest] = useState(defaultTestValue); // Test input field starts with default value
  const [testList, setTestList] = useState([]); // Initially, no tests are added to the list
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Success message state
  const [templateNameError, setTemplateNameError] = useState(""); // Error state for template name
  const [generalError, setGeneralError] = useState(""); // General error state for other form errors

  // Function to add a new test to the list
  const handleAddTest = () => {
    if (test.trim() !== "") {
      const newTests = test.split(",").map(t => t.trim()).filter(t => t !== "");
      setTestList((prevList) => [...prevList, ...newTests]); // Add new tests to the list
      setTest(""); // Clear input after adding

      // Clear general error if any tests are added successfully
      setGeneralError("");
    }
  };

  // Function to save the template
  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      setTemplateNameError("Please enter a template name.");
      return;
    }

    if (testList.length === 0) {
      setGeneralError("Please add at least one test.");
      return;
    }

    // Clear errors if template name and tests are valid
    setTemplateNameError("");
    setGeneralError("");

    const templateData = {
      doctor_id: doctorId,
      organization_id:organizationId,
      assessment_template: templateName, // Template name from the form data
      template_tests: testList.join(", "), // Convert test list to a string
    };

    try {
      const response = await fetch(`${configJson.backend_URL}/assessment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(templateData),
      });

      const responseData = await response.json();

      if (response.ok) {
        // Update the assessment list with the new template
        setAssessmentList((prevList) => [...prevList, responseData.assessment]);

        // Show the success message
        setShowSuccessMessage(true);

        // Automatically hide the success message after 3 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);

        // Reset form after saving
        resetForm();
      } else {
        setGeneralError(responseData.message); // Display error message from the server
      }
    } catch (error) {
      console.error("Error saving template:", error);
      setGeneralError("An unexpected error occurred while saving the template. Please try again.");
    }
  };

  // Function to check if the template name already exists
  const checkTemplateNameExists = async () => {
    if (!templateName.trim()) {
      setTemplateNameError("Template name cannot be empty.");
      return;
    }

    try {
      const response = await fetch(`${configJson.backend_URL}/assessment/check-name`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ doctor_id: doctorId, assessment_template: templateName }),
      });

      const responseData = await response.json();

      if (responseData.exists) {
        setTemplateNameError("Template name already exists.");
      } else {
        setTemplateNameError(""); // Clear the error if template name is unique
      }
    } catch (error) {
      console.error("Error checking template name:", error);
      setGeneralError("Error checking template name. Please try again.");
    }
  };

  // Helper function to reset the form
  const resetForm = () => {
    setTemplateName(defaultTemplateName); // Reset template name to default
    setTest(defaultTestValue); // Reset test input field to default value
    setTestList([]); // Clear the list of added tests
    setTemplateNameError(""); // Clear any existing error messages
    setGeneralError(""); // Clear any general error messages
  };

  return (
    <div>
      <h4 className="text-center gradient-text">Create Assessment Template</h4>
      
      {/* Success Message */}
      {showSuccessMessage && (
        <Alert variant="success" className="text-center">
          Template saved successfully!
        </Alert>
      )}

      {/* General Error Message */}
      {generalError && (
        <Alert variant="danger" className="text-center">
          {generalError}
        </Alert>
      )}
      
      <Form className="templateForm">
        {/* Template Name Input */}
        <Form.Group controlId="formTemplateName" className="mt-3">
          <Form.Label>Template Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. General Health Assessment" // Template name placeholder
            value={templateName} // Pre-populate the template name
            onChange={(e) => {
              setTemplateName(e.target.value);
              if (templateNameError) setTemplateNameError(""); // Clear error when user starts typing
            }} 
            onBlur={checkTemplateNameExists} // Check if template name exists on blur
            isInvalid={!!templateNameError} // Indicate invalid state if there's an error
          />
          {templateNameError && (
            <Form.Text className="text-danger">
              {templateNameError}
            </Form.Text>
          )}
        </Form.Group>

        {/* Add Test Input */}
        <Form.Group controlId="formTestName" className="mt-3">
          <Form.Label>Add Test</Form.Label>
          <div className="input-group">
            <Form.Control
              type="text"
              placeholder="e.g. Blood Test, X-Ray" // Test input placeholder
              value={test} // Pre-populate the input field with default test values
              onChange={(e) => setTest(e.target.value)} // Update test input state
            />
            <Button className="addTestclass" variant="primary" onClick={handleAddTest}>
              Add Test
            </Button>
          </div>
        </Form.Group>

        {/* Display Added Tests */}
        <div className="mt-3">
          <h5>Added Tests</h5>
          <Table striped bordered hover responsive className="table-sm text-center">
            <thead className="table-dark">
              <tr>
                <th>Test Name</th>
              </tr>
            </thead>
            <tbody>
              {testList.map((testName, index) => (
                <tr key={index}>
                  <td>{testName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Save Button */}
        <Button className="addTestclass mt-3" variant="success" onClick={handleSaveTemplate}>
          Save Template
        </Button>
      </Form>
    </div>
  );
};

export default AssessmentTemplateForm;
