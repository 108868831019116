import React, { useState, useEffect } from "react";
import { onlyDate } from "../../helpers";
import { Input } from "antd";

const DentalChart = () => {
  const [teeth, setTeeth] = useState([
    {
      num: 18,
      mobility: 0,
      implant: true,
      furcation: 1,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 17,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 16,
      mobility: 0,
      implant: true,
      furcation: 2,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 15,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 14,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 13,
      mobility: 0,
      implant: false,
      furcation: 3,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 12,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 11,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
  ]);
  const [teeth1, setTeeth1] = useState([
    {
      num: 21,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 22,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 23,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 24,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 25,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 26,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 27,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 28,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
  ]);
  const [teeth2, setTeeth2] = useState([
    {
      num: 21,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 22,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 23,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 24,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 25,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 26,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 27,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 28,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
  ]);
  const [teeth3, setTeeth3] = useState([
    {
      num: 21,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 22,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 23,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 24,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 25,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 26,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 27,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
    {
      num: 28,
      mobility: 0,
      implant: false,
      furcation: null,
      bleeding: [false, false, false],
      plaque: [false, false, false],
      gingival: [0, 0, 0],
      probing: [0, 0, 0],
    },
  ]);

  const handleInputChange = (index, field, value, position) => {
    // setTeeth((prevTeeth) => {
    //   const updatedTeeth = [...prevTeeth];
    //   if (Array.isArray(updatedTeeth[index][field])) {
    //     const updatedArray = [...updatedTeeth[index][field]];
    //     updatedArray[position] = value;
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: updatedArray };
    //     return updatedTeeth;
    //   } else {
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: value };
    //     return updatedTeeth;
    //   }
    // });
  };
  const handleInputChange1 = (index, field, value, position) => {
    // setTeeth1((prevTeeth) => {
    //   const updatedTeeth = [...prevTeeth];
    //   if (Array.isArray(updatedTeeth[index][field])) {
    //     const updatedArray = [...updatedTeeth[index][field]];
    //     updatedArray[position] = value;
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: updatedArray };
    //     return updatedTeeth;
    //   } else {
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: value };
    //     return updatedTeeth;
    //   }
    // });
  };

  const maxVal = 12;
  const [width, setWidth] = useState(window.innerWidth * 0.33);
  const height = 100;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setWidth(window.innerWidth * 0.4);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculatePoints = (teethData, startOffset = 0) => {
    return teethData.flatMap((tooth, index) => {
      const xSpacing = width / (teethData.length * tooth.gingival.length);
      return tooth.gingival.map((gin, ginIndex) => [
        xSpacing * (index * tooth.gingival.length + ginIndex) + startOffset,
        height - (gin / maxVal) * height,
      ]);
    });
  };

  const polylinePoints = calculatePoints(teeth);

  const polylinePoints1 = calculatePoints(teeth1);
  const polylinePointsStr = polylinePoints.map((p) => p.join(",")).join(" ");
  const polylinePoints1Str = polylinePoints1.map((p) => p.join(",")).join(" ");

  return (
    <>
      <div className="p-4">
        <div className="flex flex-col sm:flex-row items-center justify-between mb-2">
          <h1 className="text-xl sm:text-3xl font-bold mb-4">
            PERIODONTAL CHART
          </h1>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Date</label>
            <div className="p-2 py-1 border rounded-md shadow-sm border-blue-500">
              {onlyDate(Date.now())}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">
              Patient Last Name
            </label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">First Name</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Date Of Birth</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mb-4">
          <div className="flex items-center gap-6">
            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="initialExam"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="initialExam" className="text-sm font-medium">
                Initial Exam
              </label>
            </div>

            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="re-evaluation"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="reevaluation" className="text-sm font-medium">
                Reevaluation
              </label>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Clinician</label>
            <input
              type="text"
              className="p-2 py-1 w-full md:w-auto border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="text-xs overflow-y-hidden dental-chart">
          <div className="w-[720px] h-[250px] md:w-full flex items-center gap-6">
            {/* Table-1 */}
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-none">{"  "}</th>
                  {teeth.map((tooth, index) => (
                    <th key={index}>{tooth.num}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="!border-none text-right">Mobility</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        min={0}
                        max={20}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "mobility",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Implant</td>
                  {teeth.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                      onClick={() =>
                        handleInputChange(index, "implant", !tooth.implant)
                      }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.implant ? "bg-black" : ""
                          }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table-2 */}
            <table className="w-[85%] h-[250px]">
              <thead>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <th key={index}>{tooth.num}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        min={0}
                        max={20}
                        onChange={(e) =>
                          handleInputChange1(
                            index,
                            "mobility",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                      onClick={() =>
                        handleInputChange1(index, "implant", !tooth.implant)
                      }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.implant ? "bg-black" : ""
                          }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange1(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange1(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Buccal</div>
            <div className="flex items-center justify-around relative gap-6 mt-4 !w-full">
              {/* Image-1 */}
              <div className="flex items-center ml-3">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              {/* Image-2 */}
              <div className="flex items-center mt-4">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-0 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text-xs overflow-y-hidden">
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Palatal</div>
            <div className="flex items-center justify-around relative gap-6 !w-full">
              {/* Image - 3 */}
              <div className="flex items-center ml-6">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              {/* Image -4 */}
              <div className="flex items-center ml-2">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 top-8"
              >
                <polyline
                  points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-0 top-8"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
          <div className="w-[720px] h-[250px] md:w-full flex items-center gap-6 mt-3">
            {/* Table - 3 */}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Note</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table- 4 */}
            <table className="w-[85%] h-[189px]">
              <tbody>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange1(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange1(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="text-xs overflow-y-hidden dental-chart">
          <div className="w-[720px] h-[250px] md:w-full flex items-center gap-6">
            {/* Table - 5 */}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">Note</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="text"
                        // value={tooth.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        min={0}
                        max={20}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "mobility",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table- 6 */}
            <table className="w-[85%] h-[188px]">
              <tbody>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="text"
                        // value={tooth.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        min={0}
                        max={20}
                        onChange={(e) =>
                          handleInputChange1(
                            index,
                            "mobility",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange1(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange1(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Lingual</div>
            <div className="flex items-center justify-around relative gap-6 mt-4 !w-full">
              {/* Image- 5 */}
              <div className="flex items-center ml-3">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              {/* Image- 6 */}
              <div className="flex items-center mt-4">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-0 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text-xs overflow-y-hidden">
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Buccal</div>
            <div className="flex items-center justify-around relative gap-6 !w-full">
              <div className="flex items-center ml-6">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              {/* Tooth images 21-28 */}
              <div className="flex items-center ml-2">
                {teeth.map((tooth, index) => (
                  <div className="relative">
                    <img
                      key={index}
                      src={
                        tooth.implant
                          ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                              18 - index
                            }.png`
                          : `/dental/table1/periodontograma-dientes-arriba-${
                              18 - index
                            }.png`
                      }
                      className="!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth?.furcation && (
                      <>
                        {tooth?.furcation == 1 && (
                          <img
                            src={`/dental/null.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 2 && (
                          <img
                            src={`/dental/half.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                        {tooth?.furcation == 3 && (
                          <img
                            src={`/dental/full.png`}
                            className="absolute top-12 left-5 w-4"
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 top-8"
              >
                <polyline
                  points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-0 top-8"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
          {/* Table - 7 */}
          <div className="w-[720px] h-[250px] md:w-full flex items-center gap-6 mt-3">
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Note</td>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="border-none">{"  "}</th>
                  {teeth2.map((tooth, index) => (
                    <th key={index}>{tooth.num}</th>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table - 8 */}
            <table className="w-[85%]">
              <tbody>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.gingival.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "gingival",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.probing.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange1(
                                index,
                                "probing",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                            } p-[6px]`}
                            onClick={() =>
                              handleInputChange1(index, "plaque", !pl, plIndex)
                            }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.bleeding.map((bleed, bleedIndex) => (
                          <button
                            onClick={(e) =>
                              handleInputChange1(
                                index,
                                "bleeding",
                                !bleed,
                                bleedIndex
                              )
                            }
                            className={`${
                              bleed ? "bg-red-500" : "bg-gray-200"
                            } p-[6px]`}
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        value={tooth.furcation || 0}
                        onChange={(e) =>
                          handleInputChange1(index, "furcation", e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth3.map((tooth, index) => (
                    <th key={index}>{tooth.num}</th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DentalChart;
