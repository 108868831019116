// src/redux/settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orglogo: sessionStorage.getItem('orglogo') || '',
  timeZone: sessionStorage.getItem('timeZone'),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOrgLogo: (state, action) => {
      state.orglogo = action.payload;
      sessionStorage.setItem('orglogo', action.payload);
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
      sessionStorage.setItem('timeZone', action.payload);
    },
  },
});

export const { setOrgLogo, setTimeZone } = settingsSlice.actions;
export default settingsSlice.reducer;
