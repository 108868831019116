import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { configJson } from "../../../Constent/config";

const EditPatientModal = ({ show, handleClose, patientData, updatePatient }) => {
  const [formData, setFormData] = useState({});
  const [languages, setLanguages] = useState([]);

  // Fetch languages from the database
  const fetchLanguages = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/patient/language`);
      const data = await response.json();
      setLanguages(data); // Assuming the response is an array of languages
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    if (patientData) {

      setFormData({
        ...patientData,
       // date_of_birth: patientData.date_of_birth.split("T")[0], // Format date
      });
    }
  }, [patientData]);

  useEffect(() => {
    fetchLanguages(); // Fetch languages when the modal opens
  }, [show]); // Dependency array includes `show` to fetch languages when modal opens

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const updatedData = {
      ...formData,
      // Ensure 'language' maps directly to the column in the database
      language: formData.language, // Directly use the 'language' field
    };
    updatePatient(updatedData); // Pass updated data to the update function
    handleClose(); // Close the modal after submitting
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formPatientID">
                <Form.Label>Patient ID</Form.Label>
                <Form.Control type="text" value={formData.patient_id} name="patient_id" readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formHospitalPatientID">
                <Form.Label>Hospital Patient ID</Form.Label>
                <Form.Control type="text" value={formData.hospital_patient_id} name="hospital_patient_id" readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" value={formData.first_name} name="first_name" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" value={formData.last_name} name="last_name" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formDOB">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type="date" value={formData.date_of_birth} name="date_of_birth" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" value={formData.phone_number} name="phone_number" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={formData.email} name="email" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formInsuranceNo">
                <Form.Label>Insurance No</Form.Label>
                <Form.Control type="text" value={formData.insurance_no} name="insurance_no" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formProviderID">
                <Form.Label>Provider ID</Form.Label>
                <Form.Control type="text" value={formData.provider_id} name="provider_id" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formEmergencyContactName">
                <Form.Label>Emergency Contact Name</Form.Label>
                <Form.Control type="text" value={formData.emergency_contact_name} name="emergency_contact_name" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmergencyContactPhoneNo">
                <Form.Label>Emergency Contact Phone No</Form.Label>
                <Form.Control type="text" value={formData.emergency_contact_phone_no} name="emergency_contact_phone_no" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formAddress1">
                <Form.Label>Address 1</Form.Label>
                <Form.Control type="text" value={formData.address1} name="address1" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formAddress2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control type="text" value={formData.address2} name="address2" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formState">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" value={formData.state} name="state" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" value={formData.city} name="city" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formZipCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control type="text" value={formData.zip_code} name="zip_code" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formLanguage">
                <Form.Label>Language</Form.Label>
                <Form.Control as="select" value={formData.language} name="language" onChange={handleChange}>
                  <option value="">Select Language</option> {/* Placeholder option */}
                  {languages.map((lang) => (
                    <option key={lang.language_code} value={lang.language_code}>
                      {lang.language_name} {/* Assuming 'language_name' contains the name of the language */}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPatientModal;
