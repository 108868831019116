import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, onHide, onConfirm,loading }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By saving this, you will no longer be able to edit the file. Would you
          like to proceed?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {loading ? "Loading.." : "Yes"
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
