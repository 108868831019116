import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import settingsReducer from './slices/settingsSlice';
import patientsReducer from './slices/patientsSlice';
import assessmentReducer from './slices/assessmentSlice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        settings: settingsReducer,
        patients: patientsReducer,
        assessments: assessmentReducer,
    },
});

export default store;