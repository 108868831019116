import styled from "styled-components";
export const InteractionPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  .intraction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    background-color: #b4bfb473;
    border-radius: 15px;
    .user-details-container {
      .user-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .theme-button {
        color: #fff;
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        padding: 0px 10px;
        border-radius: 25px;
        margin: 0px;
        height: 34px;
      }
    }
    .audio-container {
      padding: 10px;
    }
    .soapnotes-container {
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding: 0px 10px 10px 10px;
      border: 1px solid black;
      border-radius: 15px;
      background-color: white;

      .text-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0px 4px 0px;
        align-items: center;
        .text-header-text {
          margin: 0px;
          color: black;
          font-size: 17px;
          border: none;
          padding: 0px;
          font-weight: 700;
        }

        .text-btn-container {
          display: flex;
          flex-direction: row;
          gap: 5px;
          .text-btn-top {
            border: none;
            padding: 1px;
          }
        }
      }
      .text-line {
        margin: 0px;
      }
      .notes {
        display: flex;
        flex-direction: column;
        justify-content: left;
        height: 200px;
        overflow-y: auto;
        padding: 0px 10px 10px 10px;
        &::-webkit-scrollbar {
          background-color: #f1f1f1;
          width: 8px;
          padding-right: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          padding-right: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          padding-right: 10px;
        }
      }
    }
    .notes-container {
      .theme-button {
        color: #fff;
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        padding: 5px 20px 5px 20px;
        border-radius: 15px;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex-wrap: wrap;
  .date-filters {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    .date-header {
      display: flex;
      flex-direction: row;
      border: 1px solid #0000004a;
      padding: 5px;
      border-radius: 10px;
      .date {
        display: flex;
        flex-direction: row;
        gap: 4px;
        .date-field {
          border: none;
        }
      }
      .arrow {
        padding: 5px;
      }
    }
  }
  .input-text {
    // position: relative;
    margin: 25px 10px;
    border: 1px solid #0000004d;
    border-radius: 10px;
    width: 350px;
    padding: 6px 17px;
  }
  .search-icon-container {
    position: relative;
    right: 45px;
    top: 37px;
    .search-input-icon {
      width: 15px;
      height: 15px;
    }
  }
`;
export const SoapnotesModelcontainer = styled.div`
  .modal-dialog {
    max-width: 90%; /* Set modal width to 90% of the screen */
    width: 90%; /* Ensure that width is applied */
  }
`;

// export const SoapEditorModalContainer = styled.div`
//   // display: flex;
//   // flex-direction: column; /* Left and right layout for larger screens */
//   // gap: 20px; /* Space between editor and preview */

//   .markdown-input {
//     flex: 1; /* Take up remaining space */
//     height: 70%;
//     max-heigth:70%;
//     padding: 10px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     resize: none; /* Prevent resizing */
//   }

//   .preview-container {
//     flex: 1; /* Take up remaining space */
//     border: 1px solid #ccc;
//     padding: 10px;
//     border-radius: 4px;
//      height: 70%;
//       max-heigth:70%;
//   }

//   @media (max-width: 768px) {
//     flex-direction: column; /* Stack vertically on mobile */

//     .markdown-input,
//     .preview-container {
//       width: 100%; /* Full width on mobile */
//     }
//   }
// `;

export const SoapEditorModalContainer = styled.div`
  .markdown-input {
    width: 100%;
    resize: vertical; /* Allows vertical resizing */
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .theme-button {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 0px 10px;
    border-radius: 25px;
    margin: 0px;
   height: 36px;
  }
`;
