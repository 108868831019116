import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import { TokenListContainer } from "../Doctorstyle";
// import { PatientSession } from "./PatientSession";
import { configJson } from "../../../Constent/config";
import { Alert, Button, Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";
import DoctorCreatePatient from "./DoctorCreatePatient";
import { useDispatch } from "react-redux";
import { addPatientIfNotExists, addPatientToTop } from "../../../redux/slices/patientsSlice";
import { getDateTimeWithOffset } from "../../Home/Components/Sidebar/Sidebar";

function Token() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]); // Replacing tokens with users from backend
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 12;
  const [show, setShow] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null); // Storing selected user details
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [decode, setDecode] = useState(null);

  const dispatch = useDispatch();
  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  // Fetch user tokens when the component mounts
  const fetchTokenUsers = async (doctor_id, formattedDate, timeZone) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/token/${doctor_id}/99/${formattedDate}/${timeZone}`
      ); // Replace with the correct API route
      if (!response.ok) {
        throw new Error("Failed to fetch token users");
      }
      const data = await response.json();
      setUsers(data.interaction);
      console.log("todaydata", data.interaction);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const fetchDoc = async (doctor_id) => {
  //   const doctor = await Doctor.findByPk(doctor_id);
  //   console.log(doctor)
  //     if (!doctor) return res.status(404).json({ message: 'Doctor not found' });
  // }
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    const timeZone = sessionStorage.getItem("timeZone");
    const zone = timeZone.replace("/", "_");
    const timesetDate = getDateTimeWithOffset(timeZone);
    console.log("getDateTimeWithOffset;", timesetDate);
    const timedate = timesetDate;
    if (token) {
      const decoded = jwtDecode(token);
      console.log(23, decode);
      setDecode(decoded);
      // const formattedDates = DateTime.now().toUTC().toFormat("yyyy-MM-dd");
      // const formattedDateTime = DateTime.now()
      //   .toUTC()
      //   .toFormat("yyyy-MM-dd, HH:mm:ss");
      // console.log("date", formattedDates, "time", formattedDateTime); // Output

      fetchTokenUsers(decoded.doctor_id, timedate, zone);
      // Set an interval to call the fetchData function every 2 minutes (120,000ms)
      const intervalId = setInterval(() => {
        fetchTokenUsers(decoded.doctor_id, timedate);
      }, 180000); // 120000ms = 2 minutes

      // Cleanup the interval on component unmount to avoid memory leaks
      return () => clearInterval(intervalId);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // useEffect(() => {
  //   // Fetch data from the backend on component mount
  //   fetchTokenUsers();

  // }, []);

  // Pagination logic
  const totalUsers = users.length;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const indexOfFirstUser = (currentPage - 1) * usersPerPage;
  const currentUsers = users.slice(
    indexOfFirstUser,
    indexOfFirstUser + usersPerPage
  );
  // Handle user click to open the session modal
  const handleUserClick = (user) => {
    let patient;
    // console.log(user)
    if (user) {
      patient = {
        Patient: {
          patient_id: user.Patient.patient_id || "",
          hospital_patient_id: user.Patient.hospital_patient_id || "",
          first_name: user.Patient.first_name || "",
          last_name: user.Patient.last_name || "",
          date_of_birth: user.Patient.date_of_birth || "",
          phone_number: user.Patient.phone_number || "",
        },
        doctor_id: user.doctor_id || "",
        interaction_status: user.interaction_status || "",
        patient_id: user.Patient.patient_id || "",
        token_number: user.token_number || "",
        interaction_id: user.interaction_id,
      };
      dispatch(addPatientIfNotExists(patient));

      // console.log(patient)

      // const userdata = {
      //   hospital_patient_id: user.Patient.hospital_patient_id,
      //   first_name: user.Patient.first_name,
      //   last_name: user.Patient.last_name,
      //   date_of_birth: user.Patient.date_of_birth,
      //   language: user.Patient.language,
      //   phone_number: user.Patient.phone_number,
      //   patient_id: user.Patient.patient_id,
      //   interaction_id: user.interaction_id,
      // };
      navigate(`/activity?id=${user.Patient.patient_id}`, {
        state: {
          interaction_id: user.interaction_id,
        },
      });
      // setSelectedUser(userdata);
      // setShow(true);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Display error message if there was an error during fetch
  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  return (
    // <Sidebar setuser={true}>
    <TokenListContainer>
      {/* {show && (
          <PatientSession
            show={show}
            handleClose={handleClose}
            tokendata={selectedUser}
            tokensStatus={() => {}}
          />
        )} */}
      {show && <DoctorCreatePatient show={show} handleClose={handleClose} />}
      <div className="heading-text">
        <h4 className="gradient-text">Today's Appointments</h4>
        <span className="heading-text-line"></span>
        <Button type="button" onClick={() => setShow(true)}>
          Create Token
        </Button>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {users.length === 0 ? (
            <>
              <Alert variant="info" className="show-hide">
                No New Hospital Appointment found. Please create Appointment
                from front office
              </Alert>
              {/* <Button
                  type="button"
                  onClick={() => navigate("/createtoken")}
                >Create Token</Button> */}
            </>
          ) : (
            ""
          )}
        </>
      )}
      {users.length > 0 ? (
        <div className="token-buttons-pagination-container">
          <div className="token-buttons-container">
            {currentUsers.map((user, index) => (
              <button
                key={index} // Using name as unique key
                onClick={() => handleUserClick(user)}
                className={
                  user.interaction_status === "2"
                    ? "token-button active"
                    : "token-button"
                }
                //className="token-button"
              >
                {user.Patient.first_name.charAt(0).toUpperCase() +
                  user.Patient.first_name.slice(1).toLowerCase() +
                  " " +
                  user.Patient.last_name.charAt(0).toUpperCase() +
                  user.Patient.last_name.slice(1).toLowerCase()}
                {/* <br></br> */}
                {user?.token_number !== null ? (
                  <div className="token-header">
                    <span className="token-text">{user?.token_number}</span>
                  </div>
                ) : (
                  ""
                )}
              </button>
            ))}
          </div>
          <div className="pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>

            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={`pagination-button ${
                  currentPage === i + 1 ? "active" : ""
                }`}
              >
                {i + 1}
              </button>
            ))}

            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </TokenListContainer>
    // </Sidebar>
  );
}

export default Token;

