import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { configJson } from "../../Constent/config";
import { AddNewDoctor } from "./Doctorstyle";
import { useForm } from "react-hook-form";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import eyeicon from "../../assets/signup/eye.png";
import eyeClose from "../../assets/signup/eyeclose.png";
import { AdddoctorForm } from "../Login-page/LoginStyle";

const AddDoctorModal = ({ show, handleClose, handleFetch }) => {
  const [decode, setDecode] = useState({});
  const [salutation, setSalutation] = useState(""); // Default salutation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  // const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [showPassword, setShowPassword] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState("doctor");
  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSpecialities = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/`);
      const data = await response.json();
      setSpecialities(data);
    } catch (error) {
      console.error("Error fetching specialities:", error);
    }
  };

  const handleSalutationChange = (e) => {
    console.log("salutechanged",e.target.value)
    setSalutation(e.target.value);
  };
  

  const handleSpecialityChange = (e) => {
    const selected = e.target.value;
    setValue("specialty_id", selected);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMemberTypeChange = (e) => {
    const selected = e.target.value;
    setSelectedMemberType(selected);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          organization_id: decode.organization_id,
          speciality_id: selectedMemberType === "doctor" ? data.specialty_id : null,
          first_name: data.first_name,
          last_name: data.last_name,
          license_no: data.license_no,
          salutation: salutation || null, // Include salutation for all roles
          password_hash: data.password,
          phone_number: data.phone_number,
          email: data.email,
          role: selectedMemberType,
        }),
      });
  
      const newUser = await response.json();
  
      if (response.ok) {
        reset();
        handleFetch(newUser, selectedMemberType); // Send new user data and role to UserList
        handleClose(); // Close the modal
      } else {
        setErrorMessage(newUser.message || "An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      fetchSpecialities();
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Modal
      className="Memberaddition"
      show={show}
      onHide={handleClose}
      style={{ position: "absolute", left: "0%", top: "17%" }}
      backdrop="static"
    >
      <AddNewDoctor>
        <div>
        <Modal.Header closeButton style={{ alignItems: "center", padding: "1rem" }}>
  <div className="head-text-container" style={{ textAlign: "center", width: "100%" }}>
    <h2 className="head-text" style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#333" }}>
      Add Member
    </h2>
  </div>
</Modal.Header>
<Modal.Body className="Modalmember" style={{ padding: "1.5rem" }}>
  {errorMessage && (
    <Alert variant="danger" style={{ marginBottom: "1rem", fontSize: "1rem" }}>
      {errorMessage}
    </Alert>
  )}
  <AdddoctorForm>
  <Form onSubmit={handleSubmit(onSubmit)}>
  {/* Member Type Radio Buttons */}
  <Form.Group className="mb-2" as={Row} controlId="formMemberType">
    <Form.Label column sm={4} style={{ fontWeight: "500" }}>
      Member Type <span style={{ color: "red" }}>*</span>
    </Form.Label>
    <Col sm={8} className="d-flex flex-wrap gap-3">
      {["doctor", "technician", "front office staff", "vital"].map((type) => (
        <Form.Check
          key={type}
          type="radio"
          label={type.charAt(0).toUpperCase() + type.slice(1)}
          value={type}
          {...register("member_type", {
            required: "Member type is required",
          })}
          onChange={handleMemberTypeChange}
          checked={selectedMemberType === type}
          style={{ marginRight: "10px" }}
        />
      ))}
      {errors.member_type && (
        <Form.Text className="text-danger" style={{ marginTop: "0.5rem" }}>
          {errors.member_type.message}
        </Form.Text>
      )}
    </Col>
  </Form.Group>

  {/* Salutation Dropdown */}
{/* Conditional Fields for Salutation */}
{["doctor", "technician", "front office staff", "vital"].includes(selectedMemberType) && (
<Form.Group className="mb-2" as={Row} controlId="formSalutation">
  <Form.Label column sm={4} style={{ fontWeight: "500" }}>
    Salutation <span style={{ color: "red" }}>*</span>
  </Form.Label>
  <Col sm={8}>
    <select
      className="form-select"
      aria-label="Default select example"
      value={salutation} // Controlled by state
      onChange={(e) => {
        setSalutation(e.target.value); // Correctly update the state with the selected value
        console.log("Selected Salutation:", e.target.value); // Debugging
      }}
    >
      <option value="">Select Salutation</option>
      <option value="Mr">Mr</option>
      <option value="Miss">Miss</option>
      {selectedMemberType === "doctor" && <option value="Dr">Dr</option>}
    </select>
    {errors.salutation && (
      <Form.Text className="text-danger">{errors.salutation.message}</Form.Text>
    )}
  </Col>
</Form.Group>

)}



  {/* Conditional Fields for Doctors */}
  {selectedMemberType === "doctor" && (
    <>
      <Form.Group className="mb-2" as={Row} controlId="formLicenseNo">
        <Form.Label column sm={4} style={{ fontWeight: "500" }}>
          License Number <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            placeholder="Enter license number"
            {...register("license_no", { required: "License number is required" })}
            style={{ borderRadius: "8px" }}
          />
          {errors.license_no && (
            <Form.Text className="text-danger">{errors.license_no.message}</Form.Text>
          )}
        </Col>
      </Form.Group>

      <Form.Group className="mb-2" as={Row} controlId="formSpeciality">
        <Form.Label column sm={4} style={{ fontWeight: "500" }}>
          Specialty <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Col sm={8}>
          <Form.Select
            {...register("specialty_id", { required: "Specialty is required" })}
            onChange={handleSpecialityChange}
            style={{ borderRadius: "8px" }}
          >
            <option value="">Select Specialty</option>
            {specialities.map((speciality) => (
              <option key={speciality.speciality_id} value={speciality.speciality_id}>
                {speciality.specialty_name}
              </option>
            ))}
          </Form.Select>
          {errors.specialty_id && (
            <Form.Text className="text-danger">{errors.specialty_id.message}</Form.Text>
          )}
        </Col>
      </Form.Group>
    </>
  )}

  {/* Additional Fields */}
  {[
    { label: "First Name", name: "first_name", required: true },
    { label: "Last Name", name: "last_name", required: true },
    { label: "Phone Number", name: "phone_number" },
    {
      label: "Email",
      name: "email",
      required: true,
      pattern: {
        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        message: "Email is not valid",
      },
    },
    { label: "Password", name: "password", required: true },
  ].map(({ label, name, required, pattern }) => (
    <Form.Group className="mb-2" as={Row} controlId={`form${name}`} key={name}>
      <Form.Label column sm={4} style={{ fontWeight: "500" }}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      <Col sm={8}>
        <div className="sh-pass">
          <Form.Control
            type={
              name === "email"
                ? "email"
                : name === "password"
                ? showPassword
                  ? "text"
                  : "password"
                : "text"
            }
            placeholder={`Enter ${label.toLowerCase()}`}
            {...register(name, {
              required: required && `${label} is required`,
              pattern: pattern,
            })}
            style={{ borderRadius: "8px" }}
          />
          {name === "password" && (
            <span className="show-pass">
              {showPassword ? (
                <img
                  onClick={togglePasswordVisibility}
                  src={eyeClose}
                  title="Hide password"
                  alt="Hide password"
                  className="sign-input-image-close"
                />
              ) : (
                <img
                  onClick={togglePasswordVisibility}
                  src={eyeicon}
                  title="Show password"
                  alt="Show password"
                  className="sign-input-image"
                />
              )}
            </span>
          )}
        </div>
        {errors[name] && (
          <Form.Text className="text-danger">{errors[name].message}</Form.Text>
        )}
      </Col>
    </Form.Group>
  ))}

  <div className="bthntop" style={{ textAlign: "center" }}>
    {/* Submit Button */}
    <Button
      className="submitmem"
      variant="primary"
      type="submit"
      style={{
        marginTop: "10px",
        width: "100%",
        padding: "10px",
        backgroundColor: "#007bff",
        borderRadius: "8px",
        border: "none",
        fontWeight: "bold",
        maxWidth: "200px",
      }}
      disabled={loading}
    >
      {loading ? "Submitting..." : "Submit"}
    </Button>
  </div>
</Form>

  </AdddoctorForm>
</Modal.Body>

        </div>
      </AddNewDoctor>
    </Modal>
  );
};

export default AddDoctorModal;
