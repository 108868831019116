import styled from "styled-components";

export const AddNewDoctor = styled.div`
  .modal-header {
    display: flex;
    flex-direction: column-reverse;
    .head-text-container {
      text-align: center;
      .head-text {
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 30px;
      }
    }
  }
  .form-check-input {
    background-color: #00000073;
  }
  #dropdown-discipline {
    width: 100%;
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
  }
  .scrollable-dropdown-menu {
    width: 100%;
    max-height: 150px; /* Adjust the max-height to your preference */
    overflow-y: auto; /* Enables vertical scrolling */
  }

  .btn-log {
    text-align: center;
    .add-doctor {
      width: 300px;
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }
  }
`;

export const TableContainer = styled.div`
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b25bcc;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const EditDeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  .edit-btn {
    border: none;
    padding: 0px;
    margin: 0px;
    .edit-btn-image {
      width: 18px;
      height: 18px;
    }
  }
  .delete-btn {
    border: none;
    padding: 0px;
    margin: 0px;
    .delete-btn-image {
      width: 18px;
      height: 18px;
    }
  }
`;

export const AddNewDoctorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  alugn-item: center;
`;

export const SearchContainer = styled.div`
  .search-btn {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px;
    .search-field {
      border: 1px solid #0000004a;
      padding: 7px;
      border-radius: 10px;
      width: 300px;
    }
    .btn-log {
      text-align: center;
      .add-doctor {
        color: #fff;
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        padding: 5px 20px 5px 20px;
      }
    }
  }
`;

export const PersonCard = styled.div`
  height: 76vh;
  padding: 12px;
  overflow-y: scroll;
  margin-top: 22px;
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b25bcc;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .doctor-list-person-card {
    border: 1px solid #00000070;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    .person-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .image-container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        .image-doctor {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .log-headers {
          text-align: left;
          margin: 0px;
          padding-left: 5px;
          background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    }
    .hr-data {
      margin: 1px;
    }
    .text-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000000bd;
      .text-type {
        text-transform: capitalize;
      }
      .text-part p {
        margin: 2px;
        font-size: 15px;
      }
      .text-part p span {
        padding-right: 3px;
      }
      .text-checkbox {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }
  }
`;
export const EditDoctorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  alugn-item: center;
`;

export const EditDoctorForm = styled.div`
  .form-check-input {
    background-color: #00000073;
    margin-top: 10px;
  }
  .form-check-label {
    margin-top: 5px;
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 36px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
  .reg-lbl {
    font-size: 14px;
    font-weight: 600;
    color: #1c2127;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
  }
  .reg-phone {
    font-size: 15px;
    box-shadow: none;
    border-radius: 6px !important;
    padding: 6px 16px;
    height: 38px;
    border: 1px solid #dee2e6;
  }
  .er-message {
    color: crimson;
    font-size: 14px;
  }
  .Feedback {
    margin: 0px;
  }
  .btn-log {
    text-align: center;
    .reg-sub-btn {
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }
  }
`;

export const ConversationHistoryContainer = styled.div`
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .gradient-text {
      position: relative;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
      -webkit-background-clip: text;
      color: transparent;
    }

    .gradient-text::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
    }
    .heading-text-line {
      flex-grow: 1;
      height: 2px;
      background-color: rgba(186, 186, 186, 1);
      margin-top: 24px;
    }
  }
  .search-items {
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    .date-filters {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      .date-header {
        display: flex;
        flex-direction: row;
        border: 1px solid #0000004a;
        padding: 5px;
        border-radius: 10px;
        .date {
          display: flex;
          flex-direction: row;
          gap: 4px;
          .date-field {
            border: none;
          }
        }
        .arrow {
          padding: 5px;
        }
      }
    }
    .input-text {
      // position: relative;
      margin: 25px 10px;
      border: 1px solid #0000004d;
      border-radius: 10px;
      width: 350px;
      padding: 6px 17px;
    }
    .search-icon-container {
      position: relative;
      right: 45px;
      top: 37px;
      .search-input-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export const PatientListContainer = styled.div``;
export const TableContainers = styled.div`
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .gradient-text {
      position: relative;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
      -webkit-background-clip: text;
      color: transparent;
    }

    .gradient-text::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
    }
    .heading-text-line {
      flex-grow: 1;
      height: 2px;
      background-color: rgba(186, 186, 186, 1);
      margin-top: 24px;
    }
  }
  .search-items {
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    .date-filters {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      .date-header {
        display: flex;
        flex-direction: row;
        border: 1px solid #0000004a;
        padding: 5px;
        border-radius: 10px;
        .date {
          display: flex;
          flex-direction: row;
          gap: 4px;
          .date-field {
            border: none;
          }
        }
        .arrow {
          padding: 5px 5px;
        }
      }
    }
    .input-text {
      // position: relative;
      margin: 25px 10px;
      border: 1px solid #0000004d;
      border-radius: 10px;
      width: 350px;
      padding: 6px 17px;
    }
    .search-icon-container {
      position: relative;
      right: 45px;
      top: 37px;
      .search-input-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
  .table-record-btn {
    border: none;
    // border-radius: 50%;
    width: 123px;
    height: 21px;
    text-align: center;
    background-color: white;
    .table-record-icon {
      width: 123px;
      height: 31px;
    }
  }
  .Processing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .Processing-icon {
      width: 15px;
      height: 15px;
    }
    .process-text {
      color: #55834d;
      margin-bottom: 0px;
    }
  }
  .verified-container {
    display: flex;
    flex-direction: row;
    .verified-icon {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }
`;

export const CardContainer = styled.div``;

export const ThemeButton = styled.div`
  text-align: center;
  .add-doctor {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
  }
`;

export const PhoneNumberContainer = styled.div`
  margin-bottom: 10px;
  .form-control {
    width: 100%;
  }
  .error-text {
    color: red;
  }
`;
export const AddNewPatient = styled.div`
  // .modal-header {
  //   display: flex;
  //   flex-direction: column-reverse;
  .head-text-container {
    text-align: center;
    margin-top: 20px;
    .head-text {
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 30px;
    }
  }
  // }
  .form-check-input {
    background-color: #00000073;
  }
  .btn-log {
    text-align: center;
    .add-doctor {
      width: 300px;
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }
  }
`;

export const TableHeader = styled.div`
  .table-styled {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    width: 100%;
  }
  .table-styled thead tr {
    border-bottom: 1px solid #dee2e6;
  }
  .table-styled tbody tr {
    border-bottom: 1px solid #dee2e6;
  }

  .table-styled tbody tr:last-child {
    border-bottom: none;
  }
  .circle-design {
    padding: 6px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: rgb(90 90 90);
    color: white;
  }
  .link-line {
    color: blue;
  }
  .table-btn {
    text-align: center;
    .table-detail-btn {
      border: none;
      width: 30px;
      height: 30px;
      text-align: center;
      background-color: white;
      .table-detail-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
  .table-record-btn {
    border: none;
    // border-radius: 50%;
    // width: 123px;
    height: 21px;
    text-align: center;
    background-color: white;
    .table-detail-icon {
      width: 35px;
      height: 35px;
    }
    .table-record-icon {
      width: 123px;
      height: 31px;
    }
  }
  .Processing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .Processing-icon {
      width: 15px;
      height: 15px;
    }
    .process-text {
      color: #55834d;
      margin-bottom: 0px;
    }
  }
  .verified-container {
    display: flex;
    flex-direction: row;
    .verified-icon {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }
  .next-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .no-appointment {
    height: 40px;
  }
`;

export const TextColorText = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 31px;
  font-weight: 700;
  background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

export const TokenPageContainer = styled.div`
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .gradient-text {
      position: relative;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
      -webkit-background-clip: text;
      color: transparent;
      padding: 9px;
    }

    .gradient-text::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
    }
    .heading-text-line {
      flex-grow: 1;
      height: 2px;
      background-color: rgba(186, 186, 186, 1);
      margin-top: 40px;
    }
  }
  .show-hide {
    z-index: -1;
  }
  .token-buttons-pagination-container {
    padding: 40px;
    text-align: center;
    background-color: #f4f4f9;
    // min-height: 100vh;
  }

  .token-buttons-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-bottom: 30px;
    .token-button {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .token-header {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 10px;
        background-color: #4388cf;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        .token-text {
          color: white;
        }
      }
    }
    @media (max-width: 767px) {
      display: grid;
      grid-template-columns: auto auto;
    }
    @media (max-width: 500px) {
      display: grid;
      grid-template-columns: auto;
    }
  }

  .token-button {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    height: 90px;
  }
  .token-button.active {
    background-color: #b1a9a9;
  }

  .token-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .token-button.active {
    background-color: #8b818a;
    border-color: #b0ffb0;
    color: black;
  }
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .pagination-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .pagination-button:hover {
    background-color: #0056b3;
  }

  .pagination-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }

  .pagination-button.active {
    background-color: #28a745;
    border-color: #28a745;
  }
`;


export const TokenListContainer = styled.div`
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .gradient-text {
      position: relative;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
      -webkit-background-clip: text;
      color: transparent;
      padding: 9px;
    }

    .gradient-text::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
    }
    .heading-text-line {
      flex-grow: 1;
      height: 2px;
      background-color: rgba(186, 186, 186, 1);
      margin-top: 40px;
    }
  }
  .show-hide {
    z-index: -1;
  }
  .token-buttons-pagination-container {
    padding: 40px;
    text-align: center;
    background-color: #f4f4f9;
    // min-height: 100vh;
  }

  .token-buttons-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-bottom: 30px;
    .token-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .token-header {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 10px;
        background-color: #4388cf;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        .token-text {
          color: white;
        }
      }
    }
    @media (max-width: 767px) {
      display: grid;
      grid-template-columns: auto auto;
    }
    @media (max-width: 500px) {
      display: grid;
      grid-template-columns: auto;
    }
  }

  .token-button {
    background-color: #ffffff;
    padding: 10px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    font-size: 1rem;
    color: #333;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    height: 90px;
  }
  .token-button.active {
    background-color: #b1a9a9;
  }

  .token-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .token-button.active {
    background-color: #8b818a;
    border-color: #b0ffb0;
    color: black;
  }
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .pagination-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .pagination-button:hover {
    background-color: #0056b3;
  }

  .pagination-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }

  .pagination-button.active {
    background-color: #28a745;
    border-color: #28a745;
  }
`;
export const Okbutton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .reg-sub-btn {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
  }
`;
